/**
 * Utility file to add all jQuery functions.
 * Keep adding static jQuery functions below the already added core functions.
 * Use "jQuery" keyword instead of "$"
 * 
 */

declare var jQuery: any; // JQuery
declare var _: any;
import { AppSettings } from "../configs/core.config";
import { AppConstant } from "../constant/core.constant";
import * as wjCore from "wijmo/wijmo";
export class JqFunctions {
  /********* ForgeRock connection **********/
  static removeFRIframe() {
    jQuery("#" + "FRIframe").html("");
  }
  static addFRIframe(urlIframe) {
    JqFunctions.removeFRIframe();
    let self = this;
    let iframe = document.createElement("iframe");

    iframe.id = "forgerockiframe";
    iframe.src = urlIframe;
    document.getElementById("FRIframe").appendChild(iframe); // add it to wherever you need it in the document
    return iframe;
  }
  /********* clientworks connection *********/
  static removecwIframe() {
    jQuery("#" + AppConstant.cwIframeParentId).html("");
  }
  static addcwIframe(urlIframe) {
    JqFunctions.removecwIframe();
    let self = this;
    let iframe = document.createElement("iframe");

    iframe.id = "clientworksiframe";
    iframe.src = urlIframe;
    document.getElementById("createIframe").appendChild(iframe); // add it to wherever you need it in the document
    return iframe;
  }
  static addcloseIframe(urlIframe) {
    let self = this;
    let iframe = document.createElement("iframe");

    iframe.src = urlIframe;
    iframe.id = "closedAccountModalIframe";
    document.getElementById("closedAccountModalOuter").appendChild(iframe); // add it to wherever you need it in the document
    // JqFunctions.closeAccountIframeModalEvent();
    return iframe;
  }
  static iframeIsLoaded(urlIframe) {}
  static CWlogoutIframe(callBack) {
    /// only logout CW
    JqFunctions.removecwIframe();
    const self = this;
    const iframeLogout = document.createElement("iframe");
    iframeLogout.id = AppConstant.cwIframeId;
    iframeLogout.src = AppSettings.cwLogoutUrl;
    document
      .getElementById(AppConstant.cwIframeParentId)
      .appendChild(iframeLogout); // add it to wherever you need it in the document
    let counterLogout = 0;
    iframeLogout.onload = function() {
      counterLogout++;
      if (JqFunctions.getCookie(AppConstant.cwAuthCookieName) == null) {
        callBack(true);
      }
    };
  }
  static CWFirmloginIframe(url, callBack) {
    // login with firm

    JqFunctions.removecwIframe();
    const self = this;
    const iframe = document.createElement("iframe");
    iframe.id = AppConstant.cwIframeId;
    iframe.src = url;
    document.getElementById(AppConstant.cwIframeParentId).appendChild(iframe); // add it to wherever you need it in the document
    let counterlogin = 0;
    iframe.onload = function() {
      counterlogin++;
      if (!(JqFunctions.getCookie(AppConstant.cwAuthCookieName) == null)) {
        callBack(true);
      }
    };
  }
  /********* end of clientworks connection *********/
  /********* get cookie by id ********/
  static getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  /********* delete cookie ********/
  static deleteLocalStorage() {
    let cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    document.cookie =
      "iPlanetDirectoryPro=; path=/; domain=.lpl.com; expires=" +
      new Date(0).toUTCString();
    localStorage.clear();
  }
  /********* Convert String to JSON ********/
  static stringConvertJson(string) {
    return JSON.parse(string);
  }
  /********* Convert JSON to string ********/
  static jsonConvertString(json) {
    return JSON.stringify(json);
  }
  
  /********* Change bootstrap modal position from top ********/
  static modalTopAdjust() {
    // jQuery(".modal-dialog").css("margin-top", "15%");
  }
  /********* hide / show quick link menu ********/
  static QL_showQuickLinksView() {
    jQuery(".quickLinksHeading").click(function() {
      jQuery(".quickLinksMenu").show();
    });
  }

  static QL_hideQuickLinksView() {
    jQuery(document).click(function(e) {
      if (
        !jQuery(e.target).hasClass("quickLinksHeading") &&
        jQuery(e.target).parents(".quickLinksMenu").length === 0
      ) {
        jQuery(".quickLinksMenu").hide();
      }
    });
  }
  /********* end hide / show quick link menu ********/

  static windowResize(isFullPage = false) {
    jQuery("#page-outer").css({
      height: JqFunctions.getWindowHeight(isFullPage) + "px"
    });
  }

  static getWindowHeight(isFullPage = false) {
    if (!isFullPage) {
      return jQuery(window).height() - jQuery("#page-outer-navbar").height();
    } else {
      return jQuery(window).height();
    }
  }

  static togglePageScrollBarShow(isAuto) {
    if (isAuto) {
      jQuery("#viewportContainer").css({ "overflow-y": "auto" });
    } else {
      JqFunctions.scrollTop();
      //jQuery('#viewportContainer').css({ 'overflow-y': 'hidden' });
    }
  }

  static scrollTop() {
    jQuery("#viewportContainer").animate({ scrollTop: 0 });
  }

  static copyToClipboard(data): boolean {
    if (typeof data == "object") {
      data = JSON.stringify(data);
    }
    const el = document.createElement("textarea");
    el.value = data;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    return true;
  }

  static createCellTooltipWijmo(flex) {
    let toolTip = new wjCore.Tooltip();
    flex.hostElement.addEventListener("mousemove", function(e) {
      let ht = flex.hitTest(e),
        rng = null;
      if (!ht.range.equals(rng)) {
        if (ht.cellType == 1 || ht.cellType == 2) {
          rng = ht.range;
          if (e.srcElement.innerText) {
            if (e.srcElement.innerText.trim() != "") {
              let tipContent =
                  " " +
                  (e.srcElement.innerText.indexOf("INLINE_SR_EDIT") > -1
                    ? "Edit"
                    : e.srcElement.innerText),
                cellElement = document.elementFromPoint(e.clientX, e.clientY),
                cellBounds = wjCore.Rect.fromBoundingRect(
                  cellElement.getBoundingClientRect()
                );
              if (e.srcElement.classList.contains("custom-desc-tooltip")) {
                toolTip.show(flex.hostElement, "custom tooltip", cellBounds);
              } else {
                toolTip.show(flex.hostElement, tipContent, cellBounds);
              }
            }
          }
        }
      }
    });
    flex.hostElement.addEventListener("mouseout", function(e) {
      toolTip.hide();
    });
  }

  static copytoClipboardWijmo(flex) {
    let selectedCellRange;
    flex.selectionChanging.addHandler(function(s, e) {
      selectedCellRange = flex.selection;
    });
    const s = flex;
    s.addEventListener(
      s.hostElement,
      "click",
      function(e) {
        var ht = s.hitTest(e);
        if (
          ht.panel === s.cells &&
          e.srcElement.innerText.indexOf("INLINE_SR_EDIT") > -1
        ) {
          flex.select(-1, -1);
        } else if (ht.panel === s.cells && e.srcElement.innerText != "") {
          var aux = document.createElement("input");
          // console.log(flex);
          try {
            // Assign it the value of the specified element
            aux.setAttribute("value", e.srcElement.innerText.trim());

            // Append it to the body
            document.body.appendChild(aux);

            // Highlight its content
            aux.select();

            // Copy the highlighted text
            document.execCommand("copy");

            // Remove it from the body
            document.body.removeChild(aux);

            flex.focus();
            if (flex.selection.col != flex.columns.length - 1) {
              selectedCellRange.col = selectedCellRange.col + 1;
              selectedCellRange.row = selectedCellRange.row;
              selectedCellRange.row2 = selectedCellRange.row;
              selectedCellRange.col2 = selectedCellRange.col;
              flex.select(selectedCellRange, true);
            } else if (flex.selection.col == flex.columns.length - 1) {
              selectedCellRange.col = 0;
              selectedCellRange.row = selectedCellRange.row + 1;
              selectedCellRange.row2 = selectedCellRange.row;
              selectedCellRange.col2 = 0;
              flex.select(selectedCellRange, true);
            }
            flex.select(selectedCellRange, true);
          } catch (ex) {}
        }
        setTimeout(function(){flex.refresh(false);}, 1000);
      },
      true
    );
  }
  static windowOpen(link) {
    window.open(link, "_blank");
  }
  // open siebel
  static openSR(siebelUrl, SRNumber) {
    var width: number = screen.width * (70 / 100);
    var height: number = screen.height * (70 / 100);
    var leftInScreem = (screen.width - width) / 2;
    var topInScreen = (screen.height - height) / 2;
    var SRNumber: any = SRNumber;
    siebelUrl = siebelUrl || AppSettings.siebelModalURL;
    var Url = siebelUrl + SRNumber;
    window.open(
      Url,
      "_blank",
      `
                    scrollbars=yes,
                    resizable=yes,
                    location=no,
                    toolbar=no,
                    menubar=no,
                    top=` +
        topInScreen +
        `,
                    left=` +
        leftInScreem +
        `,
                    width=` +
        width +
        `,
                    height=` +
        height +
        `
                    `
    );
  }

  static openPopout(url) {
    let width: number = screen.width * (70 / 100);
    let height: number = screen.height * (70 / 100);
    let leftInScreem = (screen.width - width) / 2;
    let topInScreen = (screen.height - height) / 2;

    window.open(
      url,
      "_blank",
      `
                    scrollbars=yes,
                    resizable=yes,
                    location=no,
                    toolbar=no,
                    menubar=no,
                    top=` +
        topInScreen +
        `,
                    left=` +
        leftInScreem +
        `,
                    width=` +
        width +
        `,
                    height=` +
        height +
        `
                    `
    );
  }
  static stopPropagation() {
    jQuery(document).on("click", ".clear-field", function(e) {
      e.stopPropagation();
    });
    jQuery(document).on("click", ".replist-search-wrapper", function(e) {
      e.stopPropagation();
    });
  }

  static getServiceWorksBaseUrl() {
    var concatUrl = "";
    if (window.location.href.indexOf("/swadvisor/") > -1) {
      concatUrl = "/swadvisor";
    }
    return (
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "") +
      concatUrl
    );
  }
  static getRedirectorBaseUrl(redirectorApp) {
    var concatUrl = "";
    if (window.location.href.indexOf("/" + redirectorApp + "/") > -1) {
      concatUrl = "/" + redirectorApp;
    }
    return (
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "") +
      "/" +
      concatUrl
    );
  }
  static addScriptFiletoPage(urltoLoad, callBack = function(a = false) {}) {
    let node = document.createElement("script");
    node.src = urltoLoad;
    node.type = "text/javascript";
    node.onload = function() {
      callBack(true);
    };
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }
  static findBaseHrefName(exclude = false) {
    let concatUrl = window.location.pathname;
    return window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "") +
      exclude
      ? ""
      : concatUrl;
  }
  static elementClickwithId(id) {
    document.getElementById(id).click();
  }
  static getServiceWorksLauncherUrl(defaultRoute = "/swlauncher") {
    var concatUrl = "";
    if (
      window.location.href.indexOf("/swreportscustom/") > -1 ||
      window.location.href.indexOf("/swreportsbeta/") > -1 ||
      window.location.href.indexOf("/swlauncher/") > -1 ||
      window.location.href.indexOf("/swadvisor/") > -1
    ) {
      concatUrl = defaultRoute;
    }
    return (
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "") +
      concatUrl
    );
  }
  static deleteCookieByName(name) {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
  static enableFullScreen(id) {
    // $('#support').text($.fullscreen.isNativelySupported() ? 'supports' : 'doesn\'t support');
    //$.fullscreen.isFullScreen()
    // open in fullscreen
    jQuery("#" + id).fullscreen();
  }
  static disableFullScreen() {
    jQuery.fullscreen.exit();
  }
  static isScriptFileLoaded(url) {
    var scripts = document.getElementsByTagName("script");
    for (var i = scripts.length; i--; ) {
      if (scripts[i].src == url) return true;
    }
    return false;
  }

  
  static removeAllModal(){
    jQuery(".modal").remove();
    jQuery('.modal-backdrop').remove();
  }

  static attachClickEvent(el){
    jQuery(document).on("click", el, function(e) {
      console.log("hi");
    });
  }
}
