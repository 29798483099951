//

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { appState, initialAppState } from "../models/app.model";
import { question, initialQuestionState } from "../models/questionnaire.model";
import { EntitlementService } from '../../../../core/services/entitlement.service';
import { AppSettings } from '../../../../core/configs/core.config';
import { SWCoreServices } from "src/core/services/core.services";


@Injectable({
  providedIn: "root",
})
export class AppStateService {
  // subject
  private _appState: BehaviorSubject<appState>;
  private _questionnaireState: BehaviorSubject<any>;
  private _designQuestionnaire: Subject<any>;
  public questionireFormData = new BehaviorSubject<any>(null);
  public categoryFormData = new BehaviorSubject<any>(null);
  designQuestionaireFormState = new BehaviorSubject(false);
  childQuestionaireFormState = new BehaviorSubject(null);
  public loggedInUserInfoData: Array<object>;
  childIndex: any = null;
  _removeChoice = new BehaviorSubject(0);
  hasChildArr = new BehaviorSubject([]);
  userAnswerValues1 = new Subject<any>();
  tableColumnValues = new Subject<any>();
  previewFlagValues = new BehaviorSubject<any>(null);
  // observable
  questionDelete = new BehaviorSubject(0);
  tableQuestionIds=[]
  tableColumnsArr =[]
  //public designQuestionnaire$ = this._designQuestionnaire.asObservable();

  private _appInitialState: appState = new initialAppState().initialState;
  private _appData: any = this._appInitialState;
  private _newQuestion: any[] = [];
  private answerValues: any[] = [];
  public allEntitlements: any[] = [];
  isAdmin: boolean = false;
  isAdminAQ: boolean = false;
  adminEntitlement: string = AppSettings.adminRole;
  adminEntitlementAQ: string = AppSettings.adminRoleAQ;
  parentAqId:any;
  subj$ = new BehaviorSubject([]);
  // aqId=new Subject();
  aqId$=new BehaviorSubject([]);


  constructor(private entitleService: EntitlementService, private _swcore: SWCoreServices) {
    this._appState = new BehaviorSubject<any>(this._appInitialState);
    this._questionnaireState = new BehaviorSubject<any>({
      questionnaire: [],
    });
    this._designQuestionnaire = new Subject<any>();
    ////////Nilanjan Entitlements fetch////////////////////
    this.entitleService.getRoles()
      .subscribe(
        res => {
          this.allEntitlements = res;
        });

    ///////////////////////////////////////////////////////
  }
  
  send(value: any) {
    this.subj$.next(value);
  }

  sendAQ(value:any){
    this.aqId$.next(value);
  }


  setAqID(aqQuestionId:any){
    this.parentAqId=aqQuestionId;


  }
  getaqID(){
  return this.parentAqId;
  }
  setFormState(submitFlag: any) {
    this.designQuestionaireFormState.next(submitFlag);

  }
  setChildFormstate(submitFlag: any) {
    this.childQuestionaireFormState.next(submitFlag);
  }

  setParentHasChildIndex(indexArr) {
    this.hasChildArr.next(indexArr)
  }

  setAnswerResponseValue(answerValues) {
    // debugger;
    this.userAnswerValues1.next(answerValues);
  }

  setPreviewFlag(value, taskid) {
    let previewObj = { value: value, taskid: taskid }
    this.previewFlagValues.next(previewObj);
  }
  
  setTableColumnValue(questionId, tableColumns){
    if(this.tableQuestionIds.indexOf(questionId)<0){
      this.tableQuestionIds.push(questionId)
      this.tableColumnsArr.push(tableColumns)
      this.tableColumnValues.next(this.tableColumnsArr)
    }
    
  }

  resetTableColumnValues(){
    this.tableQuestionIds = []
    this.tableColumnsArr =[]
  }
  /**
   * getter function
   * @return behavioral subject observable
   */
  get appState$(): Observable<appState> {
    return this._appState.asObservable();
  }

  get appData(): any {
    return this._appData;
  }

  /**
   * setter function - sets the new state and emits final state data
   * @param newState
   */
  set appState(newState: any) {
    this._appData = Object.assign({}, this._appData, newState);
    this._appState.next(this._appData);
  }

  /**
   * questionnaire getter function
   * @return observable
   */
  get questionnaire$(): Observable<any> {
    return this._questionnaireState.asObservable();
  }

  /**
   * this function saves new created questions to backend and get updated questionnaire.
   * @param newState
   */
  set questionnnaire(newState: any) {
    this._appData = Object.assign({}, this._appData, newState);
    this._questionnaireState.next(this._appData);
  }

  /**
   * design questionnaire getter function
   * @return observable
   */
  get designQuestionnaire$(): Observable<any> {
    return this._designQuestionnaire.asObservable();
  }

  /**
   * add parent questions when adding children
   * @param question
   */
  addQuestion(question: any) {
    const q = Object.assign({}, question);
    if (this._newQuestion.length === 0) {
      question.isChild = false;
    }
    this._newQuestion.push(question);
    this._designQuestionnaire.next({
      QData: question.QData,
      checkId: q.checkId,
      index: q.index,
      QType: 'child'
    });

    this.childIndex = q.index
  }

  editQuestion(question: any) {
    const q = Object.assign({}, question);

    this._designQuestionnaire.next({
      QData: question.QData,
      checkId: q.checkId,
      index: q.index,
      QType: 'child'
    });
    this.childIndex = q.index
  }

  removeChoice(childFormIndex: number) {
    this._removeChoice.next(childFormIndex)
  }

  setAnswerValues(answerValues) {
    this.answerValues = answerValues
  }

  getAnswerValues() {
    return this.answerValues;
  }
  /**
   * remove the question once user navigates back to parent question.
   */
  removeQuestion() {
    this._designQuestionnaire.next(this._newQuestion.splice(-1, 1).pop());
  }

  /*
  * Set data to questionire form

  */

  setQuestionireFormData(data): void {
    this.questionireFormData.next(data);
  }

  getQuestionireFormData(): Observable<any> {
    return this.questionireFormData;
  }

  setCategoryQuestionireFormData(data): void {
    this.categoryFormData.next(data);
  }

  getCategoryQuestionireFormData(): Observable<any> {
    return this.categoryFormData;
  }

  setLoggedInUserInfoData(userInfo: Array<object>) {
    this.loggedInUserInfoData = userInfo;
  }

  getLoggedInUserInfoData(id: any) {
    if (this.loggedInUserInfoData)
      return this.loggedInUserInfoData[id];
  }

  checkUserIsAdmin() {
    this.allEntitlements.forEach(element => {
      if (element == this.adminEntitlement) {
        this.isAdmin = true;
      }
    });
    return this.isAdmin;
  }

  checkUserIsAdminAQ() {
    this.allEntitlements.forEach(element => {
      if (element == this.adminEntitlementAQ) {
        this.isAdminAQ = true;
      }
    });
    return this.isAdminAQ;
  }
  deleteQuestion(questionId) {
    //this.widgetLoaded = true;
    var data={
      questionid: questionId
    };
    let clickedFrom = '';
    // this.utilService.requestFrom.subscribe((data)=>{
    //   clickedFrom = data;
    // })
    this._swcore.delete(AppSettings.deleteQuestion, data).subscribe(res => {
         this.questionDelete.next(questionId)
        //this.deleteEvent.emit(question);
      }
      //this.widgetLoaded = false;
   
    );
  }
}
