import { category, initialCategoryState } from './category.model';
import { role, initialRoleState } from './role.model';
import { responseType, initialresponseTypeState } from './responseType.model';

export interface appState {
    roles: role[];
    categories: category[];
    responseTypes: responseType[];
    questionnaire: [];
}

export class initialAppState {
    get initialState(): appState {
        return {
            roles: new initialRoleState().initialState,
            categories: new initialCategoryState().initialState,
            responseTypes: new initialresponseTypeState().initialState,
            questionnaire: []
          }
    }
}


