import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AppConstant } from "../../../constant/core.constant";
import { AppSettings } from "../../../configs/core.config";
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HeaderService } from '../../business-components/header/services/header.services';
import { HttpAdapterService } from '../../../services/http-adapter.service';

// import { SWCoreServices } from "../../../services/core.services";
import { ResolverService } from "../../../services/resolver.services";
import { AppStateService } from 'src/app/modules/shared/services/app-state.service';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {
  activeMenu: string = "Dashboard";
  public menus = [];
  public moduleADGroupExists: boolean = false;
  isAdminAQ:boolean=false;
  menuAQIndex:any=-1;
  subMenuAQIndex:any=-1;

  private environmentType: string = AppSettings.SW_ENVIRONMENT;
  private ADGroupName: string[] = [];
  private agentInformation: any = {};
  private sideMenuUrl: string = AppSettings._getSideMenuList;
  private formData = {};
  private getMenuSubscription: Subscription;

  constructor(private router: Router, public headerService: HeaderService, private httpAdapterService:HttpAdapterService, private resolverService:ResolverService,private _stateService: AppStateService) { }

  ngOnInit() {
    
    // if (localStorage.getItem(AppConstant.agentLocalStoargeName) !== null) {
    //   this.agentInformation = JSON.parse(localStorage.getItem(AppConstant.agentLocalStoargeName));
    //   //this.menus = menus;
    //   this.getSideMenu();
    //   //console.log(this.getSideMenu());
    // }
    // else {
    //   this.resolverService.getAgentInformation().then(info => {
    //       this.agentInformation = info;
    //       localStorage.setItem(
    //         AppConstant.agentLocalStoargeName,
    //         JSON.stringify(info)
    //       );
    //       //this.menus = menus;
    //       this.getSideMenu();
    //       console.log("sidemenu.component.ts - 45");

    //   });
    // }
  }
//   ngAfterViewInit(){
//     this.isAdminAQ=this._stateService.checkUserIsAdminAQ();
//     console.log('Status::',this.isAdminAQ);
//     this.getMenuSubscription = this.httpAdapterService.getData(this.sideMenuUrl)
//     .subscribe(result => {
//         this.menus = result.data.CLRWorksSideMenu;
//         console.log('View::',this.menus);
//         this.menus.forEach((element,index) => {
//             element.sub.forEach((el,ind)=>{
//               console.log(el.name);
//               if(el.name=='Annual Questionnaire' && !this.isAdminAQ){
//                 this.menuAQIndex=index;
//                 this.subMenuAQIndex=ind;

//               }
//             })
//         });
//         console.log('Menu',this.menuAQIndex);
//         console.log('SubMenu',this.subMenuAQIndex);
//         if(this.menuAQIndex !=-1 && this.subMenuAQIndex!=-1){
//           //this.menus[this.menuAQIndex].sub[this.subMenuAQIndex]=[];
//           this.menus[this.menuAQIndex].sub.splice(this.subMenuAQIndex,1);
//         }


// });

//   }
  ngOnDestroy() {
    if (this.getMenuSubscription)
        this.getMenuSubscription.unsubscribe();
  }

  getSideMenu() {  
    //console.log(this.sideMenuUrl);
    this.getMenuSubscription = this.httpAdapterService.getData(this.sideMenuUrl)
        .subscribe(result => {
            this.menus = result.data.CLRWorksSideMenu;
            //console.log(result);
    });
  }
//   checkHasAdminAcess(tile:any):boolean{
//   if(tile.name==='Annual Questionnaire'){
//       return this.isAdminAQ ? true:false;
//     }
// }
  checkHasAccess(tile: any): boolean {
    // if(tile.name === 'OBA Review'){
    //     return this.headerService.obaEntitlement === 'ho_oba_review' ? true : false;
    // }
    // //Check for CSC menu
    // if(tile.name === 'CSC'){
    //     return this.headerService.cscEntitlement === 'HO_CSC_User' ? true : false;
    // }
    // if(tile.name==='Annual Questionnaire'){
    //   return this.isAdminAQ ? true:false;
    // }
    
    let userGroups = this.agentInformation['Groups'];
    if (userGroups.length > 0) {
        for (let i = 0; i < tile.accessGroup.length; i++) {            
            let accessGroupName = tile.accessGroup[i];
            for (let index in userGroups) {
                if (accessGroupName.toLowerCase().indexOf(userGroups[index].toLowerCase()) > -1)
                    return true;
            }                
        }
        return false;
    }
    return false;
  }

}
