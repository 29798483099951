import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from '../configs/core.config';

@Injectable({
  providedIn: 'root'
})
export class AccessTokenBemsService {

  constructor(private http: HttpClient) { }

  public getAccessTokenInformation(scope) {

    const body = new HttpParams()
      .set('scope','/oauth/mule-branch-exam-api-rtf')
      .set('grant_type', 'password')
      .set('username', 'branchexam')
      .set('password', AppSettings.getaccesstoken_password)

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic TFBMRFBHYXRld2F5X09hdXRoX1Bhc3NDbGllbnRfQ1A6TFBMcGFzc3cwcmRTZWNyZXQ='
    });
    let options = { headers: headers };

    return this.http
      .post(AppSettings.LPLAccessTokenInfo, body, options
      )
  }
}
