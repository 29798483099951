export interface role {
    roleId: number;
    roleCode: string;
    roleName: string;
}

export class initialRoleState {
    get initialState(): role[] {
        return [{
            roleId : 0,
            roleCode: null,
            roleName: null
        }]
    }
}