
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, Compiler } from "@angular/core";
import { CommonModule,DatePipe } from "@angular/common";
import { FormsModule,  ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
// this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
// modules
import { AppRoutingModule } from "./app-routing.module";




// component
import { AppComponent } from "./app.component";



import { SharedModule } from './modules/shared/shared.module';
import { CoreModule } from 'src/core/core.module';
import { SideMenuComponent } from 'src/core/components/ui-components/side-menu/side-menu.component';
import { SWCoreServices } from 'src/core/services/core.services';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [

    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgIdleKeepaliveModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    })
  ],
  declarations: [AppComponent, SideMenuComponent],
  bootstrap: [AppComponent],
  providers: [SWCoreServices, ToastrService,DatePipe]
})
export class AppModule {}
