import {
  Component,
  HostListener,
  ViewChild,
  OnInit,
  OnDestroy
} from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { SWCoreServices } from "../core/services/core.services";
import { AppConstant } from "../core/constant/core.constant";
import { HeaderIconsService } from "../core/components/business-components/header/services/headerIcons.services";
import { HeaderIconSettingsModel } from "../core/models/header-icon-settings.model";
import { AppSettings } from "../core/configs/core.config";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { JqFunctions } from "../core/static-class/jqfunctions.static";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import * as moment from "moment";
import { TimeFunctions } from "../core/static-class/timefunctions.static";
import { ResolverService } from "src/core/services/resolver.services";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  iconSettingsDefault: HeaderIconSettingsModel = {
    title: AppConstant.GLOBAL_HEADER_NAME,
    redirectedURL:
      JqFunctions.getServiceWorksBaseUrl() +
      AppConstant.homeAppLauncherRouteName,
    appName: AppConstant.GLOBAL_APP_NAME,
    logo: true,
    searchContact: false,
    cwConnection: false,
    userInfo: false,
    searchAdvisor: false,
    searchInvestor: false,
    fraudAlert: false,
    feedback: false,
    ticketSearch: false,
    reportsCustom: false,
    reportsBeta: false,
    quickLink: false,
    help: false,
    signout: false,
    hamburgerMenu: false,
  };
  isRootComponent = false;
  @ViewChild("timeoutModal", { static: false }) timeoutModal: NgbModal;
  modalTemp: NgbModalRef;
  timer: any;
  idleTimeOut: number = AppConstant.IDLE_TIMEOUT_TIMER;
  logOffTimeOut: number = AppConstant.LOG_OFF_TIMEOUT_TIMER;
  countdown: number = this.logOffTimeOut;
  isSessionCheckStarted = false;
  bordHidden: boolean = false;
  isLoggedOut = false;

  appLoadingText: string = "";
  appLoadingSubs: any;
  appLoaded: boolean = false;
  isVisible = true;
  showSWHeaderFlag: boolean = false;
  constructor(
    private swCoreServices: SWCoreServices,
    private router: Router,
    private idle: Idle,
    private modalService: NgbModal,
    private titleService: Title,
    private headericons: HeaderIconsService,
    private resolverService: ResolverService
    
  ) {
    const self = this;
    // //###### timout from page #####//
    // sets an idle timeout of N seconds, for testing purposes.
    idle.setIdle(this.idleTimeOut);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      localStorage.setItem(
        AppConstant.IDLE_TIMEOUT_TIMER_LOCAL_STORAGE,
        AppConstant.IDLE_TIMEOUT_TIMER_LOCAL_STORAGE_VALUE_ARRAY[1]
      );
      // console.log('No longer idle.');
    });
    idle.onTimeout.subscribe(() => {
     // this.timeOutOpenModal();
    });
    idle.onIdleStart.subscribe(() => {
      // console.log('You\'ve gone idle!');
      localStorage.setItem(
        AppConstant.IDLE_TIMEOUT_TIMER_LOCAL_STORAGE,
        AppConstant.IDLE_TIMEOUT_TIMER_LOCAL_STORAGE_VALUE_ARRAY[0]
      );
    });
    window.addEventListener("storage", function(ev) {
      if (
        ev.key == null &&
        (ev.url.indexOf("signout") > -1 || ev.url.indexOf("error") > -1) &&
        (localStorage.getItem(AppConstant.agentLocalStoargeName) == null ||
          localStorage.getItem(AppConstant.agentLocalStoargeName) == "undefined")
      ) {
        self.timeOutLogOff();
      } else {
        if (ev.key === AppConstant.IDLE_TIMEOUT_TIMER_LOCAL_STORAGE) {
          if (
            localStorage.getItem(
              AppConstant.IDLE_TIMEOUT_TIMER_LOCAL_STORAGE
            ) == AppConstant.IDLE_TIMEOUT_TIMER_LOCAL_STORAGE_VALUE_ARRAY[1]
          ) {
            self.idle.stop();
            self.timeOutStayConnected();
          } else if (
            localStorage.getItem(
              AppConstant.IDLE_TIMEOUT_TIMER_LOCAL_STORAGE
            ) == AppConstant.IDLE_TIMEOUT_TIMER_LOCAL_STORAGE_VALUE_ARRAY[2]
          ) {
            self.timeOutLogOff();
          }
          // console.log("Change idle state value");
        }
      }
    });

    //### web worker sample

    // if (typeof Worker !== "undefined") {
    //   // Create a new
    //   const worker = new Worker("./app/my-worker.worker", { type: "module" });
    //   worker.onmessage = ({ data }) => {
    //     console.log(`page got message: ${data}`);
    //   };
    //   worker.postMessage("hello");
    // } else {
    //   // Web Workers are not supported in this environment.
    //   // You should add a fallback so that your program still executes correctly.
    // }
  }
  ngOnInit() {
    const self = this;
    this.router.events.subscribe((ob: any) => {
      if (ob instanceof NavigationEnd) {
        try {
          if (
            ob.url.indexOf("signout") === -1 &&
            ob.url.indexOf("popout-quick-ticket") === -1 &&
            ob.url.indexOf("popout-sr-advanced-search") === -1 &&
            ob.url.indexOf("popout-notifications-advsearch") === -1 &&
            ob.url.indexOf("meeting-popout") === -1 &&
            ob.url.indexOf("error") === -1 &&
            ob.url.indexOf("chatbot") === -1
          ) {
            /// CW login
            // this.swCoreServices.addCWFirmloginIframe(
            //   AppConstant.cwLPLFirm,
            //   true,
            //   function(response) {}
            // );
          } else if (
            ob.url.indexOf("signout") > -1 ||
            ob.url.indexOf("error") > -1
          ) {
          }
          if (ob.url == "/error" || ob.url == "/signout") {
            this.isLoggedOut = true;
            this.isSessionCheckStarted = false;
            this.idle.stop();
          } else {
            this.isLoggedOut = false;
          }

          if (ob.url.indexOf("meeting-popout") > -1) {
            this.isVisible = false;
          }
          // set browser title according to SW page
          for (let i = 0; i < AppConstant.SW_APP_TITLE.length; i++) {
            this.titleService.setTitle(AppConstant.Non_CW_APP_TITLE);
            // if (ob.url.indexOf(AppConstant.SW_APP_TITLE[i].url) > -1) {
            //   this.titleService.setTitle(AppConstant.SW_APP_TITLE[i].title);
            //   break;
            // }else if(window.location.host.includes(AppSettings.domain_check)){
            //   this.titleService.setTitle(AppConstant.Non_CW_APP_TITLE);
            // } 
            // else {
            //   this.titleService.setTitle(AppConstant.SW_APP_TITLE[0].title);
            // }
          }
        } catch (e) {}
      }
      if (ob instanceof NavigationStart) {
        if (
          ob.url != "/error" &&
          ob.url != "/signout" &&
          ob.url.indexOf("meeting-popout") == -1 &&
          ob.url.indexOf("popout-quick-ticket") == -1 &&
          ob.url.indexOf("popout-sr-advanced-search") == -1 &&
          ob.url.indexOf("popout-notifications-advsearch") === -1
        ) {
          this.appLoadingSubs = this.swCoreServices
            .getAppLoadingStatus()
            .subscribe(res => {
              if (res && res.hasOwnProperty("appLoaded")) {
                this.appLoaded = res.appLoaded;
                this.appLoadingText = res.appLoadingText || "";
                if (this.appLoaded) {
                  //this.appLoadingSubs.unsubscribe();
                }
              }
            });
        } else {
          this.appLoaded = true;
        }
      }
      // if (ob instanceof NavigationStart) {
      //   //(ob.url.includes('userLogin/login')) || (ob.url.includes('userPage')) || 
      //   if (window.location.host.includes(AppSettings.domain_check)) {
      //     this.showSWHeaderFlag = false;
      //   }else
      //   this.showSWHeaderFlag = true;
      // }
    });

    this.headericons.setHeaderIcons(this.iconSettingsDefault);

    //####################### always check if the session start time & end time is reached ########//
    setInterval(() => {
      if (!this.isLoggedOut) {
        const startTime = TimeFunctions.date(
          TimeFunctions.dateFormat() +
            " " +
            AppConstant.SESSION_TIMER_START_TIME
        );
        const endTime = TimeFunctions.date(
          TimeFunctions.dateFormat() + " " + AppConstant.SESSION_TIMER_END_TIME
        );
        const currentTime = TimeFunctions.date();
        if (
          TimeFunctions.dateDuration(currentTime, startTime, "seconds") >= 0 &&
          TimeFunctions.dateDuration(endTime, currentTime, "seconds") >= 0
        ) {
          this.isSessionCheckStarted = false;
          self.idle.stop();
        } else {
          if (!this.isSessionCheckStarted) {
            this.isSessionCheckStarted = true;
            self.idle.watch();
          }
        }
      }
    }, AppConstant.BACKGROUND_SESSION_INTERVAL_IN_MILLISECONDS);
  }
  @HostListener("window:keydown", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (
      event.keyCode == 86 &&
      event.ctrlKey &&
      event.shiftKey &&
      event.altKey
    ) {
      alert(
        "Environment: " +
          AppSettings.SW_ENVIRONMENT +
          "  [Version: " +
          AppSettings.APPLICATION_VERSION +
          "]"
      );
    }
    // if (
    //   event.keyCode == 75 &&
    //   event.ctrlKey &&
    //   event.shiftKey &&
    //   event.altKey
    // ) {
    //   let partofurl = window.location.hash;
    //   let redirectorApp =
    //     JqFunctions.getRedirectorBaseUrl(AppConstant.REDIRECTOR_APP_NAME) +
    //     partofurl + "&isVersionSelector=true";
    //   window.open(redirectorApp, "_blank");
    // }
  }

  ngOnDestroy(): void {
    // this.FRfacade.deleteAllEntitlement();
  }
  @HostListener("window:load", ["$event"])
  onWebLoad(event: KeyboardEvent) {
    JqFunctions.windowResize();
  }
  @HostListener("window:resize", ["$event"])
  onWebResize(event: KeyboardEvent) {
    JqFunctions.windowResize();
  }
  timeOutOpenModal() {
    this.countdown = this.logOffTimeOut;
    this.timer = setInterval(() => {
      this.countdown--;
      if (this.countdown < 0) {
        this.timeOutLogOff();
      }
    }, 1000);
    this.modalTemp = this.modalService.open(this.timeoutModal, {
      backdrop: "static",
      keyboard: false
    });
  }
  timeOutLogOff() {
    try {
      this.modalTemp.close();
    } catch (e) {}
    clearInterval(this.timer);
    location.href = "#/signout";
  }
  timeOutStayConnected() {
    this.modalTemp.close();
    clearInterval(this.timer);
    this.idle.watch();
    localStorage.setItem(
      AppConstant.IDLE_TIMEOUT_TIMER_LOCAL_STORAGE,
      AppConstant.IDLE_TIMEOUT_TIMER_LOCAL_STORAGE_VALUE_ARRAY[1]
    );
  }
}
