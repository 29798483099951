import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/observable/of'
import { SWCoreServices } from '../../../../core/services/core.services';
import { AppSettings } from '../../../../core/configs/core.config';
//import { FilterModelData } from '../../shared/models/filter-data.model';
import { HttpClient } from '@angular/common/http';
import { FilterModelData } from '../models/filter-data.model';
import * as XLSX from 'xlsx';

export enum APP_TYPE {
  AdminDashboard = 'AdminDashboard',
  // PROFILE = 'profile',
  // STATEREGISTRATION = 'profile',
  ADMINTOOL = 'admin',
  ADVISORLIST = 'configurationmanagement'
}

@Injectable()

export class BEMSService {
  private behave = new BehaviorSubject<Object>({ menuId: 'no-sub' });
  //private _gridRecords=  new Subject<any>();
  private _gridRecords = new BehaviorSubject<any>(undefined);
  private _detailRecords = new BehaviorSubject<any>({});
  private _isCommentAdded = new BehaviorSubject<boolean>(false);
  private isCommentMandatory = new BehaviorSubject<boolean>(false);  

  private filterParam = new Subject<any>();
  private appliedFilter = new BehaviorSubject<any>({});
  private toggleFilter = new Subject<any>();

  private toggleSideMenu = new Subject<boolean>();
  private isTampsSupervision = new BehaviorSubject<boolean>(false);

  private statusInfo = new BehaviorSubject('');
  currentStatus = this.statusInfo.asObservable();
  private _appType: string;
  private _selectedTaskTabName: string;
  //Task Details data holder
  private _taskDetailsData = new BehaviorSubject<any>({});
  private _requiredRegistration = new BehaviorSubject<any>([]);
  private _accountsInfo = new BehaviorSubject<any>([]);
  private _accountList = new BehaviorSubject<any>([]);
  private _masterRepId = new BehaviorSubject<any>('');
  private _selectedState = new BehaviorSubject<any>({});
  private _selectedAccounts = new BehaviorSubject<any>({});
  private _taskStatus = new BehaviorSubject<any>('');


  constructor(private coreService: SWCoreServices, private http: HttpClient) { }

  setTableData(data: Array<any>) {
    this._gridRecords.next(data);
    this.isTampsSupervision.next(data['isTampsSupervision']);
  }

  getTableData(): Observable<any> {
    return this._gridRecords.asObservable();
  }

  getTampsSupervision(): boolean {
    return this.isTampsSupervision.value;
  }

  setTaskDetailTransactionsData(data: Array<any>) {
    this._detailRecords.next(data);
  }

  getTaskDetailTransactionsData(): Observable<any> {
    return this._detailRecords.asObservable();
  }

  setIsCommentAdded(yesno) {
    this._isCommentAdded.next(yesno);
  }

  getIsCommentAdded(): boolean {
    return this._isCommentAdded.value;
  }

  setCommentMandatoryFlag(flag){
    this.isCommentMandatory.next(flag);
  }

  getCommentMandatoryFlag(): Observable<boolean>{
    return this.isCommentMandatory.asObservable();
  }

  getFilterParam(): Observable<any> {
    return this.filterParam.asObservable();
  }
  setFilterParam(data) {
    this.filterParam.next(data);
  }

  getAppliedFilterList(): Observable<any> {
    return this.appliedFilter.asObservable();
  }
  setAppliedFilterList(data) {
    this.appliedFilter.next(data);
  }

  getAppliedFilter(): Observable<any> {
    return this.toggleFilter.asObservable();
  }
  setAppliedFilter(data) {
    //Remove duplicate filter if its already ther in array
    data = data.filter((data, index, self) =>
      index === self.findIndex((t) => (
        t.filterName === data.filterName
      ))
    )
    this.toggleFilter.next(data);
  }

  getToggleSideMenu(): Observable<any> {
    return this.toggleSideMenu.asObservable();
  }

  setToggleSideMenu() {
    let toggleSideMenu$ = !this.toggleSideMenu.asObservable();
    this.toggleSideMenu.next(toggleSideMenu$);
  }
  postData(url: string, formData?: any, headers?: any): Observable<any> {

    return this.http
      .post(url, formData, headers)
      .map(response => response);
  }

  updatedStatus(status) {
    this.statusInfo.next(status)
  }

  getFilters(): Observable<FilterModelData> {
   let url: string = AppSettings.getTampsFilterList;
  //  let url: string = AppSettings.getHoFilterList;
    return this.coreService
      .get(url).pipe(
      map(response => response.data[this.appType]));
  }

  static toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }

  public exportAsExcelFile(json: any[], excelFileName: string, gridConfig: any): void {

    let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json); 
   
    let wscols = [];
    let wrow = [];
    let charPixel = 50;
    for (let i = 0; i < gridConfig.length; i++) {
      let colWidth = gridConfig[i]['width'] + charPixel
      wscols.push({
        wpx: colWidth
      });
    } 
    worksheet['!rows'] = [{ hpx: 30 }];
    worksheet['!cols'] = wscols;

    const workbook: XLSX.WorkBook = { Sheets: { 'Questionnaire': worksheet }, SheetNames: ['Questionnaire'] };
    
    XLSX.writeFile(workbook, BEMSService.toExportFileName(excelFileName), { bookType: 'xlsx' });
  }

  get appType(): string {
    return this._appType;
  }

  set appType(aAppType: string) {
      this._appType = aAppType;
  }

  get selectedTaskTabName(): string {
    return this._selectedTaskTabName;
  }

  set selectedTaskTabName(aTabName: string) {
      this._selectedTaskTabName = aTabName;
  }

  checkAppType(url: string) {
    if(url.indexOf(APP_TYPE.AdminDashboard) > -1){
      this.appType = APP_TYPE.AdminDashboard;
    }else if(url.indexOf(APP_TYPE.ADMINTOOL) > -1){
      this.appType = APP_TYPE.ADMINTOOL
    }else if(url.indexOf(APP_TYPE.ADVISORLIST) > -1){
      this.appType = APP_TYPE.ADVISORLIST
    }
  }

  /*** Task Details Data ***/

  setTaskDetailsData (data: any){
    console.log("Inside Set Task detail", data)
    this._taskDetailsData.next(data);
    this._requiredRegistration.next(data.RequiredRegistrations);
    this._accountsInfo.next(data.AccountList);
    this._masterRepId.next(data.id);
    this._taskStatus.next(data.advisorStatus);
  }
  getTaskDetails(): Observable<any>{
    console.log("Inside get Task Details", this._taskDetailsData);
    return this._taskDetailsData.asObservable();
  }
  getReqRegDetails(): Observable<any>{
    return this._requiredRegistration.asObservable();
  }
  getAccountList(): Observable<any>{
    return this._accountsInfo.asObservable();
  }
  getAccountsData(): Observable<any>{
    return this._accountsInfo.asObservable();
  }
  setSelectedStates(data: any){
    this._selectedState.next(data);
  }
  getSelectedStates(): Observable<any>{
    return this._selectedState.asObservable();
  }
  setSelectedAccountss(data: any){
    this._selectedAccounts.next(data);
  }
  getSelectedAccounts(): Observable<any>{
    return this._selectedAccounts.asObservable();
  }

}
