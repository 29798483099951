export class AdminSettings {
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ API @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  // advisor details
  public static advisorDetails = "dummyAdvisorDetails";
  // advisor search
  public static searchAdvisorFields = "SearchAdvisorFields";
  public static searchAdvisor = "SearchAdvisor";
  public static searchInvestor = "SearchInvestor";
  public static fraudReport = "FraudReport";
  public static serviceRequestSearch = "ServiceRequestSearch";
  public static searchContact = "SearchContact";
  public static taskOriginator = "TaskOriginator";
  public static userPreferenceApplicationAndComponent =
    "UserPreferenceApplicationAndComponent";
  public static savePreference = "SavePreference";
  public static cwLogout = "CwLogout";
  public static authAudit = "AuthAudit";
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ APP @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  public static quickLink = "QUICK_LINKS";
  public static iKnow = "iKnow";
  public static deptSearch = "DeptPhoneQueue";
  public static medalliaEmbedJs = "MEDALLIA_EMBEDJS";
}
