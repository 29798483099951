export interface responseType {
    [x: string]: any;
    responseTypeId: number;
    responseTypeCode: string;
    responseTypeName: string;
}

export class initialresponseTypeState {
    get initialState(): responseType[] {
        return [{
            responseTypeId : 0,
            responseTypeCode : null,
            childResponseTypeCode: null,
            responseTypeName : null
        }]
    }
}