/**
 * Part of core feature services
 * Handles all ForgeRock entilement related wrapper functions
 * 
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject, BehaviorSubject, of, forkJoin } from "rxjs";
@Injectable({
  providedIn: "root"
})
export class EntitlementService {
  constructor(private http: HttpClient) {}
  private entitlementRoleObs = new BehaviorSubject<any>([]);
  fullEntitlementData: any = [];
  fullEntitlementRoles: any = [];

  setRoles(data): void {
    this.entitlementRoleObs.next(data);
    this.fullEntitlementRoles = data;
  }
  getRoles(): Observable<any> {
    return this.entitlementRoleObs.asObservable();
  }
  getComponentAccessDetails(componentName) {
    let componentAccessDetails = this.fullEntitlementData.filter(function(
      component
    ) {
      return component.name == componentName;
    });
    if (componentAccessDetails[0]) {
      return componentAccessDetails[0];
    } else {
      return {};
    }
  }

  hasReadAccessFromEntitlements(componentName): boolean {
    let entitlementData = this.getComponentAccessDetails(componentName);
    if (Object.keys(entitlementData).length > 0) {
      return true;
    } else {
      return false;
    }
  }
  hasCreateAccessFromEntitlements(componentName): boolean {
    let entitlementData = this.getComponentAccessDetails(componentName);
    if (entitlementData && entitlementData.hasOwnProperty("action")) {
      if (
        entitlementData.action.length > 0 &&
        entitlementData.action.indexOf("Create") > -1
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  hasUpdateAccessFromEntitlements(componentName): boolean {
    let entitlementData = this.getComponentAccessDetails(componentName);
    if (entitlementData && entitlementData.hasOwnProperty("action")) {
      if (
        entitlementData.action.length > 0 &&
        entitlementData.action.indexOf("Update") > -1
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  hasDeleteAccessFromEntitlements(componentName): boolean {
    let entitlementData = this.getComponentAccessDetails(componentName);
    if (entitlementData && entitlementData.hasOwnProperty("action")) {
      if (
        entitlementData.action.length > 0 &&
        entitlementData.action.indexOf("Delete") > -1
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hasRolesFromEntitlements(roleName: string | Array<string>): boolean {
    if((roleName === null) || (roleName === undefined)) {
      return false;
    }
    let type = typeof roleName;
    let filteredRoles = [];
    let roleNameArr = roleName as Array<string>;
    try {
      switch (type) {
        case "string":
          filteredRoles = this.fullEntitlementRoles.filter(function(role) {
            return role.toLowerCase().indexOf((roleName as string).toLowerCase()) > -1;
          });
          break;
        case "object":
          filteredRoles = this.fullEntitlementRoles.filter(function(role) {
            // for(let i=0; i<roleNameArr.length; i++) {
              for (let i of roleNameArr){
              return role.toLowerCase().indexOf(roleName[i].toLowerCase()) > -1;
            }
          });
          break;
      }
      if (filteredRoles && filteredRoles.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
}
