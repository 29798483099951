export interface category {
    categoryId: number;
    categoryCode: string;
    categoryName: string;
}

export class initialCategoryState {
    get initialState(): category[] {
        return [{
            categoryId : 0,
            categoryCode: null,
            categoryName: null
        }]
    }
}