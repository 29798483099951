export class Trace {
    id: string;
    constructor() {
      this.id = ''
    }
  }
  export class  Event {
    module  : string;
    action  : string;
    duration: number;
    outcome : string;
    constructor() {
        this.module = '';
        this.action = '';
        this.duration = null;
        this.outcome = '';
      }
  }
  export class  Transaction {
    id?:  string;
    type    : string;
    constructor() {
        this.id = ''
        this.type = ''
      }
  }
  export class  Destination {
    name    : string;
    url     : string;
    type    : string;
    constructor() {
        this.name = '';
        this.url = '';
        this.type = '';
      }
  }

export class LogPayload {
  constructor() {
    return {
      "trace": {
          "id": ""
      },
      "event": {      
          "module": "",       
          "action": "",
          "duration": "", //string
          "outcome": "" 
          // "attrib":{}
      },
        "eventattribs": {
            "taskTitle": "",
            "taskCategory": "",
            "taskRep": "",
            "taskSubcategory": ""
        },
      "transaction": {
          "id": "",
          "type": ""
      },
      "destination": {
          "name": "",
          "url": "",
          "type": "",
      },
      "message": "" , 
      "user"  : {
        "browser":{
          "screensize": "",
        }
      }

    }
  }
}