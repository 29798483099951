import { TaskOriginator } from "../models/task-originator.model";
import { AgentModel } from "../models/agent.model";
import { Injectable, Inject } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Http, RequestOptions, RequestMethod } from "@angular/http";

import { Observable, Subject, BehaviorSubject, of, forkJoin } from "rxjs";
import { isDevMode } from "@angular/core";
import { AppSettings } from "../../../../configs/core.config";
import { SWCoreServices } from "../../../../services/core.services";
import { AcitivityStreamLogService } from "../../../../services/activity-stream-log.services";
import { AppConstant } from "../../../../constant/core.constant";
import { WINDOW } from "../../../../services/window-providers.services";
import { catchError, map, mergeMap, flatMap,tap } from "rxjs/operators";
import { JqFunctions } from "../../../../static-class/jqfunctions.static";

@Injectable()
export class HeaderService {
  private agentInfo = new BehaviorSubject<any>({});
  constructor(
    private swCoreServices: SWCoreServices,
    private activityStreamLog: AcitivityStreamLogService,
      @Inject(WINDOW) private window: Window
      // private FRfacade: ForgeRockFacade
  ) {}

  getCallerTemplate() {
    return this.swCoreServices
      .get("app/header/components/callerTemplate.html", {})
      .pipe(map((res: any) => res));
  }

  checkIEBrowser() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      return true;
    } // If another browser, return 0
    else {
      return false;
    }
  }

  goToErrorPage() {
    location.href = "#/error";
    return false;
  }
  clearCookiesandImage() {
    if (
      localStorage.getItem("APPLICATION_VERSION") !=
      AppSettings.APPLICATION_VERSION
    ) {
      localStorage.clear();
      const cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
      localStorage.setItem(
        "APPLICATION_VERSION",
        AppSettings.APPLICATION_VERSION
      );
    }
    return true;
  }
}
