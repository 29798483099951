import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgentModel } from '../models/agent.model';
import { SWCoreServices } from '../../core/services/core.services';

@Injectable({
    providedIn: 'root'
})
export class HttpAdapterService {
    cookie: string = '';
    agentInformation: AgentModel = this.createEmptyAgent();
    userID: string;

    constructor(private httpClient: HttpClient, private SWCoreServices: SWCoreServices) {
        this.userID = this.SWCoreServices.agentInformation.UserName;
    }

    get(
        url: string,
        formData?: any,
        additionalHeaders = {},
        jsonOutput = false
      ): Observable<any> {
        let params = new HttpParams();
        try {
          Object.keys(formData).forEach(function (key) {
            params = params.append(key, formData[key]);
          });
        } catch (e) { }
        //console.log(url, params);
        let tempHeaders = {
          "Content-type": "application/json"
        };
        tempHeaders = this.extendHeader(tempHeaders, additionalHeaders);
        let headers = new HttpHeaders(tempHeaders);
        let options = {
          params: params,
          withCredentials: true,
          headers: headers
        };
        if (!jsonOutput) {
          return this.httpClient.get(url, options).pipe(map(response => response));
        } else {
          return this.httpClient
            .get(url, options)
            .pipe(map(response => JSON.stringify(response)));
        }
      }

    getData(api: string): Observable<any> {
        //console.log(api);
        // if (addUserName)
        //     return this.httpClient.get(api, { params: { userName: this.userID } })
        // else
        return this.httpClient.get(api);
    }

    getWithCredentials(api: string): Observable<any> {
        //console.log(api);
        return this.httpClient.get(api, {withCredentials: true});
    }

    postData(api: string, data: any): Observable<any> {
        data.userName = this.userID;
        let tempHeaders = {
            'Content-type': 'application/json'
        };
        let headers = new HttpHeaders(tempHeaders);
        return this.httpClient.post(api, data, { headers: headers })
    }

    hardCodePost(api: string, data: any): Observable<any> {
        return new Observable(observer => {
            this.httpClient.post(api, data, { params: { userName: this.userID } })
                .subscribe((data) => {
                    observer.next(data);
                    observer.complete();
                }, (err) => {
                    observer.error(err);
                    observer.complete();
                })
        })
    }

    extendHeader(a, b) {
        for (var key in b)
            if (b.hasOwnProperty(key))
                a[key] = b[key];
        return a;
    }
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                this.cookie = c.substring(name.length, c.length);
            }
        }
        return this.cookie;
    }
    createEmptyAgent() {
        return {
            "Domain": "",
            "UserName": "",
            "EmailAddress": "",
            "FirstName": "",
            "LastName": "",
            "DisplayName": "",
            "AccountEnabled": false,
            "AccountExpirationDate": "",
            "AccountLockoutTime": "",
            "Groups": [
            ],
            "ClientWorksUserName": "",
            "JwtToken": "",
            "SessionID": ""
        };
    }

}
