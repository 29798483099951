import { AppSettings } from 'src/core/configs/core.config';

//API name
export const GET_CATEGORIES = 'getcategory';
export const GET_ROLES = 'getroles';
export const GET_RESPONSE_TYPES = 'getresponsetypes';
export const GET_DEPARTMENTS = 'getdepartments';
export const GET_QUESTIONNAIRE = 'getquestionnaire';
export const agentLocalStoargeName = 'agentInformation';
//export const GET_USER_INFO='getuserInfo';
export const GET_USER_TASK_LIST='gettaskList'

//constant for API
export const API_URI = AppSettings.adminToolApi;
export const API_DATA = {
    'getcategory': AppSettings.getSectionsURL,
    'getroles': AppSettings.getRolesURL,
    'getresponsetypes': AppSettings.getResponseUIUrl,
    'getdepartments': AppSettings.getContentOwnerDept,
    'getquestionnaire': AppSettings.getQuestion,
    //'getuserInfo':AppSettings.getUserInfo,
    'gettaskList':AppSettings.getusertaskList,

}

//Constants for 
// export const LOCAL_URI = 'assets/data/'
// export const LOCAL_DATA = {
//     'getcategory': LOCAL_URI + 'QCategories.json',
//     // 'getroles': LOCAL_URI + 'QRoles.json',
//     'getroles': AppSettings.getRolesURL,
//     'getresponsetypes': LOCAL_URI + 'QResponseTypes.json',
//     'getdepartments': LOCAL_URI + 'QDepartment.json',
//     'getquestionnaire': LOCAL_URI + 'QQuestions.json'
// }