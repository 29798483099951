import { config } from "process";

declare var configEnvironment: any;

export class AppSettings {
  public static APPLICATION_VERSION = "2.1.14";

  public static SW_ENVIRONMENT = configEnvironment.sw_Environment;
  public static HO_CORE_BASE_URL = configEnvironment.ho_core_base_url;
  public static logger = configEnvironment.LOGGER_SERVICE;
  public static IS_LOGGER_ACTIVE = configEnvironment.IS_LOGGER_ACTIVE;

  public static SW_REST_BASE_URL = configEnvironment.sw_RestBaseUrl;
  public static _fireClientRepChangeServices =
    AppSettings.SW_REST_BASE_URL + "/SW2AccountUpdate/";

  public static FORGE_ROCK_LOGIN_URL = configEnvironment.sw_forgeRock;
  public static SIEBEL_WEB_URL = configEnvironment.siebel_WebUrl;
  public static SW_RC_BASE_URL = configEnvironment.rc_WebUrl;
  public static SW_IKNOW_BASE_URL = configEnvironment.iKnow_WebUrl;
  public static profileLink =
    "http://mysite.corp.lpl.com/User%20Photos/Profile%20Pictures/corp_";
  public static SALESFORCE_SERVICE_URL =
    configEnvironment.salesForce_ServiceUrl;
  public static CW_REST_SERVICE_URL = configEnvironment.cw_ServiceUrl + "/";
  public static CW_WEB_URL = "https://" + configEnvironment.cw_WebUrl;
  public static CW_WEB_AXA_URL = "https://" + configEnvironment.axa_WebUrl;
  public static CW_LOGIN_URL = configEnvironment.cw_LoginUrl;
  public static NOTIFICATION_WEBURL = configEnvironment.notification_WebUrl;
  public static DOCUMENTUM_WEBURL = configEnvironment.documentum_webUrl;
  public static closeAccountBaseURL = configEnvironment.closeAccount_URL;
  public static DATA_POWER_URL = configEnvironment.dataPower_URL;
  public static IKNOW_DOC_URL = configEnvironment.iKnow_DocUrl;
  public static medallia_URL = configEnvironment.medallia_URL;
//http://datapowerdevint.sddev.lpl.com:8061/branch-exams/exp/get-sections?sectionid=1 
//http:// I01NSVL-WPLMG01.SDDEV.LPL.COM:8092/branch-exams/exp/get-roles/{roleid} 
//http:// I01NSVL-WPLMG01.SDDEV.LPL.COM:8092/branch-exams/exp/get-response-ui-type/{responseuitypeid} 
//http:// I01NSVL-WPLMG01.SDDEV.LPL.COM:8092/branch-exams/exp/get-response-ui-type/{responseuitypeid} 
//http:// I01NSVL-WPLMG01.SDDEV.LPL.COM:8092/branch-exams/exp/get-content-owner-department/{contentownerdeptid} 
//http://I01NSVL-WPLMG01.SDDEV.LPL.COM:8092/branch-exams/exp/get-question-response 
  public static APiUrl = configEnvironment.BEMSAPIBaseURL;
  public static httpsMuleUrl = configEnvironment.BEMSAPIBaseURLHttps;
  public static externalMuleUrl = configEnvironment.muleExternalServiceRoot;
  public static BEMSExtendedURL = "/mule-branch-exam-api-rtf/";
  public static getSectionsURL = AppSettings.APiUrl +"/mule-branch-exam-api-rtf/get-sections/";
  public static getRolesURL = AppSettings.APiUrl +"/mule-branch-exam-api-rtf/get-roles/";
  public static getResponseUIUrl=AppSettings.APiUrl+"/mule-branch-exam-api-rtf/get-response-ui-type/";
  public static getContentOwnerDept=AppSettings.APiUrl+"/mule-branch-exam-api-rtf/get-content-owner-department/";
  public static getQuestion=AppSettings.APiUrl+ AppSettings.BEMSExtendedURL + "get-question-response/";
  //public static getUserInfo=AppSettings.APiUrl+"annuity-recommendation/exp/taskdetails";
  public static changeQuestionOrder = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/change-question-order";
  public static deleteQuestion = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/delete-question";
  public static escalationGroup = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/get-escalation-groups";
  public static getusertaskList= AppSettings.APiUrl + "/mule-branch-exam-api-rtf/get-user-task-list";
  public static getallusertaskList= AppSettings.APiUrl + "/mule-branch-exam-api-rtf/get-user-task-list-all";
  public static getTaskStatusDetails=AppSettings.APiUrl + "/mule-branch-exam-api-rtf/get-task-status-details";
  // http://datapowerdevint.sddev.lpl.com:8061/branch-exams/exp/save-question-and-response
  public static postQuesResp = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/save-question-and-response";
  public static postConfigMangEscGroup = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/save-escalation-groups";
  public static postConfigMangCategory = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/save-sections";
  public static postConfigMangContentOwnerDept = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/save-content-owner-departments";
  public static postAnnualQuestionnaire = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/save-annual-questionnaire";
  public static getUserDetails = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/get-user-additional-details";
  public static getPDFUrl =  AppSettings.APiUrl + "/mule-branch-exam-api-rtf/download-pdf";
  public static postUserResp = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/save-response";
  public static adminRole = configEnvironment.AdminRole;
  public static adminRoleAQ = configEnvironment.AdminRoleAQ;
  public static getUserType = AppSettings.APiUrl + "/associate-person-api-rtf/api/fetchusertype";
  public static getUserId = AppSettings.APiUrl + "/associate-person-api-rtf/api/fetchuserid";
  public static getUserTask = AppSettings.APiUrl + "/associate-person-api-rtf/api/serchresults";
  public static postUserTaskNotes = AppSettings.APiUrl + "/associate-person-api-rtf/api/updateuser";
  public static getQuestionnaire = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/get-annual-questionnaire";
  public static getadvisorsDetails = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/search-rep-for-aq-distribution";
  public static getbranchDetails = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/search-aq-osj-branch";
  public static lauchQuestionnaire = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/create-advisor-aq-task";
  public static searchRepByRegion = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/search-rep-by-osj-region";
  public static getRegionStates = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/get-regions-states-list";
  public static setTaskInProgress = AppSettings.APiUrl + '/mule-branch-exam-api-rtf/set-aq-task-in-progress';
  //public static searchRepByOffice = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/search-aq-osj-branch";
  ////////////////Filter and search AdminDashboard
  
  public static getTampsFilterList = 'assets/json/BEMS-filter-group-list.json';
  //public static _hoFilterService = 'http://datapowerdevint.sddev.lpl.com:8061' + '/state-registration/exp/ho-state-registration-task-mismatch-filter';
  public static _hoFilterService = AppSettings.APiUrl + '/mule-branch-exam-api-rtf/search-task-list';
  //public static getHoTaskList = 'http://datapowerdevint.sddev.lpl.com:8061' + '/state-registration/exp/ho-state-registration-task-mismatch';
  public static getHoTaskList = AppSettings.APiUrl + '/mule-branch-exam-api-rtf/get-user-task-list-all';
  public static reopenQuestionnaire = AppSettings.APiUrl + "/mule-branch-exam-api-rtf/reopen-question";
  ////////////

  //----------------------------------------------------------------------------------------------------------//
  // public static CPS_CLIENT_ID=configEnvironment.cpsClientId;
  // public static CPS_SECRET=configEnvironment.cpsClientSecret; 
  //public static CW_WEB_TOKEN_URL = AppSettings.CW_WEB_URL + "/cdn/lpl/img/clientworks/cw-logo.svg?adAuth=true";

  public static siebelUrl = AppSettings.SIEBEL_WEB_URL;
  public static salesforceUrl = AppSettings.SALESFORCE_SERVICE_URL;

  public static _preferenceServices =
    AppSettings.SW_REST_BASE_URL + "/swuserpreference/";
  public static _agentAuthServices =
    AppSettings.SW_REST_BASE_URL + "/SW2ADAuthSession/";
  public static _callerAuthServices =
    AppSettings.SW_REST_BASE_URL + "/swcallerauth/";
  public static _callerInfoServices =
    AppSettings.SW_REST_BASE_URL + "/swcallerinfo/";
  public static _siebelsrServices =
    AppSettings.SW_REST_BASE_URL + "/swsiebelsr/";
  public static _alertServices =
    AppSettings.SW_REST_BASE_URL + "/swuseralerts/";
  public static _accountNotesServices =
    AppSettings.SW_REST_BASE_URL + "/swaccountnotes/";
  public static _accountHistServices =
    AppSettings.SW_REST_BASE_URL + "/swaccountactivity/";
  public static _accountInfoServices =
    AppSettings.SW_REST_BASE_URL + "/swaccountinfo/";
  public static _securityServices =
    AppSettings.SW_REST_BASE_URL + "/swcwsecurity/";
  public static _mulesoftcallmetrics =
    AppSettings.SW_REST_BASE_URL + "/swcallmetricswrapper/";
  public static _advisorCommission =
    AppSettings.SW_REST_BASE_URL + "/AdvisorCommission/";
  public static _wrapperServices =
    AppSettings.SW_REST_BASE_URL + "/swapiwrapper/";
  public static _swcwServices = AppSettings.SW_REST_BASE_URL + "/swcwuser/";
  public static _accountSearchServices =
    AppSettings.SW_REST_BASE_URL + "/swaccountsearch/";
  public static _swConfigRest =
    AppSettings.SW_REST_BASE_URL + "/swconfiguration/";
  public static _swEmailRest = AppSettings.SW_REST_BASE_URL + "/swemail/";
  public static _notificationAPI =
    AppSettings.SW_REST_BASE_URL + "/NotificationsApi/";
  public static _accountholderServices =
    AppSettings.SW_REST_BASE_URL + "/swaccountholder/";
  public static _swapplicationversion =
    AppSettings.SW_REST_BASE_URL + "/swapplicationversion/";

    // public static APiUrl = "http://datapowerdevint.sddev.lpl.com:8061/";
    // public static getSectionsURL =AppSettings.APiUrl +"branch-exams/exp/get-sections/";
    // public static getRolesURL =AppSettings.APiUrl +"branch-exams/exp/get-roles/";
    // public static  getResponseUIUrl=AppSettings.APiUrl+"branch-exams/exp/get-response-ui-type/";
    // public static  getContentOwnerDept=AppSettings.APiUrl+"branch-exams/exp/get-content-owner-department/";
    // public static  getQuestion=AppSettings.APiUrl+"branch-exams/exp/get-question-response/";
  // public static medallia_URL =
  //  AppSettings.SW_REST_BASE_URL + "/swmedallia/";

  // //NPH Flag Service
  // public static nphUrl =
  //   AppSettings.SW_REST_BASE_URL + "/advisorprofile/registration/nph";

  // public static serviceReqBacklog =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetServiceRequestByAgent";
  // public static serviceReqHistory =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetServiceRequestByRepId";
  // public static serviceReqSearch =
  //   AppSettings._siebelsrServices +
  //   "ServiceRequest/SearchServiceRequestBySRNumbers";
  // public static serviceReqAdvanceSearch =
  //   AppSettings._siebelsrServices +
  //   "ServiceRequest/GetServiceRequestAdvanceSearch";
  // public static issueMapUrl =
  //   AppSettings._siebelsrServices + "IssueMap/GetTopIssues";
  public static siebelModalURL =
    AppSettings.siebelUrl +
    "?SWECmd=GotoView&SWEView=All+Service+Request+List+View&SWEApplet0=Service+Request+List+Applet&SWERowId0=";

  // public static taskOriginatorUrl =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetDefaultTaskOriginator";
  // public static reportCallDriverUrl =
  //   AppSettings._siebelsrServices +
  //   "Dashboard/GetSrCountandPercentageBySubAreaandDetail";
  // public static reportChartUrl =
  //   AppSettings._siebelsrServices + "Dashboard/GetSrCountandPercentageByDetail";
  // public static siebelSRUrl =
  //   AppSettings._siebelsrServices + "Dashboard/GetSrCountandPercentageByArea";
  // public static tshdSRUrl =
  //   AppSettings._siebelsrServices +
  //   "Dashboard/GetSrCountandPercentageByAreaForLastHour";

  public static userInfoUrl = AppSettings._agentAuthServices + "ADUserInfo/GetUserInfo";

  // public static advisorDetailsUrl =
  //   AppSettings._callerInfoServices + "AdvisorInfo/GetAdvisorDetailsNew";
  // public static clientDetailsUrl =
  //   AppSettings._callerInfoServices + "ClientInfo/GetClientDetails";
  public static clientAdvDetailsUrl =
    AppSettings._callerInfoServices + "ClientInfo/GetClientAdvisorDetail";
  // public static NLAInfoUrl =
  //   AppSettings._callerInfoServices + "NLALA/GetByRepId";

  // //public static advisorDetailsJson = AppSettings._callerInfoServices + 'AdvisorInfo/GetAdvisorDetails';
  // //public static clientDetailsJson = AppSettings._callerInfoServices + 'ClientInfo/GetClientDetails';

  // public static gridJson = "app/shared/data/grid.json";
  // public static tshdsrJson = "app/shared/data/tshd_sr.json";
  // public static callStatisticsJson =
  //   AppSettings._mulesoftcallmetrics + "CallMetrics/GetCallMetricsStatistics";
  // public static serviceManagerCallmetricsData = "app/shared/data/smcm.json";
  // public static tshdManagerAPI =
  //   AppSettings._mulesoftcallmetrics + "CallMetrics/GetCallMetricsMgrGrid";
  // public static realTimeCallMetricsData =
  //   AppSettings._mulesoftcallmetrics +
  //   "callmetrics/getrealtimecallmetricsstatistics";
  // public static activityData = "app/shared/data/activity.json";
  // public static advisorNotificationsAPI =
  //   AppSettings._alertServices + "Notifications/Advisor";
  // public static investorNotificationsAPI =
  //   AppSettings._alertServices + "Notifications/Investor";
  // public static ACTNotificationsAPI =
  //   AppSettings.SW_REST_BASE_URL +
  //   "/NotificationsApi/ACTNotifications/ApplyFilters";
  // public static ACTNotificationsColumnValAPI =
  //   AppSettings.SW_REST_BASE_URL +
  //   "/NotificationsApi/ACTNotifications/GetColumnValue";
  // public static documentsData = "app/shared/data/documents.json";
  // public static requestsData = "app/shared/data/requests.json";

  // //public static knowledgeManagementUrl = 'app/shared/data/KnowledgeManagementData.json'
  // public static knowledgeManagementUrl =
  //   AppSettings.SW_RC_BASE_URL + "/bin/populateContent";
  // public static knowledgeManagementIKnowUrl =
  //   AppSettings.SW_RC_BASE_URL + "/bin/populateContentKB";

  // //Alerts links
  // public static getUserAlerts =
  //   AppSettings._alertServices + "UserAlerts/GetUserAlerts";
  // public static saveUserAlerts =
  //   AppSettings._alertServices + "UserAlerts/SaveAlerts";
  // public static deleteAlert =
  //   AppSettings._alertServices + "UserAlerts/DeleteAlert";

  // public static getClientworksAlerts =
  //   AppSettings._alertServices + "CWAlerts/GetCWAlerts";
  // /* Demo Links End */

  // public static callerInfoUrl =
  //   AppSettings._callerInfoServices + "Callerinfo/GetCallerInfo";
  // public static callerTopIssues =
  //   AppSettings._siebelsrServices + "IssueMap/GetCallerTopIssues";
  // public static authenticateCaller =
  //   AppSettings._callerInfoServices + "CallerInfo/AuthenticateCaller";
  // public static savePreference =
  //   AppSettings._preferenceServices + "ManagePreference/SavePreference";
  // public static getPrefereneFromComponent =
  //   AppSettings._preferenceServices +
  //   "ManagePreference/GetByUserApplicationAndComponent";

  // public static callerSearchFieldUrl =
  //   AppSettings._callerInfoServices + "Callerinfo/GetCallerSearchFields";
  // public static searchContactsURL =
  //   AppSettings._preferenceServices + "ContactSearch/SearchEmployee";
  // public static callerSearchedDataUrl =
  //   AppSettings._callerInfoServices + "Callerinfo/SearchCaller";
  // public static callHistoryDataUrl =
  //   AppSettings._callerInfoServices + "Callerinfo/GetCallHistory";

  // //ASAT Api
  // public static securityServicesGetAccountDetails =
  //   AppSettings._securityServices + "Security/GetUserAccountStatus";
  // public static securityServicesResetPassword =
  //   AppSettings._securityServices + "Security/ResetPassword";
  // public static securityServicesgetCertificateInfo =
  //   AppSettings._securityServices + "Security/GetUserCertificateInfo";
  // public static securityServicesResetCertificatePasscode =
  //   AppSettings._securityServices + "Security/ResetCertificatePasscode";
  // public static securityServicesRevokeUserCertificate =
  //   AppSettings._securityServices + "Security/RevokeUserCertificate";
  // public static enrollClientWorks =
  //   AppSettings._securityServices + "/security/EnrollClientWorksUser";
  // public static issueOnetimePasscode =
  //   AppSettings._securityServices + "/security/IssueOneTimePassCode";

  // public static serviceRequestByTeamURL =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetServiceRequestByTeams";
  // public static agentFilterURL =
  //   AppSettings._siebelsrServices + "Manager/GetReportingStructure";
  // public static teamFilterURL =
  //   AppSettings._siebelsrServices + "Manager/GetService360Teams";

  // public static quickLinksURL =
  //   AppSettings._preferenceServices + "InternalApps/GetApps";

  // /*GET CALLER TAB LIST SETTINGS*/
  // public static RepListHierarchy =
  //   AppSettings.SW_REST_BASE_URL + "/swcallerinfo/RepListHierarchy/Get";
  // public static RepListHierarchyByUsername =
  //   AppSettings.SW_REST_BASE_URL +
  //   "/swcallerinfo/RepListHierarchy/GetByUsername";
  // //public static RepListHierarchyByUsername = 'https://I01NSVW-ASWBO01.sddev.lpl.com/swcallerinfo/RepListHierarchy/GetByUsername';

  // //Investor sub tab
  // // public static getInvestorAPI = AppSettings._callerInfoServices + '/CW/GetInvestors';
  // //public static getInvestorAPI = AppSettings.clientWorksServices + "account/search";

  // //Investor view notes
  // public static getNoteList =
  //   AppSettings._accountNotesServices + "AccountNotes/AccountNotes";
  // public static saveNote =
  //   AppSettings._accountNotesServices + "AccountNotes/AccountNotesSave";

  // //Investor account history
  // public static getAccountHist =
  //   AppSettings._accountHistServices + "AccountActivity/GetAccountActivity";

  // //Investor account info
  // public static getAccountInfo =
  //   AppSettings._accountInfoServices + "AccountInfo/GetAccountInfo";

  // //Update Reports
  // public static updateReportsAPI =
  //   AppSettings._preferenceServices + "Task/Log";

  // /*QUICK TICKET SERVICE API*/
  // public static srIdCreation =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetNewServiceRequestNumber";
  // public static quickTicketTaskOriginator =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetTaskOriginatorValues";
  // public static quickTicketSave =
  //   AppSettings._siebelsrServices + "ServiceRequest/SaveServiceRequest";
  // public static quickTicketRTTandSLA =
  //   AppSettings._siebelsrServices +
  //   "ServiceRequest/GetSLAWithPublishedFlag?taskOriginatorName={taskoriginatorName}&areaName={areaName}&subAreaName={subareaName}&detailName={detailName}";
  public static quickTicketArea =
    AppSettings._siebelsrServices +
    "ServiceRequest/GetServiceRequestAreaTypesByTaskOriginator?taskoriginatorName={taskoriginatorName}&taskoriginatorId={taskoriginatorId}";
  public static quickTicketSubArea =
    AppSettings._siebelsrServices +
    "ServiceRequest/GetServiceRequestSubAreaTypesByTaskOriginatorAndArea?taskoriginatorName={taskoriginatorName}&taskoriginatorId={taskoriginatorId}&areaId={areaId}&areaName={areaName}";
  public static quickTicketDetail =
    AppSettings._siebelsrServices +
    "ServiceRequest/GetServiceRequestDetailValuesByTaskOriginatorAndAreaAndSubArea?taskoriginatorName={taskoriginatorName}&taskoriginatorId={taskoriginatorId}&areaId={areaId}&areaName={areaName}&subAreaId={subareaId}&subAreaName={subareaName}";
  public static quickTicketErrorSource =
    AppSettings._siebelsrServices +
    "ServiceRequest/GetServiceRequestErrorSourceTypesByTaskOriginator?taskoriginatorId={taskoriginatorId}&taskoriginatorName={taskoriginatorName}";
  public static quickTicketReceivedVia =
    AppSettings._siebelsrServices +
    "ServiceRequest/GetRootCauseValuesByTaskOriginator?taskoriginatorId={taskoriginatorId}&taskoriginatorName={taskoriginatorName}";
  public static quickTicketRootCause =
    AppSettings._siebelsrServices +
    "ServiceRequest/GetRootCauseValuesByTaskOriginator?taskoriginatorId={taskoriginatorId}&taskoriginatorName={taskoriginatorName}";
  // public static quickTicketStatus =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetStatusValues";
  // public static quickTicketTier1Why =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetTierOneWhyValues";
  // public static quickTicketTier1Who =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetTierOneWhoValues";
  // public static quickTicketSubStatus =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetSubStatusValues";
  // public static quickTicketCallType =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetCallTypeValues";
  // public static quickTicketCombinedLOV =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetCombinedLOV";

  // public static saveUserActivityStream =
  //   AppSettings._preferenceServices + "Activity/Log";
  // public static getUserActivityStream =
  //   AppSettings._preferenceServices + "Activity/Get";
  // public static saveCallerAuthAuditTrail =
  //   AppSettings._preferenceServices + "Activity/AuthAudit";

  // //public static getAllAssociatedAccountDetails = AppSettings.clientWorksServices + 'account/search';
  // public static getClientAllDetails =
  //   AppSettings.CW_REST_SERVICE_URL + "client/details?clientID=";
  // public static nigoURL =
  //   AppSettings.CW_REST_SERVICE_URL + "lplrequest/getrequest";
  // public static nigoSummaryStatisticsURL =
  //   AppSettings.CW_REST_SERVICE_URL + "lplrequest/slasummary";

  // //get HomeOffice Contact List
  // public static homeOfficeContactsURL =
  //   AppSettings.salesforceUrl + "/homeoffice/registration/homeoffice";

  // public static accountDetailsCW =
  //   AppSettings.CW_REST_SERVICE_URL + "account/details";
  // public static accountSearchCW =
  //   AppSettings.CW_REST_SERVICE_URL + "account/search";
  // public static accountSearchSW =
  //   AppSettings._accountSearchServices + "accountsearch/searchaccounts";
  // public static investmentSearchCW =
  //   AppSettings.CW_REST_SERVICE_URL + "investments/search";

  public static requestCW = "/clientmanagement/requests";

  // public static orderQuickViewAPI =
  //   AppSettings.CW_REST_SERVICE_URL + "user/systemsavedviews";
  // public static orderStatusAPI = {
  //   "4F6B9589-F38E-4A5E-8189-07FB246CD75C":
  //     AppSettings.CW_REST_SERVICE_URL + "api/trading/uit/orderstatus",
  //   "88A955DE-3158-4F50-BCCD-976565319066":
  //     AppSettings.CW_REST_SERVICE_URL +
  //     "api/trading/systematics/instructions/searchbyusername",
  //   "FF33024B-6505-44FA-85F3-835859B8D150":
  //     AppSettings.CW_REST_SERVICE_URL + "api/tradeconfirm/gettradeconfirmdata",
  //   "BB07DFBB-D661-46B3-813D-C3ABC6D55867":
  //     AppSettings.CW_REST_SERVICE_URL + "api/trading/aioe/aiorderstatus",
  //   "C4DBBEEC-0B0F-43E8-90D2-46A51CA08A7E":
  //     AppSettings.CW_REST_SERVICE_URL + "api/trading/aioe/aiorderstatus",
  //   "C6B69953-6E57-44D2-BDE0-7FEBEC3B2906":
  //     AppSettings.CW_REST_SERVICE_URL + "api/trading/mutualfund/execution",
  //   "08E20294-B531-460B-9BE8-8015936470D8":
  //     AppSettings.CW_REST_SERVICE_URL + "api/trading/mutualfund/orderstatus",
  //   "87837EB4-C617-43F4-B765-CE26FD987BCA":
  //     AppSettings.CW_REST_SERVICE_URL + "api/trading/fixedincome/execution",
  //   "214396D8-C59A-4A45-91A0-CE2E4E9E8C39":
  //     AppSettings.CW_REST_SERVICE_URL + "api/trading/orderstatus/search",
  //   "1C86953C-7C5F-4F49-8B19-5B0130D2ED33":
  //     AppSettings.CW_REST_SERVICE_URL + "api/trading/orderexecution/search",
  //   "968D97A6-954F-475D-B955-352F2F335A68":
  //     AppSettings.CW_REST_SERVICE_URL + "api/tradeconfirm/multitradeconfirms"
  // };
  // public static requestTrackerToolCW =
  //   AppSettings.CW_REST_SERVICE_URL + "lplrequest/getrequest";
  // public static requestTrackerTool =
  //   AppSettings._callerInfoServices + "RTT/GetRequests";
  // public static newAccOpenAPI =
  //   AppSettings.CW_REST_SERVICE_URL + "account/search";
  // public static utpAdminAPI =
  //   AppSettings._callerInfoServices + "UsageLog/SearchUTP";

  // public static getDocuments = AppSettings._wrapperServices + "Document/Get";
  public static documentDownloadUrl = "/ClientManagementRest/api/documents/file";
  // public static docDownloadUrlWrapper =
  //   AppSettings._wrapperServices + "Document/GetDetail";
  // public static getChecks =
  //   AppSettings._wrapperServices + "Document/SearchChecks";
  // public static getBranchNetChecks =
  //   AppSettings._wrapperServices + "Document/SearchChecks";

  public static clientDetailsView = "/clientmanagement/clients/";
  public static accountDetailsViewByAccountNumber =
    "/clientmanagement/account/";
  public static accountDetailsView = "/clientmanagement/accounts/";
  public static knowledgeManagementResourceURL = "/cw/resourcecenter";
  public static accRequestListAppend = "/requests?isIntraday=true";

  public static cwLogoutUrl =
    AppSettings.CW_LOGIN_URL +
    "/idp/startSLO.ping?TargetResource=" +
    AppSettings.CW_LOGIN_URL +
    "/ext/logout&InErrorResource=" +
    AppSettings.CW_LOGIN_URL +
    "/ext/logout";
  //public static cwLogoutService = AppSettings._swcwServices + "logout/logout";

  // public static commissionstructureUrl =
  //   AppSettings._advisorCommission + "registration/advisorprofile/commission";
  // public static ChatApi = "https://10.29.137.88:443/LPLChatbot/api/QnA"; //"https://txxdigateprd80.corp.lpl.com/api/QnA";

  // public static registerLastCallTime =
  //   AppSettings._callerInfoServices + "CallerInfo/SaveCallHistory";

  // public static getSavedRepListId =
  //   AppSettings.CW_REST_SERVICE_URL +
  //   "rephierarchy/savedlist?default=true&appID=47AD45B0-6328-4D4B-B72B-871D7FDF57C9";

  // public static deleteSavedRepListId =
  //   AppSettings.CW_REST_SERVICE_URL + "rephierarchy/savedlist/";

  // public static saveRepListId =
  //   AppSettings.CW_REST_SERVICE_URL + "rephierarchy/savedlist";

  // public static TPDadminSettingsURL =
  //   AppSettings._preferenceServices +
  //   "TPDAdmin/GetByCategory?categoryName=ServiceWorks";

  // public static TPDadminSettingsURL_Dev =
  //   "core/data/app-launcher-tiles_DEV.json";
  // public static TPDadminSettingsURL_QA = "core/data/app-launcher-tiles_QA.json";
  // public static TPDadminSettingsURL_PROD =
  //   "core/data/app-launcher-tiles_PROD.json";

  // /* Advisor Alerts Service API */
  // public static GET_ADVISOR_ALERTS =
  //   AppSettings._siebelsrServices + "AdvisorAlerts/Get";
  // public static NEW_ADVISOR_ALERTS =
  //   AppSettings._siebelsrServices + "AdvisorAlerts/Create";
  // public static EDIT_ADVISOR_ALERTS =
  //   AppSettings._siebelsrServices + "AdvisorAlerts/update";
  // public static DELETE_ADVISOR_ALERTS =
  //   AppSettings._siebelsrServices + "AdvisorAlerts/Delete";

  // /* Notifiaction Window */
  public static notificationWindowURL = AppSettings.NOTIFICATION_WEBURL + "/NotificationCenter/default.html#/notificationdetails";

  // /*Document Note*/
  // public static documentInternalNotesURL =
  //   AppSettings._wrapperServices + "Document/GetNotes";

  // /*User preference URL*/
  // //public static userPrefActualURL = AppSettings._userPreferenceSettingsServices + "Configuration/GetConfigurations?firm=LPL&ApplicationId=34&CategoryId=0";
  // public static userPrefActualURL =
  //   AppSettings._swConfigRest +
  //   "Configuration/GetConfigurationsByName?firm=LPL&ApplicationName=ServiceWorks&CategoryName=&SettingName";
  // public static userPrefFalloutURL = "core/data/userPreferenceFallout.json";

  // public static topIssueChart =
  //   AppSettings._siebelsrServices + "Dashboard/GetTopCategoriesByRepId";

  // public static GET_SR_ACTIVITY_TYPE =
  //   AppSettings._siebelsrServices + "Activity/GetTypes";
  // public static GET_SR_ACTIVITY_STATUS =
  //   AppSettings._siebelsrServices + "Activity/GetStatus";
  // public static GET_SR_ACTIVITY_CATEGORY =
  //   AppSettings._siebelsrServices + "Activity/GetCategories";
  // public static GET_SR_ACTIVITY_PRIORITY =
  //   AppSettings._siebelsrServices + "Activity/GetPriority";
  // public static GET_SR_ACTIVITIES =
  //   AppSettings._siebelsrServices + "Activity/GetBySrNumber";
  // public static CREATE_SR_ACTIVITIES =
  //   AppSettings._siebelsrServices + "Activity/Create";
  // public static UPDATE_SR_ACTIVITIES =
  //   AppSettings._siebelsrServices + "Activity/Update";
  // public static DELETE_SR_ACTIVITIES =
  //   AppSettings._siebelsrServices + "Activity/Delete";

  // public static GET_SR_ATTACHMENTS =
  //   AppSettings._siebelsrServices + "Attachment/GetMetadata";
  // public static DOWNLOAD_SR_ATTACHMENTS =
  //   AppSettings._siebelsrServices + "Attachment/Get";
  // public static DELETE_SR_ATTACHMENTS =
  //   AppSettings._siebelsrServices + "Attachment/Delete";
  // public static UPLOAD_SR_ATTACHMENTS =
  //   AppSettings._siebelsrServices + "Attachment/Insert";
  // public static EDIT_COMMENTS_SR_ATTACHMENTS =
  //   AppSettings._siebelsrServices + "Attachment/UpdateComment";

  // // SR metric
  // public static serviceMetricJson = "core/data/service-metrics.json";
  // //public static ahaFeedBackJSON = 'core/data/aha-feedback.json';
  // public static ahaFeedBackJSON = AppSettings.SW_REST_BASE_URL + "/AHA/AHA";

  // // rep, share rep, primary api
  // public static repRegistrationAPI =
  //   AppSettings._callerInfoServices + "RepRegistrationHeirarchy/Get";
  // // split rep
  // public static sharedRepMembersAPI =
  //   AppSettings._callerInfoServices +
  //   "RepRegistrationHeirarchy/GetSharedRepMembersDetail";
  // // advisor info by account no.
  // public static getAdvisorbyAccountAPI =
  //   AppSettings._callerInfoServices + "ClientInfo/GetClientAdvisorDetail";

  // // Executive Appointment
  // public static getScheduledAppointment =
  //   AppSettings._swEmailRest + "Scheduling/GetScheduledAppointment";
  // public static scheduledAppointment =
  //   AppSettings._swEmailRest + "Scheduling/ScheduleAppointment";

  // // account holder grid by account number
  // public static getAccountHolderAPI =
  //   AppSettings._callerInfoServices + "ClientInfo/GetClientAccountHolderDetail";

  // // cancel document
  // public static cancelDocument =
  //   AppSettings._wrapperServices + "Document/CancelDocument";

  // //Route document
  public static DocumentumWindowURL = AppSettings.DOCUMENTUM_WEBURL + ":8080/XIP/#xip_workflow_document/contents%2Fxip_workflow_document%2F";
  public static DocumentumWindowPRODURL = AppSettings.DOCUMENTUM_WEBURL + "/XIP/#xip_workflow_document/contents%2Fxip_workflow_document%2F";
  public static DocumentumAdvanceSearchURL = AppSettings.DOCUMENTUM_WEBURL + ":8080/XIP/#xip_adv_search_doc_type/xip_edit_advance_search";
  public static DocumentumAdvanceSearchPRODURL = AppSettings.DOCUMENTUM_WEBURL + "/XIP/#xip_adv_search_doc_type/xip_edit_advance_search";

  // // Notification API
  // public static notificationAPI =
  //   AppSettings._notificationAPI + "ACTNotifications/ApplyFilters";

  // // service metrics API
  // public static serviceMetricsAPI =
  //   AppSettings.SW_REST_BASE_URL + "/swcallerinfo/RTT/GetMetricsRequest";

  //public static chatBotJS = "assets/js/vendor/chat.js";

  // public static AccountHolderURL =
  //   AppSettings._accountholderServices + "AccountHolder/GetAccountHoldersDAML";
  // public static UpdateAccountHolderURL =
  //   AppSettings._accountholderServices +
  //   "AccountHolder/UpdateAccountHoldersDAML";
  public static FireClientRepIdChangeURL =
    AppSettings._fireClientRepChangeServices + "1.1/AccountRep/ChangeAccountRep";

  // public static UpdateAccountRegistrationURL =
  //   AppSettings._accountholderServices +
  //   "AccountRegistration/UpdateAccountRegistrationInfo";

  // public static updateTicketURL =
  //   AppSettings._siebelsrServices + "ServiceRequest/UpdateServiceRequest";
  // //Fraud Report API
  // public static fraudReportAPI =
  //   AppSettings._siebelsrServices + "Dashboard/GetFraudAlertReport";

  // //Close Account API
  public static closeAccountAPI =
    AppSettings.closeAccountBaseURL +
    "/aw3/internal/process/AMA_AccountMaintenanceLauncher?primaryAccountId=";

  // // Edit Address Across Account API
  public static editAcctAddressAPI =
    AppSettings.closeAccountBaseURL +
    '/aw3/internal/process/AMA_AccountMaintenanceLauncher?primaryAccountId=';

  // // Edit financials and suitability API
  public static editFinancialAndSuitabilityAPI =
    AppSettings.closeAccountBaseURL +
    '/aw3/internal/process/AMA_AccountMaintenanceLauncher?primaryAccountId=';

  // // dynatrace API
  // public static DynatraceWidgetAPI =
  //   AppSettings.SW_REST_BASE_URL + "/swapiwrapper/Dynatrace/GetWidgetData";
  // public static DynatraceTopAppsAPI =
  //   AppSettings.SW_REST_BASE_URL + "/swapiwrapper/Dynatrace/GetAccessedApps";
  // public static DynatraceLoginAPI =
  //   AppSettings.SW_REST_BASE_URL + "/swapiwrapper/Dynatrace/GetUserLogins";
  // public static DynatraceSessionDurationAPI =
  //   AppSettings.SW_REST_BASE_URL +
  //   "/swapiwrapper/Dynatrace/GetSessionDurations";

  // public static AgentNameSearchAPI =
  //   AppSettings._siebelsrServices + "ServiceRequest/GetUsers";

  // // Auto Fraud Alert Mail API
  // public static sendFraudEmailAPI =
  //   AppSettings.DATA_POWER_URL + "/cleh/notification/sendemail";

  // // Investor Information - Account Holder - Interested Party API
  // public static getInterestedPartyAPI =
  //   AppSettings._accountholderServices + "AccountInterestedParty/GetAccountShipDetails";
  // public static updateInterestedPartyAPI =
  //   AppSettings._accountholderServices + "AccountInterestedParty/ChangeAccountSHIPDetails";

  // // investor benificiary
  // public static getBenificiaryAPI =
  //   AppSettings._accountholderServices + "AccountTODB/GetAccountAllBENDetails";
  // public static updateBenificiaryAPI =
  //   AppSettings._accountholderServices + "AccountTODB/UpdateAccountBeneficiary";
  // // public static updateBenificiaryAPI = AppSettings._accountholderServices + 'AccountTODB/UpdateAccountTODB';
  // // public static getBenificiaryAPI = AppSettings._accountholderServices + 'AccountTODB/GetAccountTODBDetails';

  // // cafex urls & meeting key
  public static meetingAPI =
    configEnvironment.meetingAPI_webURL + "api/meeting";
  public static meetingSdkURL = configEnvironment.meetingSDK_webURL;
  public static meetingCustomURL = "assets/js/custom/meeting.js";
  public static MeetingHeaderAuthorizationKey = configEnvironment.meeting_key;
  public static meetingTerminateAPI =
    configEnvironment.meetingAPI_webURL + "api/meeting";
  public static meetingReportsAPI =
    configEnvironment.meetingAPI_webURL + "reporting/sessions/";

  // // logged documents API
  // public static loggedDocumentsGetAPI =
  //   AppSettings.SW_REST_BASE_URL +
  //   "/swaccountholder/AccountDOCA/GetAccountDOCADetails";
  // public static loggedDocumentsActionAPI =
  //   AppSettings.SW_REST_BASE_URL +
  //   "/swaccountholder/AccountDOCA/UpdateAccountDOCADetails";

  // //BORD Restriction Get API
  // public static getBordRestrictionGroupsAPI =
  //   AppSettings.SW_REST_BASE_URL + "/swbord/Bord/GetBORDProcessRestrictions";

  // //CallReview urls
  // public static saveReviewURL =
  //   AppSettings._preferenceServices + "CallRating/Save";
  // // feedback reporting API
  // public static feedbackReportingAPI =
  //   AppSettings._preferenceServices + "CallRating/GetReviews";
  // // fedback reporting API
  // public static feedbackReportingCSVAPI =
  //   AppSettings._preferenceServices + "CallRating/GetReviewsForExcel";
  // public static BORD_Restriction_Lift_API = AppSettings._preferenceServices + "Activity/GetActivitiesByCategory";

  // // Account/SSN Compromised check API
  // public static compromisedCheckAPI =
  //   AppSettings.SW_REST_BASE_URL + "/ProjectWind/Account/CompromisedCheck";

  public static CIRA_URL =
    configEnvironment.cira_webURL + "/idp/startSSO.ping?PartnerSpId=cognicor";

  // //Medallia Related API
  // public static isUserAuthorized = AppSettings._securityServices + "security/IsUserAuthorized";
  // //http://swboappdvi.sddev.lpl.com/swcwsecurity/security/IsUserAuthorized?userName=vaishali.zachrich&appShortName=CORP2CWSSO
  public static medalliaAPI = AppSettings.medallia_URL + "/advisorfeedback";
  //public static medalliaAPI = "https://datapowerdev.sddev.lpl.com:8052/advisorfeedback";

  // get settings details
  public static getSettingsDetailsAPI =
    AppSettings._swapplicationversion + "VersionSetting/GetVersionSettings";

  // get active version
  public static getDefaultVersion =
    AppSettings._swapplicationversion + "Version/GetVersions";

  /* OBA CLRWorks SideMenu API */
  public static _getSideMenuList = AppSettings.SW_REST_BASE_URL +''+ '/CLRWorksRest/api/clrworks/config'; 
  
  /* OBA CLRWorks Signout API */
  public static signOutApi = AppSettings.SW_REST_BASE_URL +''+ '/swcwuser/logout/logout';

  public static adminToolApi = AppSettings.SW_REST_BASE_URL + '/api/branchexams/Question/';
  public static agentLocalStoargeName = "agentInformation";

  /* Non-AQ users - POC*/

   // App Access Token URL
   public static LPLAccessTokenInfo = AppSettings.HO_CORE_BASE_URL + '/esb/lpl/resource/getaccesstoken';
   // login url
   public static authenticateUser = AppSettings.HO_CORE_BASE_URL + '/infosec/owa/advisor/validate/credentials';
   // reset email url
   public static resetSubmitEmail = AppSettings.HO_CORE_BASE_URL + '/infosec/owa/advisor/password/reset/submit/username';
   // mfachoice url
   public static mfachoice = AppSettings.HO_CORE_BASE_URL + '/infosec/owa/advisor/submit/mfachoice';
   // validate choice
   public static validateChoice = AppSettings.HO_CORE_BASE_URL + '/infosec/owa/advisor/validate/otp';
   // push status
   public static pushStatus = AppSettings.HO_CORE_BASE_URL + '/infosec/owa/advisor/push/status';
   // Device Concent
   public static deviceConcent = AppSettings.HO_CORE_BASE_URL + '/infosec/owa/advisor/device/concent';
   // Change Password url
   public static changePassword = AppSettings.HO_CORE_BASE_URL + '/infosec/owa/advisor/password/reset/submit/newpassword';

   //Retrieve task details
   public static getTaskIdByMailId= AppSettings.HO_CORE_BASE_URL + '/mule-branch-exam-api-rtf/get-roles/fetch-taskid-by-emailid';
   public static getaccesstoken_password = configEnvironment.getaccesstoken_password;
   public static app_version = configEnvironment.app_version;
   public static oAuthUrl = AppSettings.HO_CORE_BASE_URL + '/oauth/mule-branch-exam-api-rtf'
  }