/**
 * Main entry-point service of the application
 * Handles priamry critical functions - AD auth, ForgeRock entitlement, Version settings retrieval
 * 
 * In order to not use Versioning system, turn "useVersioningSystem = false"
 * 
 */
import { TaskOriginator } from "../models/task-originator.model";
import { AgentModel } from "../models/agent.model";
import { Injectable, Inject } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable, Subject, BehaviorSubject, of, forkJoin } from "rxjs";

import { AppSettings } from "../configs/core.config";
import { SWCoreServices } from "../services/core.services";
import { EntitlementService } from "../services/entitlement.service";
import { AcitivityStreamLogService } from "../services/activity-stream-log.services";
import { AppConstant } from "../constant/core.constant";
import { WINDOW } from "../services/window-providers.services";
import { catchError, map, mergeMap, flatMap } from "rxjs/operators";
import { JqFunctions } from "../static-class/jqfunctions.static";
import { environment } from "../../environments/environment";
import * as moment from "moment";
import { AccessTokenBemsService } from "./access-token-bems.service";
import { AccessTokenInfo } from "../models/accessToken.model";


@Injectable({
  providedIn: "root"
})
export class ResolverService implements Resolve<any> {

  useVersioningSystem: boolean = true;
  public GetVersionSettingsMock = 'assets/json/GetVersionSettingMock.json';
  public URL;
  public accessToken;
  public getAccessCalled: boolean = false;

  constructor(
    private swCoreServices: SWCoreServices,
    private entitlementService: EntitlementService,
    private activityStreamLog: AcitivityStreamLogService,
    private router: Router,
    private accessTokenServicebems: AccessTokenBemsService,
    @Inject(WINDOW) private window: Window
  ) { }

  getAgentInformation() {
    //if (!this.URL.includes('userLogin/login')) {
    return this.swCoreServices
      .get(AppSettings.userInfoUrl)
      .pipe(
        map(res => res.data || {}),

        catchError(err => {
          // Handle specific error for this call
          return of(undefined);
        })
      )
      .toPromise();
    //}
  }

  findSettingsDetailsVersion() {
    if (this.bypassEntitlement(this.URL)) {
      return this.GetVersionSettingsMOCK(
        // this.window.location.pathname
        //   .replace(/[/]/g, "")
        //   .replace(AppConstant.REDIRECTOR_APP_NAME, "") // modify this name based on project
      ).pipe();
    }

    else if (this.window.location.pathname != "/") {
      // localStorage.setItem('selectedVersion', this.window.location.pathname.replace(/[/]/g, "").replace(AppConstant.REDIRECTOR_APP_NAME,''));
      return this.getSettingsDetails(
        this.window.location.pathname
          .replace(/[/]/g, "")
          .replace(AppConstant.REDIRECTOR_APP_NAME, "") // modify this name based on project
      ).pipe();
    }
    else {
      return this.getDefaultVersion().pipe(
        mergeMap((v: any) => {
          localStorage.setItem(
            AppConstant.APP_NAME_LOCAL_STORAGE_KEY,
            JSON.stringify({
              versionName: v.modules[0].versions[0].versionName,
              isDefault: true
            })
          );
          return this.getSettingsDetails(v.modules[0].versions[0].versionName);
        })
      );
    }
  }

  GetVersionSettingsMOCK() {
    const url = this.GetVersionSettingsMock;
    return this.swCoreServices.get(url).pipe(
      map(result => {
        if (
          result &&
          result.status.toLowerCase() === "success" &&
          result.data != null
        ) {
          return result.data;
        } else {
          throw new Error("Some Error Occured");
        }
      }),
      catchError(err => {
        // Handle specific error for this call
        return of(undefined);
      })
    );

  }

  getSettingsDetails(versionName) {
    const url = AppSettings.getSettingsDetailsAPI;
    const payload = {
      applicationName: AppConstant.VERSION_API_APP_NAME,
      moduleName: AppConstant.VERSION_API_MODULE_NAME,
      versionName: versionName
    };
    return this.swCoreServices.get(url, payload).pipe(
      map(result => {
        if (
          result &&
          result.status.toLowerCase() === "success" &&
          result.data != null
        ) {
          return result.data;
        } else {
          throw new Error("Some Error Occured");
        }
      }),
      catchError(err => {
        // Handle specific error for this call
        return of(undefined);
      })
    );
  }

  getDefaultVersion() {
    const url = AppSettings.getDefaultVersion;
    const payload = {
      applicationName: AppConstant.VERSION_API_APP_NAME,
      moduleName: AppConstant.VERSION_API_MODULE_NAME,
      getDefault: "True"
    };
    return this.swCoreServices.get(url, payload).pipe(
      map(result => {
        if (
          result &&
          result.status.toLowerCase() === "success" &&
          result.data != null
        ) {
          return result.data;
        } else {
          throw new Error("Some Error Occured");
        }
      }),
      catchError(err => {
        // Handle specific error for this call
        return of(undefined);
      })
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if (this.clearCookiesandImage()) {
    // if new build delete the localtosrage
    this.URL = state.url;
    if (!localStorage.getItem(AppConstant.agentLocalStoargeName) &&
      environment.production == true
    ) {
      if (this.URL.includes('userPage/UserTaskDashboard')) {
        setInterval(() => {
          if (localStorage.getItem(AppConstant.agentLocalStoargeName) && !localStorage.getItem('UserDashBoardLoadStatus')) {
            localStorage.setItem(
              'UserDashBoardLoadStatus', 'True'
            );
            location.href = "#/userPage/UserTaskDashboard";
            console.log("Timeout");
            clearInterval();
          }
        }, 1000);
      }
      // if (window.location.host.includes(AppSettings.domain_check)) {
      //   setInterval(() => {
      //     this.bypassEntitlement(state.url);
          
      //   }, 1000);
      // }
      this.goToErrorPage();
      return { appInformation: "error occured" };

    }


    let isDev: boolean = false;
    if (!environment.production) {
      isDev = true;
    }

    if (!isDev && this.useVersioningSystem) {
      // prod mode logic available in deployed environments
      return new Promise((resolve, reject) => {
        this.findSettingsDetailsVersion()
          .toPromise()
          .then(async result => {
            if (result == undefined) {
              //reject();
              if (this.URL.includes('userPage/UserTaskDashboard')) {
                setInterval(() => {
                  if (localStorage.getItem(AppConstant.agentLocalStoargeName) && !localStorage.getItem('UserDashBoardLoadStatus')) {
                    localStorage.setItem(
                      'UserDashBoardLoadStatus', 'True'
                    );
                    location.href = "#/userPage/UserTaskDashboard";
                    console.log("Timeout");
                    clearInterval();
                  }
                }, 1000);
              }
              this.goToErrorPage();
              reject();
              return { appInformation: "error occured" };
            }
            this.createAdminSettingsDataStructure(result);
            this.swCoreServices.setAppLoadingStatus({
              appLoaded: false,
              appLoadingText: "Checking permissions..."
            });
            this.getTaskOriginator();
            this.swCoreServices.agentInformation = JSON.parse(
              localStorage.getItem(AppConstant.agentLocalStoargeName)
            );
            this.activityStreamLog.setCallerAuthAuditTrailLog(
              "Agent Data - User Info",
              null,
              {},
              {},
              {},
              JSON.parse(
                localStorage.getItem(AppConstant.agentLocalStoargeName)
              )
            );
            this.swCoreServices.sendAgentInfo(
              this.swCoreServices.agentInformation
            );

            this.swCoreServices.defaultTaskoriginator = JSON.parse(
              localStorage.getItem(AppConstant.taskoriginatorLocalStorageName)
            );
            this.swCoreServices.setTaskOriginator(
              JSON.parse(
                localStorage.getItem(AppConstant.taskoriginatorLocalStorageName)
              )
            );
            if (!this.bypassEntitlement(state.url)) {
              this.swCoreServices.setAppLoadingStatus({
                appLoaded: false,
                appLoadingText: "Fetching entitlements..."
              });
              var v = await this.emitForgeRock();

              if (v) {
                resolve();
                this.swCoreServices.setAppLoadingStatus({
                  appLoaded: true,
                  appLoadingText: "Initializing application..."
                });
                return of(this.swCoreServices.agentInformation);
              } else {
                this.goToErrorPage();
                reject();
                return { appInformation: "error occured" };
              }
            }
            else {
              resolve();
              this.swCoreServices.setAppLoadingStatus({
                appLoaded: true,
                appLoadingText: "Initializing application..."
              });
              return of(this.swCoreServices.agentInformation);
            }
          });
      });
    } else {
      // local development mode logic only
      if (!this.bypassEntitlement(state.url)) {
        console.log("SIGNING IN TO SW");
        console.log("SIGNING IN TO FORGE-ROCK");
        this.swCoreServices.setAppLoadingStatus({
          appLoaded: false,
          appLoadingText: "Signing-in..."
        });
      }

      let requests = [];
      requests.push(this.getAgentInformation().then());
      console.log("resolver.service.ts - 297");
      requests.push(
        this.findSettingsDetailsVersion()
          .toPromise()
          .then()
      );
      return new Promise((resolve, reject) => {
        forkJoin(requests).subscribe(async (result: any) => {
          if (result[0] == undefined || result[1] == undefined) {
            //reject();
            if (this.URL.includes('userPage/UserTaskDashboard')) {
              setInterval(() => {
                if (localStorage.getItem(AppConstant.agentLocalStoargeName) && !localStorage.getItem('UserDashBoardLoadStatus')) {
                  clearInterval();
                  location.href = "#/userPage/UserTaskDashboard";
                  console.log("Timeout");
                }
              }, 1000);
            }
            this.goToErrorPage();
            reject();
            return { appInformation: "error occured" };
          }
          this.swCoreServices.setAppLoadingStatus({
            appLoaded: false,
            appLoadingText: "Checking permissions..."
          });
          console.log("GOT USER-INFO");
          this.createAdminSettingsDataStructure(result[1]);
          this.swCoreServices.agentInformation = result[0];
          this.activityStreamLog.setCallerAuthAuditTrailLog(
            "Agent Data - User Info",
            null,
            {},
            {},
            {},
            result[0]
          );
          this.swCoreServices.sendAgentInfo(result[0]);
          localStorage.setItem(
            AppConstant.agentLocalStoargeName,
            JSON.stringify(result[0])
          );
          this.getTaskOriginator();
          if (!this.bypassEntitlement(state.url)) {
            this.swCoreServices.setAppLoadingStatus({
              appLoaded: false,
              appLoadingText: "Fetching entitlements..."
            });
            var v = await this.emitForgeRock();
            if (v) {
              this.swCoreServices.setAppLoadingStatus({
                appLoaded: false,
                appLoadingText: "Initializing application..."
              });
              console.log("LOADING APP!!!");
              resolve();
              this.swCoreServices.setAppLoadingStatus({
                appLoaded: true,
                appLoadingText: "Initializing application..."
              });
              return { appInformation: result };
            } else {
              this.goToErrorPage(true);
              reject();
              return { appInformation: "unauthorized" };
            }
          }
          else {
            resolve();
            this.swCoreServices.setAppLoadingStatus({
              appLoaded: true,
              appLoadingText: "Initializing application..."
            });
            return { appInformation: result };
          }
        });
      });
    }
    // }
  }


  checkIEBrowser() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      return true;
    } // If another browser, return 0
    else {
      return false;
    }
  }

  checkForgeRockCookie() {
    return new Promise(resolve => {
      let interval: number = +this.swCoreServices.getAPPSettingsByKey(
        "FRCookieCheckInterval"
      ); // 200 ms
      let timeOut: number = +this.swCoreServices.getAPPSettingsByKey(
        "FRCookieCheckTimeout"
      ); // 15 s
      let counter: number = timeOut / interval;
      let i: number = 0;
      const f = () => {
        if (JqFunctions.getCookie("iPlanetDirectoryPro")) {
          console.log("GOT FORGE-ROCK COOKIE");
          resolve(true); // resolving only when forge rock api returns entitlements // TODO : add logic to handle failed login or api
        } else {
          if (i < counter) {
            setTimeout(f, interval);
          } else {
            console.log("COULD NOT GET FORGE-ROCK COOKIE");
            resolve(false);
            // forgerock cookie not found --- redirect to error in parent function
          }
        }
        i++;
      };
      setTimeout(f, interval);
    });
  }

  getTaskOriginator() {
    this.getTaskOrginatorData().subscribe(
      (response: TaskOriginator) => {
        if (response == null) {
          response = this.swCoreServices.createEmptyDefaultTaskoriginatorData();
        }
        this.swCoreServices.defaultTaskoriginator = response;
        this.swCoreServices.setTaskOriginator(response);
        localStorage.setItem(
          AppConstant.taskoriginatorLocalStorageName,
          JSON.stringify(response)
        );
      },
      error => {
        const response = this.swCoreServices.createEmptyDefaultTaskoriginatorData();
        this.swCoreServices.defaultTaskoriginator = response;
        this.swCoreServices.setTaskOriginator(response);
        localStorage.setItem(
          AppConstant.taskoriginatorLocalStorageName,
          JSON.stringify(response)
        );
      }
    );
  }

  getTaskOrginatorData() {
    return this.swCoreServices.httpCall(AppConstant.taskOriginator).pipe(
      map(res => {
        if (res == null) {
          return null;
        } else {
          return res.data as TaskOriginator;
        }
      })
    );
  }

  goToErrorPage(isUnauth = false) {
    // location.href = "#/error";
    this.swCoreServices.setAppLoadingStatus({
      appLoaded: true,
      appLoadingText: ""
    });
    this.router.navigate(["/error", isUnauth ? "unauth" : ""]);
    return false;
  }

  clearCookiesandImage() {
    if (
      localStorage.getItem("APPLICATION_VERSION") !=
      AppSettings.APPLICATION_VERSION
    ) {
      localStorage.clear();
      const cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
      localStorage.setItem(
        "APPLICATION_VERSION",
        AppSettings.APPLICATION_VERSION
      );
    }
    return true;
  }

  createAdminSettingsDataStructure(dataObject, callBack = function () { }) {
    let apiSetting: Object = {};
    let appSetting: Object = {};
    for (let outerKeys in dataObject.apiversionSettingModule) {
      if (dataObject.apiversionSettingModule.hasOwnProperty(outerKeys)) {
        let dataArray =
          dataObject.apiversionSettingModule[outerKeys].apiversionsettings;

        for (let innerKeys in dataArray) {
          apiSetting[dataArray[innerKeys].apiName] = dataArray[innerKeys];
        }
      }
    }

    for (let outerKeys in dataObject.appversionSettingModule) {
      if (dataObject.appversionSettingModule.hasOwnProperty(outerKeys)) {
        let dataArray =
          dataObject.appversionSettingModule[outerKeys].appversionsettings;

        for (let innerKeys in dataArray) {
          appSetting[dataArray[innerKeys].appSettingName] =
            dataArray[innerKeys];
        }
      }
    }
    let tempDataStructure: any = {
      API: apiSetting,
      APP: appSetting
    };
    this.swCoreServices.setAdminSettings(tempDataStructure);
    localStorage.setItem(
      AppConstant.preferenceApplicationConfig,
      JSON.stringify(tempDataStructure)
    );
  }

  emitForgeRock() {
    return this.swCoreServices
      .httpCall("ForgeRockEntitlements", {}, true, {
        // iPlanetDirectoryPro: JqFunctions.getCookie("iPlanetDirectoryPro"),
        "x-api-transaction-id": "SW",
        platform: "HO",
        "hoz-jwt": this.swCoreServices.agentInformation.JwtToken
      })
      .pipe(
        map(res => {
          if (res && res.data && res.data.assignments) {
            this.entitlementService.fullEntitlementData = res.data.assignments;
            this.entitlementService.setRoles(res.data.roles);
            console.log("GOT ENTITLEMENTS");
            return of(true);
          } else {
            return of(undefined);
          }
        }),
        catchError(err => {
          // Handle specific error for this call
          return of(undefined);
        })
      )
      .toPromise();
  }
  bypassEntitlement(path) {
    let bypassEntitlementFlag: boolean = false;
    if (path.toLowerCase().indexOf("userpage") > -1) {
      if(!this.getAccessCalled){ // in oredr to restrict the service call multiple times
        this.getAccessToken();
        this.getAccessCalled = true;
      }
      bypassEntitlementFlag = true;
    } else if (path.toLowerCase().indexOf("login") > -1) {
      bypassEntitlementFlag = true;
    }
    return bypassEntitlementFlag;
  }

  /* Non CW users method */

  public async getAccessToken() {
    await this.RestCallForAccessTokenBems('/oauth/mule-branch-exam-api-rtf').then((token) => {
      this.accessToken = token;
    });
  }
  public RestCallForAccessTokenBems(scope: any): Promise<any> {
    return new Promise((resolve, reject) => {
        this.accessTokenServicebems.getAccessTokenInformation(scope).subscribe((info: AccessTokenInfo) => {
            resolve(info.access_token);
        });
    });
}

}
