import * as moment from "moment";
import { Injectable, Inject } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import {
  Http,
  URLSearchParams,
  RequestOptions,
  Request,
  RequestMethod,
  Headers
} from "@angular/http";
import { HttpClient, HttpParams, HttpClientModule } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { Observable, Subject, BehaviorSubject, empty } from "rxjs";
import { JqFunctions } from "../static-class/jqfunctions.static";
import { AppSettings } from "../configs/core.config";
import { AppConstant } from "../constant/core.constant";
import { AgentModel } from "../models/agent.model";
import { TaskOriginator } from "../models/task-originator.model";
import { ApiSettings } from "../models/api-settings.model";

@Injectable({
  providedIn: "root"
})
export class SWCoreServices {
  cookie: string = "";
  callerId: string;
  repId: string = "";
  advisorInformation: object = {};
  agentInformation: AgentModel = this.createEmptyAgent();
  defaultTaskoriginator: TaskOriginator;
  agentTaskOriginator: object = {};
  advisorLoadedRepId: boolean = false;
  authFlag: boolean = false;
  errMsgMain: string = "";
  errMsgSub: string = "";
  accountNumberSearched: boolean = false;
  accountNumberSearchedInput: string;
  executivePersonaObject = {
    name: null,
    isExecutive: false,
    groupCount: 0
  };
  profileCwDeepLink = {
    request: "",
    documentDownload: "",
    clientDetails: "",
    accountDetails: "",
    knowledgeManagementResource: ""
  };

  private activityStreamData = new Subject<any>();
  private exportWijmo = new Subject<any>();
  private subject = new Subject<any>();
  private taskOriginator = new Subject<any>();
  private advanceSearch = new Subject<boolean>();
  private repIdSSN = new Subject<boolean>();
  private cwConnChanged = new Subject<any>();
  private accountSerahcedObj = new Subject<any>();
  applicationSettings = new BehaviorSubject<any>({});
  private investorAdvanceSearch = new BehaviorSubject<any>(false);
  private investorReturnCallPop = new Subject<boolean>();
  private showSpinner = new Subject<boolean>();
  private activeMenu = new BehaviorSubject<any>({});

  private appLoadingStatus = new BehaviorSubject<any>({ appLoaded: false, appLoadingText: '' });
  private agentInfo = new BehaviorSubject<any>({});
  public adminSettings = new BehaviorSubject<any>({});


  constructor(private https: HttpClient, private router: Router, private httpClient: HttpClientModule) { }


  /**Forge Rock */
  setAdminSettings(data): void {
    this.adminSettings.next(data);
  }
  getAdminSettings(): Observable<any> {
    return this.adminSettings.asObservable();
  }
  setAppLoadingStatus(data): void {
    this.appLoadingStatus.next(data);
  }

  getAppLoadingStatus(): Observable<any> {
    return this.appLoadingStatus.asObservable();
  }

  getAgentInfo(): Observable<any> {
    return this.agentInfo.asObservable();
  }

  sendAgentInfo(data) {
    this.agentInfo.next(data);
  }


  /*@@@@@@@@@@@@@@ Find admin settings by key for app / api @@@@@@@@@@@@@*/
  getAPISettingsByKey(key) {
    let adminSettings = this.adminSettings.getValue();
    if (adminSettings && adminSettings.API && adminSettings.API.hasOwnProperty(key)) {
      return this.getCustomAPIDataObject(true, adminSettings.API[key]);
    } else {
      let localStorageAdminSettings = JSON.parse(localStorage.getItem('applicationConfig'));
      if (localStorageAdminSettings.API.hasOwnProperty(key)) {
        return this.getCustomAPIDataObject(true, localStorageAdminSettings.API[key]);
      } else {
        console.warn('Missing key warning: "' + key + '" key is missing in App Exchange! Please add it in App Exchange to fetch necessary data!');
        return this.getCustomAPIDataObject(false, {});
      }
    }
  }

  getCustomAPIDataObject(isExists, adminSettings) {
    let apiSettings: ApiSettings = {
      isExists: isExists,
      host: adminSettings.hostName || "",
      endPoint: adminSettings.apiEndPoint || "",
      method: adminSettings.apiMethod || "",
      cId: adminSettings.apiKey || "",
      cKey: adminSettings.apiSecret || "",
      version: adminSettings.minorVersion || "",
      isAccelerate: adminSettings.accelarateFlag || false,
      isLog: adminSettings.logFlag || false,
      isMule: isExists
        ? adminSettings.apiKey && adminSettings.apiSecret
          ? true
          : false
        : false
    };
    return apiSettings;
  }

  getAPIUrlByKey(key): string {
    if (this.getAPISettingsByKey(key).isExists) {
      let keyInfo: ApiSettings = this.getAPISettingsByKey(key);
      let url = keyInfo.host + keyInfo.endPoint;
      return url;
    } else {
      return '';
    }
  }

  getAPPSettingsByKey(key) {
    let adminSettings = this.adminSettings.getValue();
    let settingsValue: any;
    try {
      if (adminSettings.APP[key].appSettingType == "boolean") {
        settingsValue =
          adminSettings.APP[key].appSettingValue == "true" ||
            adminSettings.APP[key].appSettingValue == true
            ? true
            : false;
      } else if (adminSettings.APP[key].appSettingType == "json") {
        settingsValue = JSON.parse(adminSettings.APP[key].appSettingValue);
      } else {
        settingsValue = adminSettings.APP[key].appSettingValue;
      }
    } catch (e) {
      settingsValue = "";
      console.warn('Missing key warning: "' + key + '" key is missing in App Exchange! Please add it in App Exchange to fetch necessary data!');
    }
    return settingsValue;
  }
  /*@@@@@@@@@@@@@@ End fetch admin settings by key for app / api @@@@@@@@@@@@@*/

  /*@@@@@@@@@@@@@@ Call all API with admin key @@@@@@@@@@@@@*/

  httpCall(
    key,
    formData?: any,
    withCredentials = true,
    additionalHeaders = {},
    isJsonOutPut = true
  ) {
    if (this.getAPISettingsByKey(key).isExists) {
      let keyInfo: ApiSettings = this.getAPISettingsByKey(key);
      let headers = this.extendHeader(
        keyInfo.isMule
          ? {
            client_id: keyInfo.cId,
            client_secret: keyInfo.cKey,
            "hoz-jwt": this.agentInformation.JwtToken,
            api_version: keyInfo.version ? keyInfo.version : "",
            x_sessionId: this.agentInformation.SessionID,
            x_isaccelarate: keyInfo.isAccelerate,
            x_loginfo: keyInfo.isLog
          }
          : {
            // "hoz-jwt": this.agentInformation.JwtToken,
            // SessionID: this.agentInformation.SessionID,
            x_isaccelarate: keyInfo.isAccelerate,
            x_loginfo: keyInfo.isLog
          },
        additionalHeaders
      );
      let url = this.generateApiUrl(keyInfo);
      if (keyInfo.method == "get") {

        return this.get(url, formData, headers, !isJsonOutPut);
      } else if (keyInfo.method == "post") {
        return this.post(url, formData, withCredentials, headers, !isJsonOutPut);
      } else if (keyInfo.method == "put") { // TODO : revisit logic to pass header
        return this.put(url, formData, withCredentials);
      } else if (keyInfo.method == "delete") { // TODO : revisit logic to pass header
        return this.delete(url, formData);
      }
    } else {
      return empty().pipe(map(() => null));
    }
  }

  generateApiUrl(keyInfo: any) {
    return (
      (keyInfo.host.lastIndexOf("/") != keyInfo.host.length - 1
        ? keyInfo.host + "/"
        : keyInfo.host) + keyInfo.endPoint
    );
  }



  /** Forge Rock end */

  setSpinnerStatus(show: boolean) {
    this.showSpinner.next(show);
  }

  getSpinnerStatus(): Observable<boolean> {
    return this.showSpinner;
  }

  setInvestorReturnCallPopSearch(data: any): void {
    this.investorReturnCallPop.next(data);
  }

  getInvestorReturnCallPopSearch(): Observable<any> {
    return this.investorReturnCallPop.asObservable();
  }

  getInvestorAdvanceSearch(): Observable<any> {
    return this.investorAdvanceSearch.asObservable();
  }

  setInvestorAdvanceSearch(data: any): void {
    this.investorAdvanceSearch.next(data);
  }

  getWijmoExportEvent(): Observable<any> {
    return this.exportWijmo.asObservable();
  }
  setWijmoExportEvent(data): void {
    this.exportWijmo.next(data);
  }

  getAccountSearchedInformation(): Observable<any> {
    return this.accountSerahcedObj.asObservable();
  }
  setAccountSearchedInformation(data): void {
    this.accountSerahcedObj.next(data);
  }

  getActivityStream(): Observable<any> {
    return this.activityStreamData.asObservable();
  }

  setActivityStream(data): void {
    this.activityStreamData.next(data);
  }

  getAdvanceSearch(): Observable<any> {
    return this.advanceSearch.asObservable();
  }

  setAdvanceSearch(data: any): void {
    this.advanceSearch.next(data);
  }

  getTaskOriginator(): Observable<TaskOriginator> {
    return this.taskOriginator.asObservable();
  }

  setTaskOriginator(data: TaskOriginator): void {
    this.taskOriginator.next(data);
  }

  getLogged(): Observable<any> {
    return this.subject.asObservable();
  }

  setLogged(message: string): void {
    this.subject.next({ text: message });
  }

  setRepSSN(data: any): void {
    this.repIdSSN.next(data);
  }

  getRepSSN(): Observable<any> {
    return this.repIdSSN.asObservable();
  }
  setCwConnChanged(data: any): void {
    this.cwConnChanged.next(data);
  }

  getCwConnChanged(): Observable<any> {
    return this.cwConnChanged.asObservable();
  }

  setapplicationSettings(data): void {
    this.applicationSettings.next(data);
  }

  getapplicationSettings(): Observable<any> {
    return this.applicationSettings.asObservable();
  }

  createEmptyDefaultTaskoriginatorData() {
    return {
      Id: "",
      Value: "",
      IsSiebelUser: true
    };
  }
  createEmptyAgent() {
    return {
      Domain: "",
      UserName: "",
      EmailAddress: "",
      FirstName: "",
      LastName: "",
      DisplayName: "",
      AccountEnabled: false,
      AccountExpirationDate: "",
      AccountLockoutTime: null,
      Groups: [],
      ClientWorksUserName: "",
      JwtToken: "",
      SessionID: "",
      AssignedRoles: {
        IsServiceProfessional: false,
        IsServiceManager: false,
        IsTSHDServiceProfessional: false,
        IsTSHDServiceManager: false
      }
    };
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        this.cookie = c.substring(name.length, c.length);
      }
    }
  }

  get(
    url: string,
    formData?: any,
    additionalHeaders = {},
    jsonOutput = false
  ): Observable<any> {
    let params = new HttpParams();
    try {
      Object.keys(formData).forEach(function (key) {
        params = params.append(key, formData[key]);
      });
    } catch (e) { }
    // console.log(params);
    let tempHeaders = {
      "Content-type": "application/json"
    };
    tempHeaders = this.extendHeader(tempHeaders, additionalHeaders);
    let headers = new HttpHeaders(tempHeaders);
    let options = {
      params: params,
      withCredentials: true,
      headers: headers
    };
    if (!jsonOutput) {
      return this.https.get(url, options).pipe(map(response => response));
    } else {
      return this.https
        .get(url, options)
        .pipe(map(response => JSON.stringify(response)));
    }
  }

  // post(url: string, formData?: any, withCredentials = true): Observable<any> {
  //   let headers = new HttpHeaders({ "Content-type": "application/json" });
  //   let options = {
  //     headers: headers,
  //     withCredentials: withCredentials
  //   };
  //   let body = JSON.stringify(formData);
  //   return this.http.post(url, body, options).pipe(map(response => response));
  // }


  post(
    url: string,
    formData?: any,
    withCredentials = true,
    additionalHeaders = {},
    jsonOutput = false
  ): Observable<any> {
    let tempHeaders = {
      "Content-type": "application/json"
    };
    tempHeaders = this.extendHeader(tempHeaders, additionalHeaders);
    let headers = new HttpHeaders(tempHeaders);
    let options = {
      headers: headers,
      withCredentials: withCredentials
    };
    let body = JSON.stringify(formData);
    if (!jsonOutput) {
      return this.https
        .post(url, body, options)
        .pipe(map(response => response));
    } else {
      return this.https.post(url, body, options).pipe(map(response => response));
    }
  }

  // put(url: string, formData?: any): Observable<any> {
  //   let headers = new HttpHeaders({
  //     "Content-Type": "application/json"
  //   });
  //   let options = { headers: headers, withCredentials: true };
  //   let body = JSON.stringify(formData);

  //   return this.http.post(url, body, options).pipe(map(response => response));
  // }

  put(url: string, formData?: any, withCredentials = true): Observable<any> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let options = {
      headers: headers,
      withCredentials: withCredentials
    };
    let body = JSON.stringify(formData);
    return this.https
      .put(url, body, options)
      .pipe(map(response => response));
  }

  delete(url: string, formData?: any): Observable<any> {
    let params = new HttpParams();
    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    // Object.keys(formData).forEach(function (key) {
    //   params = params.append(key, formData[key]);
    // });
    let options = { headers: headers, params: params };
    return this.https.delete(url, options).pipe(map(response => response));
  }
  

  extendHeader(a, b) {
    for (var key in b) if (b.hasOwnProperty(key)) a[key] = b[key];
    return a;
  }
  //############################################### CW link url
  createCWDeepLinkURL(firmType = AppConstant.cwLPLFirm) {
    //var url;
    //if (firmType == AppConstant.cwAXAFirm) {
    //  url = AppSettings.CW_WEB_AXA_URL;
    //} else {
    //  url = AppSettings.CW_WEB_URL;
    //}
    //this.profileCwDeepLink = {
    //  request: url + AppSettings.requestCW,
    //  documentDownload: url + AppSettings.documentDownloadUrl,
    //  clientDetails: url + AppSettings.clientDetailsView,
    //  accountDetails: url + AppSettings.accountDetailsView,
    //  knowledgeManagementResource:
    //    url + AppSettings.knowledgeManagementResourceURL
    //};
  }
  //################################ just logout from CW
  // addCWlogoutIframe() {
  //   return this.get(AppSettings.cwLogoutService, {}, {}, true)
  //     .pipe(res => res)
  //     .toPromise();
  // }
  //################################ login to CW with Firm ID
  // addCWFirmloginIframe(
  //   firm = AppConstant.cwLPLFirm,
  //   saveCookie = false,
  //   callBack?
  // ) {
  //   /// if you send blank firm then it will not login, directly logout and done
  //   const self = this;
  //   this.addCWlogoutIframe().then(response => {
  //     if (firm != "") {
  //       JqFunctions.CWFirmloginIframe(self.createCWIframeURL(firm), function (
  //         responsefromlogin
  //       ) {
  //         if (responsefromlogin && saveCookie) {
  //           localStorage.setItem(AppConstant.localStorageFirmName, firm);
  //           localStorage.setItem(
  //             AppConstant.localStorageDateName,
  //             moment.parseZone(Date()).format("MM/DD/YYYY HH:mm:ss")
  //           );
  //           localStorage.setItem(
  //             AppConstant.localStorageAuthName,
  //             JqFunctions.getCookie(AppConstant.cwAuthCookieName)
  //           );
  //         }
  //         callBack(responsefromlogin);
  //       });
  //     }
  //   });
  // }
  //################################ login to CW - LPL
  addCWLPLloginIframe(saveCookie = false) {
    // store in cookie
    //this.addCWFirmloginIframe(AppConstant.cwLPLFirm, saveCookie);
  }
  //################################ login to CW - AXA
  addCWAXAloginIframe(saveCookie = false) {
    // this.addCWFirmloginIframe(AppConstant.cwAXAFirm, saveCookie);
  }

  createCWIframe(url) {
    //JqFunctions.addcwIframe(url);
  }
  createCWIframeURL(firmType = AppConstant.cwLPLFirm) {
    if (firmType == AppConstant.cwAXAFirm) {
      return (
        AppSettings.CW_WEB_AXA_URL +
        "/cdn/lpl/img/clientworks/cw-logo.svg?adAuth=true"
      );
    } else {
      return (
        AppSettings.CW_WEB_URL +
        "/cdn/lpl/img/clientworks/cw-logo.svg?adAuth=true"
      );
    }
  }
  callerPageCWAuthenticate(firmId, callBack) {
    // this.get(AppSettings.cwLogoutService)   //// logout from CW & login again
    //     .pipe(res => res).subscribe(res => {
    //         let cwLogin = this.createCWIframeURL(firmId);
    //         let iframe = JqFunctions.addcwIframe(cwLogin);
    //         let counter = 0;
    //         iframe.onload = function () {
    //             counter++;

    //             if (!(typeof JqFunctions.getCookie('Auth') === 'undefined')) {
    //                 localStorage.setItem(AppConstant.localStorageFirmName, firmId);
    //                 localStorage.setItem(AppConstant.localStorageDateName, moment.parseZone(Date()).format('MM/DD/YYYY hh:mm:ss'));
    //                 localStorage.setItem(AppConstant.localStorageAuthName, JqFunctions.getCookie("Auth"));
    //             }
    //         };
    //     });
    let loadLoginIframe: boolean = true;
    firmId = firmId.toUpperCase();
    // First check firm is same as previous
    if (firmId == localStorage.getItem(AppConstant.localStorageFirmName)) {
      //check if firm is same with last person
      if (
        JqFunctions.getCookie(AppConstant.cwAuthCookieName) ==
        localStorage.getItem(AppConstant.localStorageAuthName)
      ) {
        //check uf auth is same or not
        const now = moment(new Date()); //current date
        const end = moment(
          new Date(localStorage.getItem(AppConstant.localStorageDateName))
        ); // last saved date
        const duration = moment.duration(now.diff(end));
        const hours = duration.asHours();
        if (hours < AppConstant.authStoreTimeDiff) {
          ////auth is expired ?
          loadLoginIframe = false;
        }
      }
    }
    if (loadLoginIframe) {
      // this.addCWFirmloginIframe(firmId, true, function (response) {
      //   callBack(true);
      // });
    } else {
      callBack(true);
    }
  }
  getCW(
    url: string,
    formData?: any,
    mandatoryHeader = true,
    additionalHeaders = {},
    withCredentials = true
  ): Observable<any> {
    let params: HttpParams = new HttpParams();
    let headers;
    let tempHeaders;
    let self = this;
    let options;
    if (mandatoryHeader) {
      tempHeaders = {
        "Content-Type": "application/json",
        timestamp: new Date().toISOString(),
        "X-XSRF-TOKEN": self.getCookie("XSRF-TOKEN")
      };
    } else {
      tempHeaders = {
        "Content-type": "application/json"
      };
    }

    tempHeaders = this.extendHeader(tempHeaders, additionalHeaders);
    headers = new HttpHeaders(tempHeaders);
    try {
      Object.keys(formData).forEach(function (key) {
        params = params.append(key, formData[key]);
      });
    } catch (e) { }

    if (withCredentials) {
      options = {
        search: params,
        headers: headers,
        withCredentials: true
      };
    } else {
      options = new RequestOptions({ search: params, headers: headers });
    }

    return this.https.get(url, options).pipe(map(response => response));
  }
  deleteCW(
    url: string,
    mandatoryHeader = true,
    additionalHeaders = {},
    withCredentials = true
  ): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
    let headers;
    let tempHeaders;
    let self = this;
    let options;
    if (mandatoryHeader) {
      tempHeaders = {
        "Content-Type": "application/json",
        timestamp: new Date().toISOString(),
        "X-XSRF-TOKEN": self.getCookie("XSRF-TOKEN")
      };
    } else {
      tempHeaders = {
        "Content-type": "application/json"
      };
    }

    tempHeaders = this.extendHeader(tempHeaders, additionalHeaders);
    headers = new HttpHeaders(tempHeaders);
    if (withCredentials) {
      options = { headers: headers, withCredentials: true };
    } else {
      options = { headers: headers };
    }

    return this.https.delete(url, options).pipe(map(response => response));
  }

  postCW(
    url: string,
    formData?: any,
    mandatoryHeader = true,
    additionalHeaders = {},
    withCredentials = true
  ): Observable<any> {
    let headers;
    let tempHeaders;
    let self = this;
    let options;
    if (mandatoryHeader) {
      tempHeaders = {
        "Content-type": "application/json",
        "X-XSRF-TOKEN": self.getCookie("XSRF-TOKEN"),
        timestamp: new Date().toISOString()
      };
    } else {
      tempHeaders = {
        "Content-type": "application/json"
      };
    }

    tempHeaders = this.extendHeader(tempHeaders, additionalHeaders);
    headers = new HttpHeaders(tempHeaders);
    if (withCredentials) {
      options = { headers: headers, withCredentials: true };
    } else {
      options = { headers: headers };
    }
    let body = JSON.stringify(formData);
    return this.https.post(url, body, options).pipe(map(response => response));
  }

  getApplicationConfigValuebyKey(key, fallBackValue?) {
    const keyValues = this.applicationSettings.getValue();
    let isbreak = 0;
    let rValue: any = "";
    for (let outerKeys in keyValues) {
      if (keyValues.hasOwnProperty(outerKeys)) {
        let dataArray = keyValues[outerKeys].Settings;

        for (let keys in dataArray) {
          if (dataArray[keys].SettingName == key) {
            isbreak = 1;
            if (
              dataArray[keys].SettingType == "boolean" ||
              dataArray[keys].SettingType == "Boolean"
            ) {
              rValue =
                dataArray[keys].SettingValue == "true" ||
                  dataArray[keys].SettingValue == true
                  ? true
                  : false;
            } else {
              rValue = dataArray[keys].SettingValue;
            }
            break;
          }
        }
      }
      if (isbreak) {
        break;
      }
    }
    if (isbreak == 0) {
      return fallBackValue;
    } else {
      return rValue;
    }
  }

  getUserPersonaFromGroups(groupName = "", ignorePage = false) {
    if (
      this.router.url.indexOf("executive") == -1 &&
      this.router.url.indexOf("advisor") > -1 &&
      !ignorePage
    ) {
      this.executivePersonaObject = {
        name: "",
        isExecutive: false,
        groupCount: 1
      };
      return this.executivePersonaObject;
    } else {
      let envArray = [];
      let isExecutiveExists = false;
      let myGroups;
      if (groupName != "") {
        myGroups = groupName
          .toLowerCase()
          .replace("sw_", "")
          .replace("_qa", "")
          .replace("_dev", "");
        this.executivePersonaObject = {
          name: myGroups,
          isExecutive: myGroups == "executive" ? true : false,
          groupCount: 1
        };
      } else {
        envArray = this.agentInformation.Groups.filter(groups => {
          if (groups) {
            if (groups.split("_")[0] == "SW") {
              const groupEnvironmnet = groups.substring(
                groups.lastIndexOf("_") + 1,
                groups.length
              );
              return (
                AppSettings.SW_ENVIRONMENT.toLowerCase().indexOf(
                  groupEnvironmnet.toLowerCase()
                ) > -1 ||
                (AppSettings.SW_ENVIRONMENT.toLowerCase() === "prod" &&
                  groups.split("_").length == 2)
              );
            }
          }
        });
        for (let j = 0; j < envArray.length; j++) {
          if (envArray[j].toLowerCase().indexOf("executive") > -1) {
            myGroups = envArray[j]
              .toLowerCase()
              .replace("sw_", "")
              .replace("_qa", "")
              .replace("_dev", "");
            isExecutiveExists = true;
            break;
          }
        }

        if (
          (this.router.url.indexOf("dashboard") > -1 ||
            this.router.url.indexOf("reporting") > -1) &&
          envArray.length >= 2 &&
          !ignorePage
        ) {
          this.executivePersonaObject = {
            name: "",
            isExecutive: false,
            groupCount: envArray.length
          };
        } else {
          this.executivePersonaObject = {
            name: myGroups,
            isExecutive: myGroups == "executive" ? true : false,
            groupCount: envArray.length
          };
        }
      }
      return this.executivePersonaObject;
    }
  }
  getCoBrowseExists() {
    let envArray = [];
    let isCobrowse = false;
    //let myGroups;
    envArray = this.agentInformation.Groups;
    //envArray = this.agentInformation.Groups.filter((groups) => {
    //    if (groups) {
    //        if (groups.split("_")[0] == "SW") {
    //            const groupEnvironmnet = groups.substring((groups.lastIndexOf('_') + 1), groups.length);
    //            return (AppSettings.SW_ENVIRONMENT.toLowerCase().indexOf(groupEnvironmnet.toLowerCase()) > -1 || (AppSettings.SW_ENVIRONMENT.toLowerCase() === 'prod' && (groups.split("_").length == 2)));
    //        }
    //    }
    //});
    for (let j = 0; j < envArray.length; j++) {
      if (envArray[j].toLowerCase().indexOf("cobrowse") > -1) {
        //myGroups = envArray[j].toLowerCase().replace('sw_', '').replace('_qa', '').replace('_dev', '');
        isCobrowse = true;
        break;
      }
    }
    return isCobrowse;
  }

  getEnvironmentPersona(groupToFilter = []) {
    let envArray = [];
    envArray = groupToFilter.filter((groups) => {
      if (groups) {
        if (groups.split("_")[0] == "SW") {
          if (AppConstant.PERSONA_ENVIRONMENT_EXCEPTION.indexOf(groups.toLowerCase()) > -1) {
            return true;
          } else {
            const groupEnvironmnet = groups.substring((groups.lastIndexOf('_') + 1), groups.length);
            return (AppSettings.SW_ENVIRONMENT.toLowerCase().indexOf(groupEnvironmnet.toLowerCase()) > -1 || (AppSettings.SW_ENVIRONMENT.toLowerCase() === 'prod' && (groups.split("_").length == 2)));
          }
        }
      }
    });
    return envArray;
  }


  getScreenShareExists() {
    let envArray = [];
    let isCobrowse = false;
    //let myGroups;
    envArray = this.agentInformation.Groups;
    for (let j = 0; j < envArray.length; j++) {
      if (envArray[j].toLowerCase().indexOf("screenshare") > -1) {
        isCobrowse = true;
        break;
      }
    }
    return isCobrowse;
  }

  getLoggedInUserInfo() {
    if (localStorage.getItem(AppConstant.agentLocalStoargeName) !== null) {
      this.agentInformation = JSON.parse(localStorage.getItem(AppConstant.agentLocalStoargeName));
    }

    return this.agentInformation;
  }

  getAdvisorData(url: string, additionalHeaders?: any): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
    let headers;
    let options;
    let tempHeaders = {
      'Content-type': 'application/json'
    };
    tempHeaders = this.extendHeader(tempHeaders, additionalHeaders);
    headers = new HttpHeaders(tempHeaders);

    options = new RequestOptions({ search: params, headers: headers });
    return this.https
      .get(url, options)

  }

  getHttp(url: string, additionalHeaders?: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'MessagingAccess': 'true'
    });
    return this.https.get(url, { headers: httpHeaders });
  }


  postAdvisorData(url: string, additionalHeaders?: any, formData?: any): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
    let headers;
    let options;
    let tempHeaders: any = {
      'Content-type': 'application/json',
      'MessagingAccess': true,
    };
    tempHeaders = this.extendHeader(tempHeaders, additionalHeaders);
    headers = new HttpHeaders(tempHeaders);

    for (var i in formData) {
      params.set(i, formData[i]);
    };

    options = new RequestOptions({ search: params, headers: headers });

    return this.https
      .post(url, options)
      .pipe(map(response => response));

  }

  postData(url: string, formData?: any, headers?: any): Observable<any> {

    return this.https
      .post(url, formData, headers)
      .pipe(map(response => response));
    //.map(response => response);
  }

  getData(url: string, headers?: any): Observable<any> {

    return this.https
      .get(url, headers)
      .pipe(map(response => response));
    //.map(response => response);
  }

  
  // getApiData(apipath: string, headers?: any): Observable<any> {
  //   //this.agentInformation = JSON.parse(localStorage.getItem(AppConstant.agentLocalStoargeName)) 
  //   const httpHeaders = {
  //     'hoz-jwt': this.agentInformation.JwtToken
  //   };
  // // console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@:::',  httpHeaders );
  //   //var header= httpHeaders;
  //   let commHeaders = new HttpHeaders(httpHeaders);
  //   let options = {
  //     headers: commHeaders
  //   }; 
  //   // if (apipath.match(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/))
  //   // {
  //   return this.https.get(apipath, options).pipe(map(response => response));
  //   // }
  //   //.map(response => response);
  // }

  getCookieImage(imageUrl: string) {
    let promise = new Promise((resolve, reject) => {

      var hozCookieImage = new Image();
      hozCookieImage.onload = function () {
        resolve();
      };
      hozCookieImage.onerror = function () {
        reject();
      };
      hozCookieImage.src = imageUrl;
    });
    return promise;
  };

  private resentURLSource = new BehaviorSubject('dashboard');
  currentURL = this.resentURLSource.asObservable();

  changeRouter(resentURL: string) {
    this.resentURLSource.next(resentURL)
  }

  getSideMenuData(
    url: string,
    formData?: any,
    additionalHeaders = {},
    jsonOutput = false
  ): Observable<any> {
    let params = new HttpParams();
    try {
      Object.keys(formData).forEach(function (key) {
        params = params.append(key, formData[key]);
      });
    } catch (e) { }
    // console.log(params);
    let tempHeaders = {
      "Content-type": "application/json"
    };
    tempHeaders = this.extendHeader(tempHeaders, additionalHeaders);
    let headers = new HttpHeaders(tempHeaders);
    let options = {
      params: params,
      //withCredentials: true,
      headers: headers
    };
    console.log('menu', options);
    if (!jsonOutput) {
      return this.https.get(url, options).pipe(map(response => response));
    } else {
      return this.https
        .get(url, options)
        .pipe(map(response => JSON.stringify(response)));
    }

  }

  setActiveMenu(data): void {
    console.log('setActiveMenu');
    this.activeMenu.next(data);
  }

  getActiveMenu(): Observable<any> {
    console.log('getActiveMenu');
    return this.activeMenu.asObservable();
  }
  downloadPdf(id: any) : Observable<any> {
    
    const httpHeaders = {      
      
      'Content-Type':  'application/json',
      'hoz-jwt' : this.agentInformation.JwtToken
    };   

    return this.https
      .get(AppSettings.getPDFUrl + "?taskid=" + id, { headers: httpHeaders})
      .pipe(map(response => response));
    
  }



}
