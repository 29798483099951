import { Injectable, OnInit, Input, ElementRef } from '@angular/core';
import { Http, RequestOptions, RequestMethod } from '@angular/http';
import { Observable, of } from 'rxjs';
import { Subject } from 'rxjs';
import { AppSettings } from '../configs/core.config';
import { SWCoreServices } from './core.services';
import { AppConstant } from '../constant/core.constant';
import * as moment from 'moment';
import 'moment-timezone';
import { catchError, map } from 'rxjs/operators';
import {ResolverService} from './resolver.services';
import { Router } from '@angular/router';

@Injectable()
export class AcitivityStreamLogService {

  //agentId: any;

  constructor(private swCoreService: SWCoreServices,private resolverService: ResolverService ) {
    //this.agentId = this.swCoreService.agentInformation['AgentId'];
  }
  createDateFormatActivityStreamLog() {
    var sone = moment.tz.guess();
    var timezone = moment.tz(sone).zoneAbbr();
    var receivedCallDate = new Date();
    var receivedCallDateTimeFormttedOutput = moment.parseZone(receivedCallDate).format('MM/DD/YYYY')
      + ' at ' + moment.parseZone(receivedCallDate).format('hh:mm A');
    return receivedCallDateTimeFormttedOutput;
  }
  setActivityStreamLog(title, message, date, category = AppConstant.ACTIVITY_FEED_INFO, type = '', streamMoreData = {},
    isBord = false, bordCategoryName = '') {

    var activityStreamJson = {
      title: (isBord) ? bordCategoryName : title,
      message: message,
      date: date,
      category: category,
      actionType: type, ////// this type is required to determine its a normal stream or any action needed stream
      actionDetails: streamMoreData
    }
    var oldActivityStream: Array<any> = [];
    oldActivityStream.push(activityStreamJson);
    localStorage.setItem(AppConstant.activityLocalStorage, JSON.stringify(oldActivityStream));

    var dateTmp = new Date();
    var sone = moment.tz.guess();
    var timezone = moment.tz(sone).zoneAbbr();
    var saveTimeStructured = moment.utc(dateTmp).tz(sone).format('MM/DD/YYYY');
    var dataBaseObject = {
      "category": title,
      "description": JSON.stringify(activityStreamJson),
      "activityTimestamp": saveTimeStructured,
      "agentId": this.swCoreService.agentInformation['AgentId'],
      "agentCorpAlias": this.swCoreService.agentInformation['UserName']
    }
    this.saveActivityStream(dataBaseObject)
      .subscribe(result => {
        this.swCoreService.setActivityStream(1);
      }, error => {
        this.swCoreService.setActivityStream(1);
      });
  }

  getActivityStreamLog() {
    var lastStreamStored: any = localStorage.getItem(AppConstant.activityLastStoredLocalStorage),
      time_now: any = (new Date()).getTime();

    // .getTime() returns milliseconds so 1000 * 60 * 60 * 24 = 1 day
    if ((time_now - lastStreamStored) > 1000 * 60 * 60 * 24) {

      localStorage.clear();

      localStorage.setItem(AppConstant.activityLastStoredLocalStorage, time_now);
    }
    if (JSON.parse(localStorage.getItem(AppConstant.activityLocalStorage)) != undefined && JSON.parse(localStorage.getItem(AppConstant.activityLocalStorage)) != null) {
      return JSON.parse(localStorage.getItem(AppConstant.activityLocalStorage)).reverse();
    }
    else {
      return JSON.parse(localStorage.getItem(AppConstant.activityLocalStorage));
    }
  }
  //GetUserInfo call
  callGetUserInfo() {
    return this.swCoreService
      .get(AppSettings.userInfoUrl)
      .pipe(
        map(res => res.data || {}),

        catchError(err => {
          // Handle specific error for this call
          return of(undefined);
        })
      )
      .toPromise();  
  }
  //For logging caller authentication audit trail
  setCallerAuthAuditTrailLog(title, ucid, auditDescObj, agentAuthFields = {}, nphDataObj = {}, agentDataObj = {}) {

    var dateTmp = new Date();
    var sone = moment.tz.guess();
    var timezone = moment.tz(sone).zoneAbbr();
    var saveTimeStructured = moment.utc(dateTmp).tz(sone).format('MM/DD/YYYY');
    localStorage.setItem('setCallerAuthAuditTrailLog', 'clearAgent: ' + new Date());
    
    //localStorage.removeItem(AppConstant.agentLocalStoargeName);
    //Nilanjan fix bypass auth
    if (localStorage.getItem(AppConstant.agentLocalStoargeName) == null) {
      this.callGetUserInfo().then(info => {
        
        localStorage.setItem(
          AppConstant.agentLocalStoargeName,
          JSON.stringify(info)
        );
        this.swCoreService.agentInformation = info;       
        console.log("activity-stream.service.ts - 108");
      });
    }
    
    ///////////////////////////////////
    var description = {
    };
    if (!this.isEmpty(auditDescObj)) {
      description['CallerData'] = auditDescObj;
    }
    if (!this.isEmpty(nphDataObj)) {
      description["NPH"] = {
        "isNPH": true,
        "companyID": nphDataObj["bd"]
      };
    }
    if (!this.isEmpty(agentAuthFields)) {
      description['AgentAuthenticationFields'] = agentAuthFields;
    }
    if (!this.isEmpty(agentDataObj)) {
      description['AgentData'] = agentDataObj;
    }
    var dataBaseObject = {
      "category": title,
      "description": JSON.stringify(description),
      "activityTimestamp": saveTimeStructured,
      "ucid": ucid,
      "agentId": this.swCoreService.agentInformation['AgentId'],
      "agentCorpAlias": this.swCoreService.agentInformation['UserName'],
      "display": false
    };

    this.saveCallerAuthAuditTrail(dataBaseObject)
      .subscribe(result => {
      });
  }

  //For logging other audit trail
  setAuthAuditTrailLog(title, message, streamMoreData = {}) {
    var dateTmp = new Date();
    var sone = moment.tz.guess();
    var timezone = moment.tz(sone).zoneAbbr();
    var saveTimeStructured = moment.utc(dateTmp).tz(sone).format('MM/DD/YYYY');

    var description = {
    };
    description['title'] = title;
    if (message) {
      description['message'] = message;
    }
    if (!this.isEmpty(streamMoreData)) {
      description['actionDetails'] = streamMoreData;
    }
    var dataBaseObject = {
      "category": title,
      "description": JSON.stringify(description),
      "activityTimestamp": saveTimeStructured,
      "ucid": null,
      "agentId": this.swCoreService.agentInformation['AgentId'],
      "agentCorpAlias": this.swCoreService.agentInformation['UserName'],
      "display": false
    };

    this.saveCallerAuthAuditTrail(dataBaseObject)
      .subscribe(result => {
      });
  }

  saveActivityStream(formData) {
    return this.swCoreService
      .httpCall('LogActivity', formData)
      .pipe(map(data => data));
  }

  getActivityStream(dateToFilter) {
    return this.swCoreService
      .httpCall('GetActivity', { date: dateToFilter })
      .pipe(map(res => res));
  }

  saveCallerAuthAuditTrail(formData) {
    return this.swCoreService
      .httpCall(AppConstant.authAudit, formData)
      .pipe(map(data => data));
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

}
