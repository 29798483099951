
<div class="accordion accordion-Container" id="accordionExample">
    <div class="card accordian-card" *ngFor="let menu of menus">
      <div *ngIf="checkHasAccess(menu)">
        <div class="card-header card-Header-Tab panel-heading" id="{{menu.tagId + 'Card'}}" >
            <button class="btn menu-tab-btn collapsed accordianHeader pl-0 pr-0" type="button" data-toggle="collapse" [attr.data-target]="'#' + menu.tagId" [attr.aria-expanded]="!menu.open" [attr.aria-controls]="menu.tagId">
              <img [alt]="menu.name" [src]="menu.icon" style="height: 38px; width:38px;">
              <label class="menuTitleLabel">{{ menu.name }}</label>
              <i class="fa fa-chevron-up arrowIcon" aria-hidden="true"></i>
              <i class="fa fa-chevron-down arrowIcon" aria-hidden="true"></i>
            </button>
        </div>
        
        <div id="{{menu.tagId}}" class="collapse" [ngClass]="{in : menu.open}" [attr.aria-labelledby]="menu.tagId + 'Card'" data-parent="#accordionExample">
            <ul>
                <li *ngFor="let subMenu of menu.sub;let i=index"> 
                  <label class="menuTitle" routerLinkActive="active-link" *ngIf="checkHasAccess(subMenu) ">
                    <a href="javascript:void(0);" [href]="subMenu.link" >{{subMenu.name}}</a>
                  </label>
                </li>
            </ul>
        </div>
        </div>
      </div>
  </div>
  
