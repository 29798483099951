import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminService } from './services/admin.service';
import { AppStateService } from './services/app-state.service';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { CheckboxControlComponent } from './components/response-types/checkbox-control/checkbox-control.component';
import { DropdownControlComponent } from './components/response-types/dropdown-control/dropdown-control.component';
import { DTPickerControlComponent } from './components/response-types/dt-picker-control/dt-picker-control.component';
import { ListboxControlComponent } from './components/response-types/listbox-control/listbox-control.component';
import { RadioBtnControlComponent } from './components/response-types/radio-btn-control/radio-btn-control.component';
import { TextBoxControlComponent } from './components/response-types/text-box-control/text-box-control.component';
import { SsnTextBoxControlComponent } from './components/response-types/ssn-text-box-control/ssn-text-box-control.component';
import { ToggleControlComponent } from './components/response-types/toggle-control/toggle-control.component';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { ExpandCollapseDirective } from './directives/expand-collapse.directive';
import { NgxMyDatePickerModule } from "ngx-mydatepicker";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DynamicControlMapperDirective } from './directives/dynamic-control-mapper.directive';
import { DomChangeDirective } from './directives/dom-change.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxTypeComponent } from './components/control-types/checkbox-type/checkbox-type.component';
import { RadioBtnTypeComponent } from './components/control-types/radio-btn-type/radio-btn-type.component';
import { TextBoxTypeComponent } from './components/control-types/text-box-type/text-box-type.component';
import { SsnTextBoxTypeComponent } from './components/control-types/ssn-text-box-type/ssn-text-box-type.component';
import { TableTypeComponent } from './components/control-types/table-type/table-type.component';
import { GridTypeComponent } from './components/control-types/grid-type/grid-type.component';
import { TableControlComponent } from './components/response-types/table-control/table-control.component';
import { UtilService } from './services/util.service';
import { Toaster } from './services/toaster.service';
import { LoggerService } from './services/logger.service';
import { CustomDialogComponent } from './components/custom-dialog/custom-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule, MatInputModule, MAT_FORM_FIELD_DEFAULT_OPTIONS  } from '@angular/material';
import { MatNativeDateModule } from '@angular/material/core';
import { WijmoFlexGridComponent } from '../../../core/components/ui-components/wijmo-flex-grid/wijmo-flex-grid.component';//"./components/ui-components/wijmo-flex-grid/wijmo-flex-grid.component";
// import { WjInputModule } from "wijmo/wijmo.angular2.input";
// import { WjGridModule } from "wijmo/wijmo.angular2.grid";
// import { WjGridFilterModule } from "wijmo/wijmo.angular2.grid.filter";
// import { WjGridDetailModule } from "wijmo/wijmo.angular2.grid.detail";
// import { WjGridGrouppanelModule } from "wijmo/wijmo.angular2.grid.grouppanel";
// import { WjNavModule } from "wijmo/wijmo.angular2.nav";
  /*wijmo module*/

import { WjGridModule } from 'wijmo/wijmo.angular2.grid';
import { WjInputModule } from "wijmo/wijmo.angular2.input";
import { WjGridFilterModule } from "wijmo/wijmo.angular2.grid.filter";
import { WjGridDetailModule } from "wijmo/wijmo.angular2.grid.detail";
import { WjNavModule } from "wijmo/wijmo.angular2.nav";
import { WjGridGrouppanelModule } from "wijmo/wijmo.angular2.grid.grouppanel";

/*end*/
import { QuillModule } from 'ngx-quill'; 

import { SearchFilterPipe } from "./components/filter-menu/filter-menu.pipe";
import {FilterArrayStartWith} from '../shared/pipes/filterArrayStartWith.pipe';
import { PopUpMessageComponent } from './components/pop-up-message/pop-up-message.component';
import {
  // TableLayoutComponent,
  AccountListFilterComponent,
  DateFilterComponent,
  
  TypeAheadListFilterContainerComponent
} from './components';
import {BELoaderComponent} from './components/BELoader/BEloader.component'
import { FilterMenuComponent} from './components/filter-menu/filter-menu.component'
import { BEMSService } from './services/BEMS.service';
import { FilteringService} from './services/filtering.service'
import { AccountListFilterService } from './components/account-list-filter/services/account-list-filter.service';
import { DatePickerTypeComponent } from './components/control-types/date-picker-type/date-picker-type.component';
//import { LoaderComponent } from "../../../core/components/ui-components/loader/loader.component"; 
import { GridHistoryTypeComponent } from './components/control-types/grid-history-type/grid-history-type.component';
import { GridHistoryControlComponent } from './components/response-types/grid-history-control/grid-history-control.component';

@NgModule({
  declarations: [CheckboxControlComponent, 
                DropdownControlComponent,
                DTPickerControlComponent,
                ListboxControlComponent,
                RadioBtnControlComponent,
                TextBoxControlComponent,
                SsnTextBoxControlComponent,
                ToggleControlComponent,
                SafeHTMLPipe,
                ExpandCollapseDirective,
                DynamicControlMapperDirective,
                DomChangeDirective,
                CheckboxTypeComponent,
                RadioBtnTypeComponent,
                TextBoxTypeComponent,
                SsnTextBoxTypeComponent,
                TableTypeComponent,
                GridTypeComponent,
                TableControlComponent,
                CustomDialogComponent,
                //TableLayoutComponent,
                AccountListFilterComponent,
                DateFilterComponent,
                DatePickerTypeComponent,
                FilterMenuComponent,
                TypeAheadListFilterContainerComponent,
                SearchFilterPipe,
                FilterArrayStartWith,
                PopUpMessageComponent,
                WijmoFlexGridComponent,
                BELoaderComponent,
                GridHistoryTypeComponent,
                GridHistoryControlComponent
                ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,    
    NgbModule,
    WjInputModule,
    WjGridModule,
    WjGridFilterModule,
    WjGridDetailModule,
    WjGridGrouppanelModule,
    WjNavModule,
    NgxMyDatePickerModule.forRoot(),
    QuillModule.forRoot(),
    
  ],
  providers: [
    AdminService,
    AppStateService,
    HttpInterceptorService,
    UtilService,
    Toaster,
    BEMSService,
    FilteringService,
    AccountListFilterService,
    LoggerService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'fill'}
    }
  ],
  exports: [CheckboxControlComponent, 
    DropdownControlComponent,
    DTPickerControlComponent,
    ListboxControlComponent,
    RadioBtnControlComponent,
    TextBoxControlComponent,
    SsnTextBoxControlComponent,
    TableTypeComponent,
    GridTypeComponent,
    ToggleControlComponent,
    CheckboxTypeComponent,
    RadioBtnTypeComponent,
    TextBoxTypeComponent,
    SsnTextBoxTypeComponent,
    DynamicControlMapperDirective,
    SafeHTMLPipe,
    ExpandCollapseDirective,
    DomChangeDirective,
    TableControlComponent,
    CustomDialogComponent,
    //TableLayoutComponent,
    AccountListFilterComponent,
    DateFilterComponent,
    FilterMenuComponent,
    DatePickerTypeComponent,
    TypeAheadListFilterContainerComponent,
    SearchFilterPipe,
    FilterArrayStartWith,
    PopUpMessageComponent,
    WijmoFlexGridComponent,
    FormsModule,
    WjInputModule,
    WjGridModule,
    WjGridFilterModule,
    WjGridDetailModule,
    WjGridGrouppanelModule,
    WjNavModule,
    NgbModule,
    BELoaderComponent,
    GridHistoryTypeComponent,
    GridHistoryControlComponent
  ],
})
export class SharedModule { }
