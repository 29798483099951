﻿/*
 *
 * Wijmo Library 5.20182.500
 * http://wijmo.com/
 *
 * Copyright(c) GrapeCity, Inc.  All rights reserved.
 *
 * Licensed under the GrapeCity Commercial License.
 * sales@wijmo.com
 * wijmo.com/products/wijmo-5/license/
 *
 */
var __extends = this && this.__extends || function () {
  var t = Object.setPrototypeOf || {
    __proto__: []
  }
  instanceof Array && function (t, e) {
    t.__proto__ = e
  } || function (t, e) {
    for (var n in e) e.hasOwnProperty(n) && (t[n] = e[n])
  };
  return function (e, n) {
    function r() {
      this.constructor = e
    }
    t(e, n), e.prototype = null === n ? Object.create(n) : (r.prototype = n.prototype, new r)
  }
}();
define(["require", "exports", "wijmo/wijmo"], function (t, e, n) {
  "use strict";

  function r() {
    return Dt
  }

  function i(t, e) {
    return null == t ? null : s(e) ? l(t.implementsInterface) && t.implementsInterface(e) ? t : null : t instanceof e ? t : null
  }

  function o(t) {
    return s(t) || a(t) || c(t) || h(t)
  }

  function s(t) {
    return "string" == typeof t
  }

  function a(t) {
    return "number" == typeof t
  }

  function u(t) {
    return a(t) && t == Math.round(t)
  }

  function c(t) {
    return "boolean" == typeof t
  }

  function l(t) {
    return "function" == typeof t
  }

  function h(t) {
    return (t instanceof Date || "[object Date]" === Object.prototype.toString.call(t)) && !isNaN(t.getTime())
  }

  function p(t) {
    return t instanceof Array || Array.isArray(t) || "[object Array]" === Object.prototype.toString.call(t)
  }

  function f(t) {
    return null != t && "object" == typeof t && !h(t) && !p(t)
  }

  function d(t) {
    for (var e = t, n = 0; null != document.getElementById(e); n++) e = t + n;
    return e
  }

  function g(t, e, n) {
    if (null != t) {
      if (s(t)) switch (e) {
        case Lt.Number:
          var r = Bt.parseFloat(t, n);
          return isNaN(r) ? t : r;
        case Lt.Date:
          var i = Bt.parseDate(t, n);
          return i || n || !t || (i = new Date(t)), i && isFinite(i.getTime()) ? i : t;
        case Lt.Boolean:
          switch (t.toLowerCase()) {
            case "true":
              return !0;
            case "false":
              return !1
          }
          return t
      }
      if (e == Lt.String) return Bt.format(t, n)
    }
    return t
  }

  function _(t, e, n) {
    if (n) {
      var r = (i = t.toString()).indexOf(".");
      i.indexOf("e") < 0 && r > -1 && (i = i.substr(0, r + 1 + e), t = parseFloat(i))
    } else {
      var i = t.toFixed(e);
      t = parseFloat(i)
    }
    return t
  }

  function m(t, e, n) {
    if ((t = w(t)).match(/\{.*"count".*:.*"when".*:.*\}/)) try {
      var r = JSON.parse(t);
      if (s(r.count)) {
        var i = e[r.count],
          o = r.when;
        if (a(i) && f(o)) {
          var u = o[i] || o.other;
          s(u) && (t = u)
        }
      }
    } catch (t) {}
    return t.replace(/\{(.*?)(:(.*?))?\}/g, function (t, r, i, o) {
      var s = t;
      return r && "{" != r[0] && e && (s = e[r], o && (s = Bt.format(s, o)), n && (s = n(e, r, o, s))), null == s ? "" : s
    })
  }

  function v(t, e, n) {
    return null != t && (null != n && t > n && (t = n), null != e && t < e && (t = e)), t
  }

  function y(t, e) {
    if (e)
      for (var n in e)
        if ("_" != n[0]) {
          b(n in t, 'Unknown property "' + n + '".');
          var r = e[n];
          t._copy && t._copy(n, r) || (t[n] instanceof Gt ? l(r) && t[n].addHandler(r) : !f(r) || "undefined" != typeof Element && r instanceof Element || !t[n] || "itemsSource" == n ? t[n] = r : y(t[n], r))
        }
  }

  function b(t, e) {
    if (!t) throw e = "** Assertion failed in Wijmo: " + e, console.error(e), e
  }

  function w(t, e) {
    return void 0 === e && (e = !0), b(e && null == t || s(t), "String expected."), t
  }

  function C(t, e, n) {
    if (void 0 === e && (e = !1), void 0 === n && (n = !1), b(e && null == t || a(t), "Number expected."), n && t && t < 0) throw "Positive number expected.";
    return t
  }

  function E(t, e, n) {
    if (void 0 === e && (e = !1), void 0 === n && (n = !1), b(e && null == t || u(t), "Integer expected."), n && t && t < 0) throw "Positive integer expected.";
    return t
  }

  function A(t, e) {
    return void 0 === e && (e = !1), b(e && null == t || c(t), "Boolean expected."), t
  }

  function x(t, e) {
    if (void 0 === e && (e = !1), s(t)) {
      var n = g(t, Lt.Date, "r");
      h(n) && (t = n)
    }
    return b(e && null == t || h(t), "Date expected."), t
  }

  function M(t, e) {
    return void 0 === e && (e = !0), b(e && null == t || l(t), "Function expected."), t
  }

  function T(t, e) {
    return void 0 === e && (e = !0), b(e && null == t || p(t), "Array expected."), t
  }

  function O(t, e, n) {
    return void 0 === n && (n = !1), t = i(t, e), b(n || null != t, e + " expected."), t
  }

  function I(t, e, n) {
    if (void 0 === n && (n = !1), null == t && n) return null;
    var r = e[t];
    return b(null != r, "Invalid enum value."), a(r) ? r : t
  }

  function P(t, e) {
    if (t && e) {
      if (t instanceof HTMLElement && t.classList && !Ot()) return t.classList.contains(e);
      if (t.getAttribute) {
        var n = new RegExp("(\\s|^)" + e + "(\\s|$)");
        return t && n.test(t.getAttribute("class"))
      }
    }
    return !1
  }

  function S(t, e) {
    if (t && e) {
      if (t instanceof HTMLElement && t.classList && !Ot()) {
        if (e.indexOf(" ") < 0) t.classList.add(e);
        else
          for (var n = e.split(" "), r = 0; r < n.length; r++) t.classList.add(n[r]);
        return
      }
      if (t.setAttribute)
        for (var n = e.split(" "), r = 0; r < n.length; r++) {
          var i = n[r];
          if (!P(t, i)) {
            var o = t.getAttribute("class");
            t.setAttribute("class", o ? o + " " + i : i)
          }
        }
    }
  }

  function D(t, e) {
    if (t && e) {
      if (t instanceof HTMLElement && t.classList && !Ot()) {
        if (e.indexOf(" ") < 0) t.classList.remove(e);
        else
          for (var n = e.split(" "), r = 0; r < n.length; r++) t.classList.remove(n[r]);
        return
      }
      if (t.setAttribute)
        for (var n = e.split(" "), r = 0; r < n.length; r++) {
          var i = n[r];
          if (P(t, i)) {
            var o = new RegExp("((\\s|^)" + i + "(\\s|$))", "g"),
              s = t.getAttribute("class");
            (s = s.replace(o, " ").replace(/ +/g, " ").trim()) ? t.setAttribute("class", s): t.removeAttribute("class")
          }
        }
    }
  }

  function F(t, e, n) {
    null == n && (n = !P(t, e)), n ? S(t, e) : D(t, e)
  }

  function L(t, e, n, r) {
    t && (null != n ? r && t.getAttribute(e) || t.setAttribute(e, n.toString()) : t.removeAttribute(e))
  }

  function j(t, e, n) {
    if (void 0 === n && (n = e), b(t instanceof HTMLInputElement || t instanceof HTMLTextAreaElement, "INPUT or TEXTAREA element expected"), z(document.body, t) && !t.disabled && "none" != t.style.display) try {
      return t.setSelectionRange(C(e), C(n), Tt() ? null : "backward"), t.focus(), !0
    } catch (t) {}
    return !1
  }

  function k(t) {
    return t && t.parentNode ? t.parentNode.removeChild(t) : null
  }

  function R() {
    var t = document.activeElement;
    if (t) {
      var e = t.shadowRoot;
      e && e.activeElement && (t = e.activeElement)
    }
    return t
  }

  function H(t, e) {
    var n = N(t),
      r = 0;
    if (e) {
      var i = n.indexOf(R());
      i > -1 && (r = (i + e + n.length) % n.length)
    }
    if (r < n.length) {
      var o = n[r];
      o.focus(), o instanceof HTMLInputElement && o.select()
    }
  }

  function N(t) {
    for (var e = [], n = t.querySelectorAll("input,select,textarea,button,a,div"), r = 0; r < n.length; r++) {
      var i = n[r];
      if (i.offsetHeight > 0 && i.tabIndex > -1 && !i.disabled && !G(i, "[disabled],.wj-state-disabled")) {
        if (i instanceof HTMLAnchorElement && !i.hasAttribute("href")) continue;
        if (Tt() && !i.hasAttribute("tabindex")) {
          if (i instanceof HTMLDivElement) continue;
          var o = $t.getControl(G(i, ".wj-flexgrid"));
          if (o && 0 == o.keyActionTab) continue
        }!$t.getControl(i) && N(i).length || e.push(i)
      }
    }
    return e
  }

  function B(t) {
    return t instanceof Element ? t : s(t) ? document.querySelector(t) : t && t.jquery ? t[0] : null
  }

  function V(t, e) {
    var n = document.createElement("div");
    return n.innerHTML = t, 1 == n.children.length && (n = n.children[0]), e && (e = B(e)).appendChild(n), n
  }

  function z(t, e) {
    for (var n = e; n && t;) {
      if (n === t) return !0;
      n = n.parentNode || n.host
    }
    return !1
  }

  function G(t, e) {
    var n = t ? t.matches || t.webkitMatchesSelector || t.mozMatchesSelector || t.msMatchesSelector : null;
    if (n)
      for (; t; t = t.parentNode)
        if (t instanceof Element && n.call(t, e)) return t;
    return null
  }

  function U(t, e) {
    var n = !e;
    F(t, "wj-state-disabled", n), L(t, "disabled", n ? "true" : null);
    for (var r = t.querySelectorAll("input"), i = 0; i < r.length; i++) {
      var o = r[i];
      e ? o.removeAttribute("disabled") : o.setAttribute("disabled", "true")
    }
  }

  function W(t, e) {
    if (b(f(e), "css parameter should be an object"), t instanceof Array)
      for (var n = 0; n < t.length; n++) W(t[n], e);
    else if (t && t.style) {
      var r = t.style;
      for (var i in e) {
        var o = e[i];
        "number" == typeof o && i.match(/width|height|left|top|right|bottom|size|padding|margin'/i) && (o += "px"), r[i] !== o && (r[i] = o.toString())
      }
    }
  }

  function Y(t, e, n) {
    void 0 === e && (e = $t._ANIM_DEF_DURATION), void 0 === n && (n = $t._ANIM_DEF_STEP), t = M(t), e = C(e, !1, !0), n = C(n, !1, !0);
    var r, i = Date.now(),
      o = setInterval(function () {
        var n = Math.min(1, (Date.now() - i) / e);
        n = Math.sin(n * Math.PI / 2), n *= n, r && cancelAnimationFrame(r), r = requestAnimationFrame(function () {
          r = null, t(n)
        }), n >= 1 && clearInterval(o)
      }, n);
    return o
  }

  function K(t, e) {
    e || (e = {});
    var n = e.method ? w(e.method).toUpperCase() : "GET",
      r = null == e.async || A(e.async),
      i = e.data;
    if (null != i && "GET" == n) {
      var o = [];
      for (var u in i) {
        var c = i[u];
        h(c) && (c = c.toJSON()), o.push(u + "=" + c)
      }
      o.length && (t += (t.indexOf("?") < 0 ? "?" : "&") + o.join("&")), i = null
    }
    var p = new XMLHttpRequest;
    p.URL_DEBUG = t;
    var d = !1;
    if (null == i || s(i) || (d = f(i), i = JSON.stringify(i)), p.onload = function () {
        4 == p.readyState && (p.status < 300 ? e.success && M(e.success)(p) : e.error && M(e.error)(p), e.complete && M(e.complete)(p))
      }, p.onerror = function () {
        if (!l(e.error)) throw "HttpRequest Error: " + p.status + " " + p.statusText;
        e.error(p)
      }, p.open(n, t, r, e.user, e.password), e.user && e.password && p.setRequestHeader("Authorization", "Basic " + btoa(e.user + ":" + e.password)), d && p.setRequestHeader("Content-Type", "application/json"), e.requestHeaders)
      for (var g in e.requestHeaders) p.setRequestHeader(g, e.requestHeaders[g]);
    return a(e.timeout) && (p.timeout = e.timeout), l(e.beforeSend) && e.beforeSend(p), p.send(i), p
  }

  function q(t, e, n) {
    var r = 0,
      i = 0,
      o = 0,
      s = 0,
      u = null,
      l = null,
      h = null,
      p = n ? new Vt(n) : null;
    if ((t = I(t, Qt)) == Qt.CntAll) return e.length;
    for (var f = 0; f < e.length; f++) {
      var d = e[f];
      if (p && (d = p.getValue(d)), t == Qt.First) return d;
      null != d && (r++, (null == u || d < u) && (u = d), (null == l || d > l) && (l = d), h = d, a(d) && !isNaN(d) ? (i++, o += d, s += d * d) : c(d) && (i++, 1 == d && (o++, s++)))
    }
    var g = 0 == i ? 0 : o / i;
    switch (t) {
      case Qt.Avg:
        return g;
      case Qt.Cnt:
        return r;
      case Qt.Max:
        return l;
      case Qt.Min:
        return u;
      case Qt.Rng:
        return l - u;
      case Qt.Sum:
        return o;
      case Qt.VarPop:
        return i <= 1 ? 0 : s / i - g * g;
      case Qt.StdPop:
        return i <= 1 ? 0 : Math.sqrt(s / i - g * g);
      case Qt.Var:
        return i <= 1 ? 0 : (s / i - g * g) * i / (i - 1);
      case Qt.Std:
        return i <= 1 ? 0 : Math.sqrt((s / i - g * g) * i / (i - 1));
      case Qt.Last:
        return h
    }
    throw "Invalid aggregate type."
  }

  function J(t, e, n, r, i) {
    void 0 === i && (i = !0);
    var o = document.body;
    if (e instanceof HTMLElement) {
      if (!z(document.body, e)) return;
      for (var s = e; s; s = s.parentElement)
        if (Z(s) || "fixed" == getComputedStyle(s).position) {
          o = s;
          break
        }
    }
    W(t, {
      display: "",
      opacity: "0",
      position: "fixed",
      left: 0,
      top: 0
    }), t.offsetHeight && t.offsetWidth && t.parentElement == o || o.lastChild != t && o.appendChild(t);
    var a = document.body,
      u = new kt(a.scrollLeft || pageXOffset, a.scrollTop || pageYOffset),
      c = document.documentElement,
      l = c.clientWidth / window.innerWidth;
    if (o != a || l > 1.005) {
      var h = o == a ? c : t.offsetParent || o;
      if (Z(o) && o.offsetParent && (h = o.offsetParent), h) {
        var p = h.getBoundingClientRect();
        u = new kt(h.scrollLeft - p.left, h.scrollTop - p.top)
      }
    }
    t.offsetHeight && t.offsetWidth && z(o, t) || o.lastChild != t && o.appendChild(t);
    var f = null;
    e instanceof HTMLElement && (f = getComputedStyle(e), i && new de(f.backgroundColor).a && W(t, {
      color: f.color,
      backgroundColor: f.backgroundColor,
      fontFamily: f.fontFamily,
      fontSize: f.fontSize,
      fontWeight: f.fontWeight,
      fontStyle: f.fontStyle
    })), $t.refreshAll(t);
    var d = getComputedStyle(t),
      g = parseFloat(d.marginTop) + parseFloat(d.marginBottom),
      _ = parseFloat(d.marginLeft) + parseFloat(d.marginRight),
      m = new Rt(t.offsetWidth + _, t.offsetHeight + g),
      v = new kt,
      y = null,
      b = c.clientWidth,
      w = c.clientHeight;
    if (e && null != e.clientX && null != e.clientY && null != e.pageX && null != e.pageY) e.clientX <= 0 && e.clientY <= 0 && e.target ? y = e.target.getBoundingClientRect() : (v.x = Math.max(0, e.pageX - pageXOffset), v.y = Math.max(0, e.pageY - pageYOffset));
    else if (e instanceof kt) v = e;
    else if (e instanceof HTMLElement) y = e.getBoundingClientRect();
    else if (e && null != e.top && null != e.left) y = e;
    else {
      if (null != e) throw "Invalid ref parameter.";
      v.x = Math.max(0, (b - m.width) / 2), v.y = Math.max(0, Math.round((w - m.height) / 2 * .7))
    }
    var C = parseFloat(d.minWidth);
    if (y) {
      C = Math.max(C, y.width), Tt() && (C -= t.offsetWidth - (t.clientWidth + parseInt(d.borderLeftWidth) + parseInt(d.borderRightWidth)));
      var E = y.top,
        A = w - y.bottom;
      v.x = Math.max(0, Math.min(y.left, b - m.width)), v.y = n ? E > m.height || E > A ? Math.max(0, y.top - m.height) : y.bottom : A > m.height || A > E ? y.bottom : Math.max(0, y.top - m.height)
    } else {
      v.y + m.height > w - 20 && (v.y = Math.max(0, w - 20 - m.height)), v.x + m.width > b - 20 && (v.x = Math.max(0, b - 20 - m.width))
    }
    var x = {
      position: "absolute",
      opacity: "",
      left: v.x + u.x,
      top: v.y + u.y,
      minWidth: C,
      zIndex: $t._POPUP_ZINDEX,
      pointerEvents: "auto"
    };
    e instanceof HTMLElement && (t[$t._OWNR_KEY] = e);
    var M = null;
    r && (x.opacity = "0", M = Y(function (e) {
      t.style.opacity = e < 1 ? e.toString() : ""
    })), W(t, x);
    var T = e instanceof MouseEvent ? e.target : e;
    if (T instanceof HTMLElement && T.parentElement != document.body) {
      var O = Date.now(),
        I = T.getBoundingClientRect(),
        P = new $t(document.createElement("div"));
      t[$t._SCRL_KEY] = P, P.addEventListener(document, "scroll", function (n) {
        if (Date.now() - O > 100 && z(document, T) && !z(t, n.target) && (n.target != document || null != e && "fixed" == t.style.position)) {
          var r = T.getBoundingClientRect(),
            i = Math.abs(r.left - I.left),
            o = Math.abs(r.top - I.top);
          (i > 1 || o > 1) && X(t, !0)
        }
      }, !0)
    }
    return M
  }

  function X(t, e) {
    t.style.display = "none", e && t.parentElement && setTimeout(function () {
      "none" == t.style.display && (k(t), l(e) && e())
    }, $t._FOCUS_INTERVAL + 1);
    var n = t[$t._SCRL_KEY];
    n instanceof $t && n.dispose(), delete t[$t._SCRL_KEY], delete t[$t._OWNR_KEY]
  }

  function Z(t) {
    var e = window.jQuery,
      n = l(e) ? e(t) : null;
    return n && l(n.dialog) && P(t, "ui-dialog")
  }

  function $(t) {
    return t._getProductInfo().split(",")[0]
  }

  function Q(t) {
    return t._getProductInfo().split(",")[1]
  }

  function tt(t, n, r) {
    var i = e.culture.Licensing || ve,
      o = null;
    r ? nt(t, r) ? rt(t, r) ? it(t, r) ? et(t, r) ? ot(t, r) && (o = i.evl) : o = i.prd : o = i.dmn : o = i.ver : o = i.exp : o = n ? i.lic : i.mss, o && at(t, o += "<br/><br/>" + i.ctc, r)
  }

  function et(t, e) {
    var n = $(t);
    if (n && n.length >= 4)
      for (var r = 0; r < e.Prd.length; r++)
        if (e.Prd[r].C.indexOf(n) > -1) return !0;
    return !1
  }

  function nt(t, e) {
    if (e.Exp) {
      var n = e.Exp,
        r = new Date(parseInt(n.substr(0, 4)) || 0, parseInt(n.substr(4, 2)) - 1 || 0, parseInt(n.substr(6, 2)) || 0);
      if (Math.ceil((r.valueOf() - Date.now()) / 864e5) <= 0) return !1
    }
    return !0
  }

  function rt(t, e) {
    var n = e.Anl ? e.Anl.ver : null,
      i = r();
    return !n || !i || parseInt(n.replace("v", "")) + 10 >= parseInt(i.split(".")[1])
  }

  function it(t, e) {
    if (st() || !e.Dms) return !0;
    for (var n = location.hostname, r = e.Dms.split(","), i = !1, o = 0; o < r.length; o++) {
      var s = r[o];
      if (i = s && 0 == s.lastIndexOf("*.", 0), (s = i ? s.slice(2) : s) == n) return !0;
      if (i) {
        var a = n.indexOf("." + s);
        if (a > 0 && a == n.length - s.length - 1) return !0
      }
    }
    return !1
  }

  function ot(t, e) {
    return !!e && e.Evl
  }

  function st() {
    var t = atob("Xih8bG9jYWxob3N0fGZpZGRsZS5qc2hlbGwubmV0fHJ1bi5wbG5rci5jb3xzLmNvZGVwZW4uaW98bnVsbC5qc2Jpbi5jb218MTI3LjAuMC4xKSQ="),
      e = new RegExp(t, "i");
    return location.hostname.match(e)
  }

  function at(t, n, r) {
    if (n = ct(t, n, r), !ye[" "] && !st() && !ot(t, r)) {
      ye[" "] = !0;
      var i = e.culture.Licensing || ve,
        o = V(s = '<div style="display:none;position:fixed;background:rgba(0,0,0,0.3);left:0;top:0;width:100%;height:100%;font-family: sans-serif;z-index:10000;"><div style="position:relative;background:white;width:450px;padding:36pt;left:50%;top:50%;transform:translate(-50%,-50%)"><div><p style="font-size:150%;font-weight:bold">' + i.hdr + "</p><p>" + n + '</p></div><div style="text-align:right;margin-top:24pt"><button style="border:none;background:rgba(158,158,158,.2);padding:8px 16px">' + i.cls + "</button></div></div></div>", document.body);
      o.addEventListener("click", function (t) {
        t.target instanceof HTMLButtonElement && k(o)
      })
    }
    if (!$t._wme) {
      var s = '<div><a href="' + ut() + '" target="_blank" rel="noopener">' + ct(t) + "</a></div>",
        a = V(s),
        u = {
          position: "fixed",
          display: "none",
          visibility: "visible",
          background: "rgba(240,240,240,.9)",
          padding: "8px 12px",
          margin: "6px",
          fontFamily: "sans-serif",
          fontSize: "14pt",
          fontWeight: "bold",
          zIndex: 1e4,
          opacity: .8,
          height: "auto",
          width: "auto",
          transform: "none"
        };
      switch (Math.round(100 * Math.random()) % 3) {
        case 0:
          u.right = u.bottom = 0;
          break;
        case 1:
          u.left = u.bottom = 0;
          break;
        case 2:
          u.right = u.top = 0
      }
      W(a, u), document.body.appendChild(a), $t._wme = a, (new pe).setTooltip(a, n)
    }
  }

  function ut() {
    return "ja" == e.culture.Globalize.name ? "https://www.grapecity.co.jp/developer/support/wijmo-license" : "https://www.grapecity.com/en/licensing/wijmo"
  }

  function ct(t, n, i) {
    (!n || n.trim().length < 15) && (n = (e.culture.Licensing || ve).evl), (!n || n.trim().length < 15) && (n = atob("V2lqbW8gRXZhbHVhdGlvbiBWZXJzaW9uICh7dmVyc2lvbn0p"));
    var o = null;
    if (i && i.Exp) {
      var s = i.Exp;
      o = new Date(parseInt(s.substr(0, 4)) || 0, parseInt(s.substr(4, 2)) - 1 || 0, parseInt(s.substr(6, 2)) || 0)
    }
    return n = m(n, {
      version: r(),
      domain: location.hostname,
      control: Q(t),
      licVer: i && i.Anl ? "5." + i.Anl.ver.replace("v", "") + ".*" : null,
      licDomain: i ? i.Dms : null,
      expDate: o
    })
  }

  function lt(t, e, n, r) {
    var i = ht(t, e.algorithmCode);
    if (!i) return r();
    var o = i.licData,
      s = i.prefix + "#" + e.algorithmCode;
    if (At(s + JSON.stringify(o.D)) != o.H) return r();
    var a = window.msCrypto || window.crypto,
      u = a ? a.subtle : null;
    if (!u || !location.protocol.match(/^https/i)) return n(o.D);
    var c = {
        name: "RSASSA-PKCS1-v1_5",
        hash: {
          name: "SHA-256"
        }
      },
      l = {
        alg: "RS256",
        e: "AQAB",
        kty: "RSA",
        n: Ct(e.publicKey)
      };
    l = window.msCrypto ? Et(JSON.stringify(l)) : l;
    try {
      xt(u.importKey("jwk", l, c, !1, ["verify"])).then(function (t) {
        var e = s + yt(bt(wt(JSON.stringify(o.D))));
        xt(u.verify(c, t, bt(o.S), Et(e))).then(function (t) {
          return t ? n(o.D) : r()
        })
      }, function () {
        return n(o.D)
      })
    } catch (t) {
      return n(o.D)
    }
  }

  function ht(t, e) {
    try {
      e = "#" + e;
      var n = t.indexOf(e);
      if (n > -1) return {
        prefix: t.substring(0, n),
        licData: JSON.parse(pt(t.substr(n + e.length)))
      }
    } catch (t) {}
    return null
  }

  function pt(t) {
    if (!t) return "";
    var e = ft(t);
    e = mt(e);
    var n = Math.ceil(e.length / 2);
    return e = e.substr(n) + e.substr(0, n), e = e.replace("#", "="), e = e.replace("&", "=="), vt(e)
  }

  function ft(t) {
    for (var e = t.split(""), n = e.length - 5; n >= 0; n--) dt(e, n + 1, n + 3, _t), dt(e, n, n + 2, _t);
    return e.join("")
  }

  function dt(t, e, n, r) {
    t.length > 1 && t.splice(n, 1, r(t.splice(e, 1, r(t[n]))[0]))
  }

  function gt(t, e) {
    var n = t.charCodeAt(0);
    return n >= 65 && n <= 90 ? t.toLowerCase() : n >= 97 && n <= 122 ? t.toUpperCase() : n >= 48 && n <= 57 ? String.fromCharCode(48 + (n - 48 + 10 + e) % 10) : t
  }

  function _t(t) {
    return gt(t, -1)
  }

  function mt(t) {
    return t.split("").reverse().join("")
  }

  function vt(t) {
    return decodeURIComponent(Array.prototype.map.call(atob(t), function (t) {
      return "%" + ("00" + t.charCodeAt(0).toString(16)).slice(-2)
    }).join(""))
  }

  function yt(t) {
    return String.fromCharCode.apply(null, new be(t))
  }

  function bt(t) {
    for (var e = atob(t), n = e.length, r = new be(n), i = 0; i < n; i++) r[i] = e.charCodeAt(i);
    return r.buffer
  }

  function wt(t) {
    return btoa(encodeURIComponent(t).replace(/%([0-9A-F]{2})/g, function (t, e) {
      return String.fromCharCode(parseInt(e, 16))
    }))
  }

  function Ct(t) {
    return t.replace(/\+/g, "-").replace(/\//g, "_").replace(/\=+$/, "")
  }

  function Et(t) {
    for (var e = new ArrayBuffer(t.length), n = new be(e), r = 0, i = t.length; r < i; r++) n[r] = t.charCodeAt(r);
    return e
  }

  function At(t) {
    for (var e = 0, n = 5381, r = 0, i = t.length - 1; i >= 0; i--) {
      var o = t.charCodeAt(i);
      e = o + (e << 6) + (e << 16) - e, n = o + ((n << 5) + n), r = o + ((r << 5) - r), r &= r
    }
    var s = e ^ n ^ r;
    return s < 0 && (s = ~s), s.toString(16).toUpperCase()
  }

  function xt(t) {
    return "oncomplete" in t && (t.then = function (e, n) {
      t.resolve = e, t.reject = n
    }, t.oncomplete = function (e) {
      t.resolve(t.result)
    }, t.onerror = function (e) {
      t.reject()
    }), t
  }

  function Mt() {
    return xe
  }

  function Tt() {
    return Oe
  }

  function Ot() {
    return Ie
  }

  function It(t, e) {
    return Pe ? {
      capture: t,
      passive: e
    } : t
  }
  Object.defineProperty(e, "__esModule", {
    value: !0
  });
  var Pt = n || e,
    St = window.wijmo && window.wijmo.culture;
  window.wijmo = Pt, e.culture = St;
  var Dt = "5.20182.500";
  e.getVersion = r, e.setLicenseKey = function (t) {
    $t._licKey = t
  };
  var Ft;
  ! function (t) {
    t[t.Back = 8] = "Back", t[t.Tab = 9] = "Tab", t[t.Enter = 13] = "Enter", t[t.Escape = 27] = "Escape", t[t.Space = 32] = "Space", t[t.PageUp = 33] = "PageUp", t[t.PageDown = 34] = "PageDown", t[t.End = 35] = "End", t[t.Home = 36] = "Home", t[t.Left = 37] = "Left", t[t.Up = 38] = "Up", t[t.Right = 39] = "Right", t[t.Down = 40] = "Down", t[t.Delete = 46] = "Delete", t[t.F1 = 112] = "F1", t[t.F2 = 113] = "F2", t[t.F3 = 114] = "F3", t[t.F4 = 115] = "F4", t[t.F5 = 116] = "F5", t[t.F6 = 117] = "F6", t[t.F7 = 118] = "F7", t[t.F8 = 119] = "F8", t[t.F9 = 120] = "F9", t[t.F10 = 121] = "F10", t[t.F11 = 122] = "F11", t[t.F12 = 123] = "F12"
  }(Ft = e.Key || (e.Key = {}));
  var Lt;
  ! function (t) {
    t[t.Object = 0] = "Object", t[t.String = 1] = "String", t[t.Number = 2] = "Number", t[t.Boolean = 3] = "Boolean", t[t.Date = 4] = "Date", t[t.Array = 5] = "Array"
  }(Lt = e.DataType || (e.DataType = {})), e.tryCast = i, e.isPrimitive = o, e.isString = s, e.isNullOrWhiteSpace = function (t) {
    return null == t || t.replace(/\s/g, "").length < 1
  }, e.isNumber = a, e.isInt = u, e.isBoolean = c, e.isFunction = l, e.isUndefined = function (t) {
    return void 0 === t
  }, e.isDate = h, e.isArray = p, e.isObject = f, e.isEmpty = function (t) {
    for (var e in t) return !1;
    return !0
  }, e.getUniqueId = d, e.mouseToPage = function (t) {
    if (t instanceof kt) return t;
    if (t.touches && t.touches.length > 0 && (t = t.touches[0]), a(t.clientX) && a(t.clientY)) return new kt(t.clientX + pageXOffset, t.clientY + pageYOffset);
    throw "Mouse or touch event expected."
  }, e.getType = function (t) {
    return a(t) ? Lt.Number : c(t) ? Lt.Boolean : h(t) ? Lt.Date : s(t) ? Lt.String : p(t) ? Lt.Array : Lt.Object
  }, e.changeType = g, e.toFixed = _, e.format = m, e.clamp = v, e.copy = y, e.assert = b, e._deprecated = function (t, e) {
    console.error('** WARNING: "' + t + '" has been deprecated; please use "' + e + '" instead.')
  }, e.asString = w, e.asNumber = C, e.asInt = E, e.asBoolean = A, e.asDate = x, e.asFunction = M, e.asArray = T, e.asType = O, e.asEnum = I, e.asCollectionView = function (t, e) {
    if (void 0 === e && (e = !0), null == t && e) return null;
    var n = i(t, "ICollectionView");
    return null != n ? n : (p(t) || b(!1, "Array or ICollectionView expected."), new le(t))
  }, e.hasItems = function (t) {
    return null != t && null != t.items && t.items.length > 0
  }, e.toHeaderCase = function (t) {
    return t && t.length ? t[0].toUpperCase() + t.substr(1).replace(/([a-z])([A-Z])/g, "$1 $2") : ""
  }, e.escapeHtml = function (t) {
    return s(t) && (t = t.replace(/[&<>"'\/]/g, function (t) {
      return jt[t]
    })), t
  };
  var jt = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
    "/": "&#x2F;"
  };
  e.hasClass = P, e.addClass = S, e.removeClass = D, e.toggleClass = F, e.setAttribute = L, e.setAriaLabel = function (t, e) {
    L(t, "aria-label", e)
  }, e.setSelectionRange = j, e.disableAutoComplete = function (t) {
    "autocomplete,autocorrect,autocapitalize,spellcheck".split(",").forEach(function (e) {
      t.setAttribute(e, "spellcheck" == e ? "false" : "off")
    })
  }, e.removeChild = k, e.getActiveElement = R, e.moveFocus = H, e.getElement = B, e.createElement = V, e.setText = function (t, e) {
    t.textContent = e || ""
  }, e.contains = z, e.closest = G, e.closestClass = function (t, e) {
    return G(t, "." + e)
  }, e.enable = U, e.getElementRect = function (t) {
    var e = t.getBoundingClientRect();
    return new Ht(e.left + pageXOffset, e.top + pageYOffset, e.width, e.height)
  }, e.setCss = W, e.animate = Y;
  var kt = function () {
    function t(t, e) {
      void 0 === t && (t = 0), void 0 === e && (e = 0), this.x = C(t), this.y = C(e)
    }
    return t.prototype.equals = function (e) {
      return e instanceof t && this.x == e.x && this.y == e.y
    }, t.prototype.clone = function () {
      return new t(this.x, this.y)
    }, t
  }();
  e.Point = kt;
  var Rt = function () {
    function t(t, e) {
      void 0 === t && (t = 0), void 0 === e && (e = 0), this.width = C(t), this.height = C(e)
    }
    return t.prototype.equals = function (e) {
      return e instanceof t && this.width == e.width && this.height == e.height
    }, t.prototype.clone = function () {
      return new t(this.width, this.height)
    }, t
  }();
  e.Size = Rt;
  var Ht = function () {
    function t(t, e, n, r) {
      this.left = C(t), this.top = C(e), this.width = C(n), this.height = C(r)
    }
    return Object.defineProperty(t.prototype, "right", {
      get: function () {
        return this.left + this.width
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "bottom", {
      get: function () {
        return this.top + this.height
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.equals = function (e) {
      return e instanceof t && this.left == e.left && this.top == e.top && this.width == e.width && this.height == e.height
    }, t.prototype.clone = function () {
      return new t(this.left, this.top, this.width, this.height)
    }, t.fromBoundingRect = function (e) {
      return null != e.left ? new t(e.left, e.top, e.width, e.height) : null != e.x ? new t(e.x, e.y, e.width, e.height) : void b(!1, "Invalid source rectangle.")
    }, t.union = function (e, n) {
      var r = Math.min(e.left, n.left),
        i = Math.min(e.top, n.top);
      return new t(r, i, Math.max(e.right, n.right) - r, Math.max(e.bottom, n.bottom) - i)
    }, t.intersection = function (e, n) {
      var r = Math.max(e.left, n.left),
        i = Math.max(e.top, n.top);
      return new t(r, i, Math.min(e.right, n.right) - r, Math.min(e.bottom, n.bottom) - i)
    }, t.prototype.contains = function (e) {
      if (e instanceof kt) return e.x >= this.left && e.x <= this.right && e.y >= this.top && e.y <= this.bottom;
      if (e instanceof t) {
        var n = e;
        return n.left >= this.left && n.right <= this.right && n.top >= this.top && n.bottom <= this.bottom
      }
      b(!1, "Point or Rect expected.")
    }, t.prototype.inflate = function (e, n) {
      return new t(this.left - e, this.top - n, this.width + 2 * e, this.height + 2 * n)
    }, t
  }();
  e.Rect = Ht;
  var Nt = function () {
    function t() {}
    return t.addDays = function (e, n) {
      return t.newDate(e.getFullYear(), e.getMonth(), e.getDate() + n)
    }, t.addMonths = function (e, n) {
      return t.newDate(e.getFullYear(), e.getMonth() + n, e.getDate())
    }, t.addYears = function (e, n) {
      return t.newDate(e.getFullYear() + n, e.getMonth(), e.getDate())
    }, t.addHours = function (e, n) {
      return t.newDate(e.getFullYear(), e.getMonth(), e.getDate(), e.getHours() + n)
    }, t.addMinutes = function (e, n) {
      return t.newDate(e.getFullYear(), e.getMonth(), e.getDate(), e.getHours(), e.getMinutes() + n)
    }, t.addSeconds = function (e, n) {
      return t.newDate(e.getFullYear(), e.getMonth(), e.getDate(), e.getHours(), e.getMinutes(), e.getSeconds() + n)
    }, t.sameDate = function (t, e) {
      return h(t) && h(e) && t.getFullYear() == e.getFullYear() && t.getMonth() == e.getMonth() && t.getDate() == e.getDate()
    }, t.sameTime = function (t, e) {
      return h(t) && h(e) && t.getHours() == e.getHours() && t.getMinutes() == e.getMinutes() && t.getSeconds() == e.getSeconds()
    }, t.equals = function (t, e) {
      return h(t) && h(e) && t.getTime() == e.getTime()
    }, t.fromDateTime = function (e, n) {
      return e || n ? (e || (e = n), n || (n = e), t.newDate(e.getFullYear(), e.getMonth(), e.getDate(), n.getHours(), n.getMinutes(), n.getSeconds(), n.getMilliseconds())) : null
    }, t.toFiscal = function (n, r) {
      var i = e.culture.Globalize.calendar;
      return p(i.fiscalYearOffsets) ? t.addMonths(n, -i.fiscalYearOffsets[r ? 0 : 1]) : n
    }, t.fromFiscal = function (n, r) {
      var i = e.culture.Globalize.calendar;
      return p(i.fiscalYearOffsets) ? t.addMonths(n, +i.fiscalYearOffsets[r ? 0 : 1]) : n
    }, t.newDate = function (t, e, n, r, i, o, s) {
      if (null == t || null == e || null == n) {
        var a = new Date;
        null == t && (t = a.getFullYear()), null == e && (e = a.getMonth()), null == n && (n = a.getDate())
      }
      null == r && (r = 0), null == i && (i = 0), null == o && (o = 0), null == s && (s = 0);
      var u = new Date(t, e, n, r, i, o, s),
        c = u.getFullYear();
      return t < 100 && c >= 1900 && u.setFullYear(u.getFullYear() - 1900), u
    }, t.clone = function (e) {
      return t.fromDateTime(e, e)
    }, t
  }();
  e.DateTime = Nt, e.httpRequest = K, e.culture = "undefined" != typeof window && window.wijmo.culture || {
    Globalize: {
      numberFormat: {
        ".": ".",
        ",": ",",
        "-": "-",
        "+": "+",
        "%": "%",
        percent: {
          pattern: ["-n %", "n %"]
        },
        currency: {
          decimals: 2,
          symbol: "$",
          pattern: ["($n)", "$n"]
        }
      },
      calendar: {
        "/": "/",
        ":": ":",
        firstDay: 0,
        days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        daysAbbr: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        monthsAbbr: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        am: ["AM", "A"],
        pm: ["PM", "P"],
        eras: ["A.D.", "B.C."],
        patterns: {
          d: "M/d/yyyy",
          D: "dddd, MMMM dd, yyyy",
          f: "dddd, MMMM dd, yyyy h:mm tt",
          F: "dddd, MMMM dd, yyyy h:mm:ss tt",
          t: "h:mm tt",
          T: "h:mm:ss tt",
          M: "MMMM d",
          m: "MMMM d",
          Y: "MMMM, yyyy",
          y: "MMMM, yyyy",
          g: "M/d/yyyy h:mm tt",
          G: "M/d/yyyy h:mm:ss tt",
          s: 'yyyy"-"MM"-"dd"T"HH":"mm":"ss',
          o: 'yyyy"-"MM"-"dd"T"HH":"mm":"ss"."fffffffK',
          O: 'yyyy"-"MM"-"dd"T"HH":"mm":"ss"."fffffffK',
          U: "dddd, MMMM dd, yyyy h:mm:ss tt"
        },
        fiscalYearOffsets: [-3, -3]
      }
    }
  };
  var Bt = function () {
    function t() {}
    return t.format = function (e, n, r, i) {
      return s(e) ? e : a(e) ? (n = n || (e == Math.round(e) ? "n0" : "n2"), t.formatNumber(e, n, r, i)) : h(e) ? (n = n || "d", t.formatDate(e, n)) : null != e ? e.toString() : ""
    }, t.formatNumber = function (n, r, i, o) {
      n = C(n), r = w(r);
      var s, a = e.culture.Globalize.numberFormat,
        u = r ? r.match(/([a-z])(\d*)(,*)(.*)/i) : null,
        c = u ? u[1].toLowerCase() : "n",
        l = u && u[2] ? parseInt(u[2]) : "c" == c ? a.currency.decimals : n == Math.round(n) ? 0 : 2,
        h = u && u[3] ? 3 * u[3].length : 0,
        p = a["."] || ".",
        f = a[","] || ",",
        d = a["-"] || "-";
      if (h && (n /= Math.pow(10, h)), "d" == c || "x" == c) {
        for (s = Math.round(Math.abs(n)).toString("d" == c ? 10 : 16); s.length < l;) s = "0" + s;
        return n < 0 && (s = d + s), r && "X" == r[0] && (s = s.toUpperCase()), s
      }
      if ("p" == c && (n = _(n = t._mul100(n), l, o)), o && "p" != c && (n = _(n, l, !0)), s = t._toFixedStr("c" == c || "p" == c ? Math.abs(n) : n, l), (i || "g" == c) && s.indexOf(".") > -1 && (s = (s = s.replace(/(\.[0-9]*?)0+$/g, "$1")).replace(/\.$/, "")), "." != p && (s = s.replace(".", p)), "-" != d && (s = s.replace("-", d)), f && ("n" == c || "c" == c || "p" == c)) {
        var g = s.indexOf(p),
          m = /\B(?=(\d\d\d)+(?!\d))/g;
        s = g > -1 ? s.substr(0, g).replace(m, f) + s.substr(g) : s.replace(m, f)
      }
      if ("c" == c) {
        var v = a.currency.pattern[n < 0 ? 0 : 1],
          y = u && u[4] ? u[4] : a.currency.symbol;
        "​" == y && (y = ""), s = v.replace("n", s).replace("$", y)
      }
      if ("p" == c) {
        var v = a.percent.pattern[n < 0 ? 0 : 1],
          b = a["%"] || "%";
        s = v.replace("n", s), "%" != b && (s = s.replace("%", b)), "-" != d && n < 0 && (s = s.replace("-", d))
      }
      return s
    }, t.formatDate = function (e, n) {
      switch (e = x(e), n) {
        case "r":
        case "R":
          return e.toUTCString();
        case "u":
          return e.toISOString().replace(/\.\d{3}/, "")
      }
      n = t._expandFormat(n);
      for (var r = t._parseDateFormat(n), i = "", o = 0; o < r.length; o++) i += t._formatDatePart(e, n, r[o]);
      return i
    }, t.parseInt = function (e, n) {
      return Math.round(t.parseFloat(e, n))
    }, t.parseFloat = function (t, n) {
      var r = e.culture.Globalize.numberFormat,
        i = r["-"] || "-",
        o = r["%"] || "%",
        s = t.indexOf(i) > -1 || t.indexOf("(") > -1 && t.indexOf(")") > -1 ? -1 : 1,
        a = t.indexOf(o) > -1 ? .01 : 1,
        u = n ? n.match(/,+/) : null,
        c = u ? 3 * u[0].length : 0,
        l = 0;
      if (!n || "x" != n[0] && "X" != n[0]) {
        var h = r["."] || ".",
          p = new RegExp("[^\\d\\" + h + "]", "g");
        t = t.replace(p, "").replace(h, "."), l = parseFloat(t)
      } else t = t.replace(/[^0-9a-f]+.*$/gi, ""), l = parseInt(t, 16);
      return l * s * a * Math.pow(10, c)
    }, t.parseDate = function (n, r, i) {
      if (!(n = w(n))) return null;
      if ("u" == r) return new Date(n);
      var o;
      if ("R" == r || "r" == r) {
        var s = /(([0-9]+)\-([0-9]+)\-([0-9]+))?\s?(([0-9]+):([0-9]+)(:([0-9]+))?)?/,
          u = n.match(s);
        return u[1] || u[5] ? (o = u[1] ? new Date(parseInt(u[2]), parseInt(u[3]) - 1, parseInt(u[4])) : new Date, u[5] && (o.setHours(parseInt(u[6])), o.setMinutes(parseInt(u[7])), o.setSeconds(u[8] ? parseInt(u[9]) : 0))) : o = new Date(n), isNaN(o.getTime()) ? null : o
      }
      r = t._expandFormat(r || "d");
      var c, l, f, d, g, _, m = e.culture.Globalize.calendar,
        v = t._CJK,
        y = new RegExp("(\\" + m["/"] + ")|(\\" + m[":"] + ")|(\\d+)|([" + v + "\\.]{2,})|([" + v + "]+)", "gi"),
        b = n.match(y),
        C = t._parseDateFormat(r),
        E = 0,
        A = -1,
        x = 0,
        M = 1,
        T = 0,
        O = 0,
        I = 0,
        P = 0,
        S = -1;
      if (!(b && b.length && C && C.length)) return null;
      for (var D = 0; D < C.length && b; D++) {
        var F = D - E,
          L = F > -1 && F < b.length ? b[F] : "",
          j = C[D].length;
        switch (C[D]) {
          case "EEEE":
          case "EEE":
          case "EE":
          case "E":
          case "eeee":
          case "eee":
          case "ee":
          case "e":
            _ = C[D];
          case "yyyy":
          case "yyy":
          case "yy":
          case "y":
            j > 1 && L.length > j && (b[F] = L.substr(j), L = L.substr(0, j), E++), A = parseInt(L), g = 4 == L.length;
            break;
          case "MMMM":
          case "MMM":
            d = !0;
            var k = L.toLowerCase();
            x = -1;
            for (var R = 0; R < 12; R++)
              if (0 == m.months[R].toLowerCase().indexOf(k)) {
                x = R;
                break
              } if (x > -1) break;
          case "MM":
          case "M":
            d = !0, j > 1 && L.length > j && (b[F] = L.substr(j), L = L.substr(0, j), E++), x = parseInt(L) - 1;
            break;
          case "dddd":
          case "ddd":
            c = !0;
            break;
          case "dd":
          case "d":
            j > 1 && L.length > j && (b[F] = L.substr(j), L = L.substr(0, j), E++), M = parseInt(L), l = !0;
            break;
          case "hh":
          case "h":
            j > 1 && L.length > j && (b[F] = L.substr(j), L = L.substr(0, j), E++), T = 12 == (T = parseInt(L)) ? 0 : T;
            break;
          case "HH":
            j > 1 && L.length > j && (b[F] = L.substr(j), L = L.substr(0, j), E++), T = parseInt(L);
            break;
          case "H":
            T = parseInt(L);
            break;
          case "mm":
          case "m":
            j > 1 && L.length > j && (b[F] = L.substr(j), L = L.substr(0, j), E++), O = parseInt(L);
            break;
          case "ss":
          case "s":
            j > 1 && L.length > j && (b[F] = L.substr(j), L = L.substr(0, j), E++), I = parseInt(L);
            break;
          case "fffffff":
          case "FFFFFFF":
          case "ffffff":
          case "FFFFFF":
          case "fffff":
          case "FFFFF":
          case "ffff":
          case "FFFF":
          case "fff":
          case "FFF":
          case "ff":
          case "FF":
          case "f":
          case "F":
            P = parseInt(L) / Math.pow(10, j - 3);
            break;
          case "tt":
          case "t":
            L = L.toUpperCase(), T < 12 && m.pm.indexOf(L) > -1 && (T += 12);
            break;
          case "q":
          case "Q":
          case "u":
          case "U":
            f = !0;
            break;
          case "ggg":
          case "gg":
          case "g":
            S = m.eras.length > 1 ? t._getEra(L, m) : -1;
            break;
          case m["/"]:
          case m[":"]:
            if (L && L != C[D]) return null;
            break;
          case "K":
            break;
          default:
            t._unquote(C[D]).trim() != L.trim() && E++
        }
      }
      if (d && l && (isNaN(T) && (T = 0), isNaN(O) && (O = 0), isNaN(I) && (I = 0)), x < 0 || x > 11 || isNaN(x) || M < 1 || M > 31 || isNaN(M) || T < 0 || T > 24 || isNaN(T) || O < 0 || O > 60 || isNaN(O) || I < 0 || I > 60 || isNaN(I)) return null;
      if (_) {
        if (!d) return null;
        var H = e.culture.Globalize.calendar;
        if (p(H.fiscalYearOffsets)) {
          var N = "E" == _[0],
            B = x - H.fiscalYearOffsets[N ? 0 : 1];
          A += B > 11 ? -1 : B < 0 ? 1 : 0
        }
      }
      if (c && !l) return null;
      if (f && !d) return null;
      if (A < 0 && (A = h(i) ? i.getFullYear() : (new Date).getFullYear()), S > -1) A = A + m.eras[S].start.getFullYear() - 1;
      else if (A < 100 && !g) {
        var V = a(m.twoDigitYearMax) ? m.twoDigitYearMax : 2029;
        V > 99 && (A += A + 2e3 <= V ? 2e3 : 1900)
      }
      return M > new Date(A, x + 1, 0).getDate() ? null : (o = Nt.newDate(A, x, M, T, O + 0, I, P), isNaN(o.getTime()) ? null : o)
    }, t.getFirstDayOfWeek = function () {
      var t = e.culture.Globalize.calendar.firstDay;
      return t || 0
    }, t.getNumberDecimalSeparator = function () {
      var t = e.culture.Globalize.numberFormat["."];
      return t || "."
    }, t._toFixedStr = function (t, e) {
      var n = t.toString(),
        r = n.indexOf("."),
        i = e - (n.length - r) + 1;
      return n.indexOf("e") < 0 && r > -1 && i >= 0 ? n + Array(i + 1).join("0") : t.toFixed(e)
    }, t._unquote = function (t) {
      return t.length > 1 && t[0] == t[t.length - 1] && ("'" == t[0] || '"' == t[0]) ? t.substr(1, t.length - 2) : t
    }, t._parseDateFormat = function (e) {
      if (e in t._dateFormatParts) return t._dateFormatParts[e];
      var n, r, i = [];
      if (e)
        for (n = 0; n > -1 && n < e.length; n++) {
          var o = e[n];
          if (("'" == o || '"' == o) && (r = e.indexOf(o, n + 1)) > -1) i.push(e.substring(n, r + 1)), n = r;
          else if ("\\" == o && n < e.length - 1) n++, i.push('"' + e[n] + '"');
          else {
            for (r = n + 1; r < e.length && e[r] == o; r++);
            i.push(e.substring(n, r)), n = r - 1
          }
        }
      return t._dateFormatParts[e] = i, i
    }, t._formatDatePart = function (n, r, i) {
      var o, s = e.culture.Globalize.calendar,
        a = 0,
        u = 0,
        c = 0,
        l = i.length;
      switch (i) {
        case "yyyy":
        case "yyy":
        case "yy":
        case "y":
        case "EEEE":
        case "EEE":
        case "EE":
        case "E":
        case "eeee":
        case "eee":
        case "ee":
        case "e":
          u = (o = "E" == i[0] ? Nt.toFiscal(n, !0) : "e" == i[0] ? Nt.toFiscal(n, !1) : n).getFullYear(), s.eras.length > 1 && r.indexOf("g") > -1 && (a = t._getEra(n, s)) > -1 && (u = u - s.eras[a].start.getFullYear() + 1);
          var h = i.length < 3 ? u % 100 : 3 == i.length ? u % 1e3 : u;
          return t._zeroPad(h, i.length);
        case "MMMMM":
          return s.monthsAbbr[n.getMonth()][0];
        case "MMMM":
          return s.months[n.getMonth()];
        case "MMM":
          return s.monthsAbbr[n.getMonth()];
        case "MM":
        case "M":
          return t._zeroPad(n.getMonth() + 1, l);
        case "dddd":
          return s.days[n.getDay()];
        case "ddd":
          return s.daysAbbr[n.getDay()];
        case "dd":
          return t._zeroPad(n.getDate(), 2);
        case "d":
          return n.getDate().toString();
        case "hh":
        case "h":
          return t._zeroPad(t._h12(n), l);
        case "HH":
        case "H":
          return t._zeroPad(n.getHours(), l);
        case "mm":
        case "m":
          return t._zeroPad(n.getMinutes(), l);
        case "ss":
        case "s":
          return t._zeroPad(n.getSeconds(), l);
        case "fffffff":
        case "FFFFFFF":
        case "ffffff":
        case "FFFFFF":
        case "fffff":
        case "FFFFF":
        case "ffff":
        case "FFFF":
        case "fff":
        case "FFF":
        case "ff":
        case "FF":
        case "f":
        case "F":
          return c = n.getMilliseconds() * Math.pow(10, l - 3), "f" == i[0] ? t._zeroPad(c, l) : c.toFixed(0);
        case "tt":
          return n.getHours() < 12 ? s.am[0] : s.pm[0];
        case "t":
          return n.getHours() < 12 ? s.am[1] : s.pm[1];
        case "q":
        case "Q":
          return (Math.floor(n.getMonth() / 3) + 1).toString();
        case "u":
        case "U":
          return o = Nt.toFiscal(n, "U" == i), (Math.floor(o.getMonth() / 3) + 1).toString();
        case "ggg":
        case "gg":
        case "g":
          return s.eras.length > 1 && (a = t._getEra(n, s)) > -1 ? "ggg" == i ? s.eras[a].name : "gg" == i ? s.eras[a].name[0] : s.eras[a].symbol : s.eras[0];
        case ":":
        case "/":
          return s[i];
        case "K":
          var p = n.toString().match(/(\+|\-)(\d{2})(\d{2})/);
          return p ? p[1] + p[2] + p[3] : "";
        case "zzz":
        case "zz":
        case "z":
          var f = -n.getTimezoneOffset(),
            d = void 0;
          switch (i) {
            case "zzz":
              d = t.format(f / 60, "d2", !1, !0) + s[":"] + t.format(f % 60, "d2", !1, !0);
              break;
            case "zz":
              d = t.format(f / 60, "d2", !1, !0);
              break;
            case "z":
              d = t.format(f / 60, "d", !1, !0)
          }
          return f >= 0 ? "+" + d : d
      }
      return l > 1 && i[0] == i[l - 1] && ('"' == i[0] || "'" == i[0]) ? i.substr(1, l - 2) : i
    }, t._getEra = function (t, e) {
      if (h(t)) {
        for (n = 0; n < e.eras.length; n++)
          if (t >= e.eras[n].start) return n
      } else if (s(t))
        for (var n = 0; n < e.eras.length; n++)
          if (e.eras[n].name && (0 == e.eras[n].name.indexOf(t) || 0 == e.eras[n].symbol.indexOf(t))) return n;
      return -1
    }, t._expandFormat = function (t) {
      var n = e.culture.Globalize.calendar.patterns[t];
      return n || t
    }, t._zeroPad = function (t, e) {
      var n = t.toFixed(0),
        r = e - n.length + 1;
      return r > 0 ? Array(r).join("0") + n : n
    }, t._h12 = function (t) {
      var n = e.culture.Globalize.calendar,
        r = t.getHours();
      return n.am && n.am[0] && 0 == (r %= 12) && (r = 12), r
    }, t._mul100 = function (t) {
      var e = t.toString(),
        n = e.indexOf(".");
      return e.indexOf("e") > -1 ? 100 * t : (n < 0 ? e += "00" : (n += 2, e = (e = e.replace(".", "") + "00").substr(0, n) + "." + e.substr(n)), parseFloat(e))
    }, t._CJK = "a-z" + "u00c0-u017fu3000-u30ffu4e00-u9faf".replace(/u/g, "\\u") + "u1100-u11ffu3130-u318fua960-ua97fuac00-ud7afud7b0-ud7ff".replace(/u/g, "\\u"), t._dateFormatParts = {}, t
  }();
  e.Globalize = Bt, e._updateCulture = function () {
    e.culture = window.wijmo.culture
  }, e._addCultureInfo = function (t, n) {
    e.culture[t] = window.wijmo.culture[t] || n
  };
  var Vt = function () {
    function t(t) {
      this.path = t
    }
    return Object.defineProperty(t.prototype, "path", {
      get: function () {
        return this._path
      },
      set: function (t) {
        this._path = t, this._parts = t ? t.split(".") : [];
        for (var e = 0; e < this._parts.length; e++) {
          var n = this._parts[e],
            r = n.indexOf("[");
          r > -1 && (this._parts[e] = n.substr(0, r), this._parts.splice(++e, 0, parseInt(n.substr(r + 1))))
        }
        this._key = 1 == this._parts.length ? this._parts[0] : null
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.getValue = function (t) {
      if (t) {
        if (this._key) return t[this._key];
        if (this._path && this._path in t) return t[this._path];
        for (var e = 0; e < this._parts.length && t; e++) t = t[this._parts[e]]
      }
      return t
    }, t.prototype.setValue = function (t, e) {
      if (t) {
        if (this._path in t) return void(t[this._path] = e);
        for (var n = 0; n < this._parts.length - 1; n++)
          if (null == (t = t[this._parts[n]])) return;
        t[this._parts[this._parts.length - 1]] = e
      }
    }, t
  }();
  e.Binding = Vt;
  var zt = function () {
      return function (t, e) {
        this.handler = t, this.self = e
      }
    }(),
    Gt = function () {
      function t() {
        this._handlers = []
      }
      return t.prototype.addHandler = function (t, e) {
        t = M(t), this._handlers.push(new zt(t, e))
      }, t.prototype.removeHandler = function (t, e) {
        t = M(t);
        for (var n = 0; n < this._handlers.length; n++) {
          var r = this._handlers[n];
          if ((r.handler == t || null == t) && (r.self == e || null == e) && (this._handlers.splice(n, 1), t && e)) break
        }
      }, t.prototype.removeAllHandlers = function () {
        this._handlers.length = 0
      }, t.prototype.raise = function (t, e) {
        void 0 === e && (e = Ut.empty);
        for (var n = 0; n < this._handlers.length; n++) {
          var r = this._handlers[n];
          r.handler.call(r.self, t, e)
        }
      }, Object.defineProperty(t.prototype, "hasHandlers", {
        get: function () {
          return this._handlers.length > 0
        },
        enumerable: !0,
        configurable: !0
      }), Object.defineProperty(t.prototype, "handlerCount", {
        get: function () {
          return this._handlers.length
        },
        enumerable: !0,
        configurable: !0
      }), t
    }();
  e.Event = Gt;
  var Ut = function () {
    function t() {}
    return t.empty = new t, t
  }();
  e.EventArgs = Ut;
  var Wt = function (t) {
    function e() {
      var e = null !== t && t.apply(this, arguments) || this;
      return e.cancel = !1, e
    }
    return __extends(e, t), e
  }(Ut);
  e.CancelEventArgs = Wt;
  var Yt = function (t) {
    function e(e, n, r) {
      var i = t.call(this) || this;
      return i._name = e, i._oldVal = n, i._newVal = r, i
    }
    return __extends(e, t), Object.defineProperty(e.prototype, "propertyName", {
      get: function () {
        return this._name
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(e.prototype, "oldValue", {
      get: function () {
        return this._oldVal
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(e.prototype, "newValue", {
      get: function () {
        return this._newVal
      },
      enumerable: !0,
      configurable: !0
    }), e
  }(Ut);
  e.PropertyChangedEventArgs = Yt;
  var Kt = function (t) {
    function e(e, n) {
      var r = t.call(this) || this;
      return r._xhr = e, r._msg = n, r
    }
    return __extends(e, t), Object.defineProperty(e.prototype, "request", {
      get: function () {
        return this._xhr
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(e.prototype, "message", {
      get: function () {
        return this._msg
      },
      set: function (t) {
        this._msg = t
      },
      enumerable: !0,
      configurable: !0
    }), e
  }(Wt);
  e.RequestErrorEventArgs = Kt, e.controlBaseClass = "undefined" != typeof window && window["wj-control-is-element"] ? HTMLElement : Object;
  var qt = !1;
  try {
    var Jt = function () {
      return function () {}
    }();
    Jt.prototype = Array.prototype, qt = Jt.prototype !== Array.prototype
  } catch (t) {
    qt = !0
  }
  var Xt = qt || e.controlBaseClass !== Object,
    Zt = function (t) {
      function e() {
        var e = this;
        return Xt && (e = t.call(this) || this), e
      }
      return __extends(e, t), e
    }(e.controlBaseClass);
  e.ControlBase = Zt;
  var $t = function (t) {
    function e(n, r, i) {
      void 0 === r && (r = null), void 0 === i && (i = !1);
      var o = t.call(this) || this;
      o._pristine = !0, o._focus = !1, o._updating = 0, o._fullUpdate = !1, o.gotFocus = new Gt, o.lostFocus = new Gt, o.refreshing = new Gt, o.refreshed = new Gt, o._updateWme(), b(null == e.getControl(n), "Element is already hosting a control.");
      var s = B(n);
      if (b(null != s, "Cannot find the host element."), o._orgTabIndex = s.hasAttribute("tabindex") ? s.tabIndex : 0, o._orgOuter = s.outerHTML, o._orgTag = s.tagName, o._orgAtts = s.attributes, "INPUT" != s.tagName && "SELECT" != s.tagName || (s = o._replaceWithDiv(s)), o._e = s, s[e._CTRL_KEY] = o, 1 == i) {
        o._szCtl = new Rt(s.offsetWidth, s.offsetHeight);
        var a = o._handleResize.bind(o);
        o.addEventListener(window, "resize", a)
      }
      var u;
      o.addEventListener(s, "focus", function (t) {
        u && clearTimeout(u), u = setTimeout(function () {
          u = null, o._updateFocusState()
        }, e._FOCUS_INTERVAL)
      }, !0), o.addEventListener(s, "blur", function (t) {
        u && clearTimeout(u), u = setTimeout(function () {
          u = null, o._updateFocusState()
        }, e._FOCUS_INTERVAL)
      }, !0);
      var c = o._handleDisabled.bind(o),
        l = o.addEventListener.bind(o);
      if (l(s, "mousedown", c, !0), l(s, "mouseup", c, !0), l(s, "click", c, !0), l(s, "dblclick", c, !0), l(s, "keydown", c, !0), l(s, "wheel", c, It(!0, !0)), null == e._touching && (e._touching = !1, "ontouchstart" in window || "onpointerdown" in window)) {
        var h = document.body,
          p = h.addEventListener.bind(h),
          f = o._handleTouchStart,
          d = o._handleTouchEnd,
          g = It(!0, !0);
        "ontouchstart" in window ? (p("touchstart", f, g), p("touchend", d, g), p("touchcancel", d, g), p("touchleave", d, g)) : "onpointerdown" in window && (p("pointerdown", f, g), p("pointerup", d, g), p("pointerout", d, g), p("pointercancel", d, g), p("pointerleave", d, g))
      }
      return o
    }
    return __extends(e, t), e.prototype.getTemplate = function () {
      for (var t = Object.getPrototypeOf(this); t; t = Object.getPrototypeOf(t)) {
        var e = t.constructor.controlTemplate;
        if (e) return e
      }
      return null
    }, e.prototype.applyTemplate = function (t, n, r, i) {
      var o = this,
        s = this._e;
      t && S(s, t);
      var a = null;
      n && (a = V(n, s));
      for (var u = s.querySelectorAll("a.wj-btn"), c = 0; c < u.length; c++) {
        var l = u[c];
        L(l, "role", "button", !0), L(l, "href", "", !0), L(l, "draggable", !1, !0)
      }
      for (var h = s.querySelectorAll("button"), c = 0; c < h.length; c++) L(h[c], "type", "button", !0);
      var p = s.querySelectorAll("input"),
        f = 1 == p.length ? p[0] : null;
      if (f) {
        var g = e._rxInputAtts;
        this._copyAttributes(f, s.attributes, g), this._copyAttributes(f, this._orgAtts, g)
      }
      if (f && s.id) {
        for (var _ = s; _.parentElement;) _ = _.parentElement;
        var m = _.querySelector('label[for="' + s.id + '"]');
        if (m instanceof HTMLLabelElement) {
          var v = d(s.id + "_input");
          f.id = v, m.htmlFor = v
        }
      }
      if (f) {
        var y = document.createEvent("HTMLEvents"),
          b = f.value;
        y.initEvent("change", !0, !1), this.addEventListener(f, "input", function () {
          o._pristine = !1, b = f.value
        }, !0), this.gotFocus.addHandler(function () {
          b = f.value
        }), this.lostFocus.addHandler(function () {
          o._pristine && (o._pristine = !1, o._updateState()), b != f.value && f.dispatchEvent(y)
        })
      }
      if (f ? s.tabIndex = -1 : s.getAttribute("tabindex") || (s.tabIndex = 0), this._updateState(), r)
        for (var w in r) {
          var C = r[w];
          if (this[w] = a.querySelector('[wj-part="' + C + '"]'), null == this[w] && a.getAttribute("wj-part") == C && (this[w] = a), null == this[w]) throw 'Missing template part: "' + C + '"';
          if (C == i) {
            var E = "name",
              A = s.attributes[E];
            A && A.value && this[w].setAttribute(E, A.value), E = "accesskey", (A = s.attributes[E]) && A.value && (this[w].setAttribute(E, A.value), s.removeAttribute(E))
          }
        }
      return a
    }, e.prototype.dispose = function () {
      for (var t = this._e.querySelectorAll(".wj-control"), n = 0; n < t.length; n++) {
        var r = e.getControl(t[n]);
        r && r.dispose()
      }
      this._toInv && (clearTimeout(this._toInv), this._toInv = null), this.removeEventListener();
      for (var i in this) i.length > 2 && 0 == i.indexOf("on") && (s = this[i[2].toLowerCase() + i.substr(3)]) instanceof Gt && s.removeAllHandlers();
      var o = this.collectionView;
      if (o instanceof le)
        for (var i in o) {
          var s = o[i];
          s instanceof Gt && s.removeHandler(null, this)
        }
      this._e.parentNode && (this._e.outerHTML = this._orgOuter), this._e[e._CTRL_KEY] = null, this._e = this._orgOuter = this._orgTag = null
    }, e.getControl = function (t) {
      var n = B(t);
      return n ? O(n[e._CTRL_KEY], e, !0) : null
    }, Object.defineProperty(e.prototype, "hostElement", {
      get: function () {
        return this._e
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(e.prototype, "rightToLeft", {
      get: function () {
        return null == this._rtlDir && (this._rtlDir = !!this._e && "rtl" == getComputedStyle(this._e).direction), this._rtlDir
      },
      enumerable: !0,
      configurable: !0
    }), e.prototype.focus = function () {
      var t = this._e;
      t && (t.tabIndex < 0 ? H(t, 0) : t.focus())
    }, e.prototype.containsFocus = function () {
      var t = this._e,
        n = R();
      if (!t) return !1;
      for (var r = n; r;) {
        if (r == t) return !0;
        r = r[e._OWNR_KEY] || r.parentElement
      }
      return !1
    }, e.prototype.invalidate = function (t) {
      var n = this;
      void 0 === t && (t = !0), this._rtlDir = null, this._fullUpdate = this._fullUpdate || t, this._toInv && (clearTimeout(this._toInv), this._toInv = null), this.isUpdating || (this._toInv = setTimeout(function () {
        n.refresh(n._fullUpdate), n._toInv = null
      }, e._REFRESH_INTERVAL))
    }, e.prototype.refresh = function (t) {
      var e = this;
      void 0 === t && (t = !0);
      var n = this.hostElement;
      n && (this._rtlDir = "rtl" == getComputedStyle(n).direction, F(n, "wj-rtl", this._rtlDir)), this.isUpdating || (this.onRefreshing(), setTimeout(function () {
        e.onRefreshed()
      })), !this.isUpdating && this._toInv && (clearTimeout(this._toInv), this._toInv = null, this._fullUpdate = !1, this._updateWme())
    }, e.invalidateAll = function (t) {
      if (t || (t = document.body), t.children)
        for (var n = 0; n < t.children.length; n++) e.invalidateAll(t.children[n]);
      var r = e.getControl(t);
      r && r.invalidate()
    }, e.refreshAll = function (t) {
      if (t || (t = document.body), t.children)
        for (var n = 0; n < t.children.length; n++) e.refreshAll(t.children[n]);
      var r = e.getControl(t);
      r && r.refresh()
    }, e.disposeAll = function (t) {
      var n = e.getControl(t);
      if (n) n.dispose();
      else if (t.children)
        for (var r = 0; r < t.children.length; r++) e.disposeAll(t.children[r])
    }, e.prototype.beginUpdate = function () {
      this._updating++
    }, e.prototype.endUpdate = function () {
      this._updating--, this._updating <= 0 && this.invalidate()
    }, Object.defineProperty(e.prototype, "isUpdating", {
      get: function () {
        return this._updating > 0
      },
      enumerable: !0,
      configurable: !0
    }), e.prototype.deferUpdate = function (t) {
      try {
        this.beginUpdate(), t()
      } finally {
        this.endUpdate()
      }
    }, Object.defineProperty(e.prototype, "isTouching", {
      get: function () {
        return e._touching
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(e.prototype, "isDisabled", {
      get: function () {
        return this._e && null != this._e.getAttribute("disabled")
      },
      set: function (t) {
        (t = A(t, !0)) != this.isDisabled && U(this._e, !t)
      },
      enumerable: !0,
      configurable: !0
    }), e.prototype.initialize = function (t) {
      t && y(this, t)
    }, e.prototype.addEventListener = function (t, e, n, r) {
      void 0 === r && (r = !1), t && (t.addEventListener(e, n, r), null == this._listeners && (this._listeners = []), this._listeners.push({
        target: t,
        type: e,
        fn: n,
        capture: r
      }))
    }, e.prototype.removeEventListener = function (t, e, n, r) {
      var i = 0;
      if (this._listeners)
        for (var o = 0; o < this._listeners.length; o++) {
          var s = this._listeners[o];
          null != t && t != s.target || null != e && e != s.type || (null == n || n == s.fn || n && s.fn && n.toString() == s.fn.toString()) && (null != r && r != s.capture || (s.target.removeEventListener(s.type, s.fn, s.capture), this._listeners.splice(o, 1), o--, i++))
        }
      return i
    }, e.prototype.onGotFocus = function (t) {
      this.gotFocus.raise(this, t)
    }, e.prototype.onLostFocus = function (t) {
      this.lostFocus.raise(this, t)
    }, e.prototype.onRefreshing = function (t) {
      this.refreshing.raise(this, t)
    }, e.prototype.onRefreshed = function (t) {
      this.refreshed.raise(this, t)
    }, e.prototype._getProductInfo = function () {
      return "B0C3,Control"
    }, e.prototype._updateWme = function () {
      var t = window.wijmo._updateWme;
      l(t) && t(this, e._licKey)
    }, e.prototype._hasPendingUpdates = function () {
      return null != this._toInv
    }, e.prototype._handleResize = function () {
      if (this._e.parentElement) {
        var t = new Rt(this._e.offsetWidth, this._e.offsetHeight);
        t.equals(this._szCtl) || (this._szCtl = t, this.invalidate())
      }
    }, e.prototype._updateFocusState = function () {
      setTimeout(function () {
        for (var t = Ut.empty, n = document.body.querySelectorAll(".wj-state-focused"), r = 0; r < n.length; r++)(a = e.getControl(n[r])) && a._focus && !a.containsFocus() && (D(a._e, "wj-state-focus"), a._focus = !1, a._updateState(), a.onLostFocus(t));
        var i = R();
        if (i)
          for (var o = !0, s = i; s;) {
            var a = e.getControl(s);
            a && !a._focus && a.containsFocus() && (o && (S(s, "wj-state-focus"), o = !1), a._focus = !0, a._updateState(), a.onGotFocus(t)), s = s[e._OWNR_KEY] || s.parentElement
          }
      })
    }, e.prototype._updateState = function () {
      var t = this.hostElement;
      if (t) {
        F(t, "wj-state-focused", this._focus);
        var e = t.querySelector("input");
        if (e instanceof HTMLInputElement) {
          F(t, "wj-state-empty", 0 == e.value.length), F(t, "wj-state-readonly", e.readOnly);
          var n = e.validationMessage;
          F(t, "wj-state-invalid", !this._pristine && null != n && n.length > 0)
        }
      }
    }, e.prototype._handleTouchStart = function (t) {
      null != t.pointerType && "touch" != t.pointerType || (e._touching = !0)
    }, e.prototype._handleTouchEnd = function (t) {
      null != t.pointerType && "touch" != t.pointerType || setTimeout(function () {
        e._touching = !1
      }, 800)
    }, e.prototype._handleDisabled = function (t) {
      this.isDisabled && (t.preventDefault(), t.stopPropagation(), t.stopImmediatePropagation())
    }, e.prototype._replaceWithDiv = function (t) {
      var e = document.createElement("div");
      return t.parentElement.replaceChild(e, t), e.innerHTML = t.innerHTML, this._copyAttributes(e, t.attributes, /id|style|class/i), e
    }, e.prototype._copyAttributes = function (t, e, n) {
      if (t)
        for (var r = 0; r < e.length; r++) {
          var i = e[r].name;
          i.match(n) && t.setAttribute(i, e[r].value)
        }
    }, e._REFRESH_INTERVAL = 10, e._FOCUS_INTERVAL = 0, e._ANIM_DEF_DURATION = 400, e._ANIM_DEF_STEP = 35, e._CLICK_DELAY = 800, e._CLICK_REPEAT = 75, e._CLIPBOARD_DELAY = 100, e._POPUP_ZINDEX = 1500, e._SEARCH_DELAY = 600, e._DRAG_SCROLL_EDGE = 15, e._DRAG_SCROLL_STEP = 10, e._CTRL_KEY = "$WJ-CTRL", e._OWNR_KEY = "$WJ-OWNR", e._SCRL_KEY = "$WJ-SCRL", e._rxInputAtts = /name|tabindex|placeholder|autofocus|autocomplete|autocorrect|autocapitalize|spellcheck|readonly|minlength|maxlength|pattern|type/i, e
  }(Zt);
  e.Control = $t;
  var Qt;
  ! function (t) {
    t[t.None = 0] = "None", t[t.Sum = 1] = "Sum", t[t.Cnt = 2] = "Cnt", t[t.Avg = 3] = "Avg", t[t.Max = 4] = "Max", t[t.Min = 5] = "Min", t[t.Rng = 6] = "Rng", t[t.Std = 7] = "Std", t[t.Var = 8] = "Var", t[t.StdPop = 9] = "StdPop", t[t.VarPop = 10] = "VarPop", t[t.CntAll = 11] = "CntAll", t[t.First = 12] = "First", t[t.Last = 13] = "Last"
  }(Qt = e.Aggregate || (e.Aggregate = {})), e.getAggregate = q;
  var te;
  ! function (t) {
    t[t.Add = 0] = "Add", t[t.Remove = 1] = "Remove", t[t.Change = 2] = "Change", t[t.Reset = 3] = "Reset"
  }(te = e.NotifyCollectionChangedAction || (e.NotifyCollectionChangedAction = {}));
  var ee = function (t) {
    function e(e, n, r) {
      void 0 === e && (e = te.Reset), void 0 === n && (n = null), void 0 === r && (r = -1);
      var i = t.call(this) || this;
      return i.action = e, i.item = n, i.index = r, i
    }
    return __extends(e, t), e.reset = new e(te.Reset), e
  }(Ut);
  e.NotifyCollectionChangedEventArgs = ee;
  var ne = function () {
    function t(t, e) {
      this._bnd = new Vt(t), this._asc = e
    }
    return Object.defineProperty(t.prototype, "property", {
      get: function () {
        return this._bnd.path
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "ascending", {
      get: function () {
        return this._asc
      },
      enumerable: !0,
      configurable: !0
    }), t
  }();
  e.SortDescription = ne;
  var re = function (t) {
    function e(e) {
      var n = t.call(this) || this;
      return n.newPageIndex = e, n
    }
    return __extends(e, t), e
  }(Wt);
  e.PageChangingEventArgs = re;
  var ie = function () {
    function t() {}
    return t.prototype.groupNameFromItem = function (t, e) {
      return ""
    }, t.prototype.namesMatch = function (t, e) {
      return t === e
    }, t
  }();
  e.GroupDescription = ie;
  var oe = function (t) {
    function e(e, n) {
      var r = t.call(this) || this;
      return r._bnd = new Vt(e), r._converter = n, r
    }
    return __extends(e, t), Object.defineProperty(e.prototype, "propertyName", {
      get: function () {
        return this._bnd.path
      },
      enumerable: !0,
      configurable: !0
    }), e.prototype.groupNameFromItem = function (t, e) {
      return this._converter ? this._converter(t, this.propertyName) : this._bnd.getValue(t)
    }, e.prototype.namesMatch = function (t, e) {
      return t === e
    }, e
  }(ie);
  e.PropertyGroupDescription = oe;
  var se = function (t) {
    function e() {
      var e = this;
      return ae ? e.length = 0 : e = t.call(this) || this, e
    }
    return __extends(e, t), e
  }(Array);
  e.ArrayBase = se;
  var ae = !0;
  try {
    se.prototype = Array.prototype, ae = se.prototype === Array.prototype
  } catch (t) {
    ae = !1
  }
  var ue = "undefined" != typeof window ? window.Symbol : null;
  !ae && ue && ue.species && Object.defineProperty(se, ue.species, {
    get: function () {
      return Array
    },
    enumerable: !1,
    configurable: !1
  });
  var ce = function (t) {
    function e(e) {
      var n = t.call(this) || this;
      if (n._updating = 0, n.collectionChanged = new Gt, e) {
        e = T(e), n._updating++;
        for (var r = 0; r < e.length; r++) n.push(e[r]);
        n._updating--
      }
      return n
    }
    return __extends(e, t), e.prototype.push = function () {
      for (var e = [], n = 0; n < arguments.length; n++) e[n] = arguments[n];
      for (var r = this.length, i = 0; e && i < e.length; i++) r = t.prototype.push.call(this, e[i]), this._updating || this._raiseCollectionChanged(te.Add, e[i], r - 1);
      return r
    }, e.prototype.pop = function () {
      var e = t.prototype.pop.call(this);
      return this._raiseCollectionChanged(te.Remove, e, this.length), e
    }, e.prototype.splice = function (e, n, r) {
      var i, o = te;
      return n && r ? (i = t.prototype.splice.call(this, e, n, r), 1 == n ? this._raiseCollectionChanged(o.Change, r, e) : this._raiseCollectionChanged(), i) : r ? (i = t.prototype.splice.call(this, e, 0, r), this._raiseCollectionChanged(o.Add, r, e), i) : (i = t.prototype.splice.call(this, e, n), 1 == n ? this._raiseCollectionChanged(o.Remove, i[0], e) : this._raiseCollectionChanged(), i)
    }, e.prototype.slice = function (e, n) {
      return t.prototype.slice.call(this, e, n)
    }, e.prototype.indexOf = function (e, n) {
      return t.prototype.indexOf.call(this, e, n)
    }, e.prototype.sort = function (e) {
      var n = t.prototype.sort.call(this, e);
      return this._raiseCollectionChanged(), n
    }, e.prototype.insert = function (t, e) {
      this.splice(t, 0, e)
    }, e.prototype.remove = function (t) {
      var e = this.indexOf(t);
      return e > -1 && (this.removeAt(e), !0)
    }, e.prototype.removeAt = function (t) {
      this.splice(t, 1)
    }, e.prototype.setAt = function (t, e) {
      t > this.length && (this.length = t), this.splice(t, 1, e)
    }, e.prototype.clear = function () {
      this.length && this.splice(0, this.length)
    }, e.prototype.beginUpdate = function () {
      this._updating++
    }, e.prototype.endUpdate = function () {
      this._updating > 0 && (this._updating--, 0 == this._updating && this._raiseCollectionChanged())
    }, Object.defineProperty(e.prototype, "isUpdating", {
      get: function () {
        return this._updating > 0
      },
      enumerable: !0,
      configurable: !0
    }), e.prototype.deferUpdate = function (t) {
      try {
        this.beginUpdate(), t()
      } finally {
        this.endUpdate()
      }
    }, e.prototype.implementsInterface = function (t) {
      return "INotifyCollectionChanged" == t
    }, e.prototype.onCollectionChanged = function (t) {
      void 0 === t && (t = ee.reset), this.isUpdating || this.collectionChanged.raise(this, t)
    }, e.prototype._raiseCollectionChanged = function (t, e, n) {
      if (void 0 === t && (t = te.Reset), !this.isUpdating) {
        var r = new ee(t, e, n);
        this.onCollectionChanged(r)
      }
    }, e
  }(se);
  e.ObservableArray = ce;
  var le = function () {
    function t(t, e) {
      var n = this;
      this._idx = -1, this._srtDsc = new ce, this._grpDesc = new ce, this._newItem = null, this._edtItem = null, this._pgSz = 0, this._pgIdx = 0, this._updating = 0, this._stableSort = !1, this._canFilter = !0, this._canGroup = !0, this._canSort = !0, this._canAddNew = !0, this._canCancelEdit = !0, this._canRemove = !0, this._canChangePage = !0, this._trackChanges = !1, this._chgAdded = new ce, this._chgRemoved = new ce, this._chgEdited = new ce, this.collectionChanged = new Gt, this.sourceCollectionChanging = new Gt, this.sourceCollectionChanged = new Gt, this.currentChanged = new Gt, this.currentChanging = new Gt, this.pageChanged = new Gt, this.pageChanging = new Gt, this._srtDsc.collectionChanged.addHandler(function () {
        n._srtDsc.forEach(function (t) {
          b(t instanceof ne, "sortDescriptions array must contain SortDescription objects.")
        }), n.canSort && n.refresh()
      }), this._grpDesc.collectionChanged.addHandler(function () {
        n._grpDesc.forEach(function (t) {
          b(t instanceof ie, "groupDescriptions array must contain GroupDescription objects.")
        }), n.canGroup && (n.currentEditItem || n.currentAddItem ? n.commitEdit() : n.refresh())
      }), this.sourceCollection = t || new ce, e && (this.beginUpdate(), y(this, e), this.endUpdate())
    }
    return t.prototype._copy = function (t, e) {
      switch (t) {
        case "sortDescriptions":
          this.sortDescriptions.clear();
          for (var n = T(e), r = 0; r < n.length; r++) s(o = n[r]) && (o = new ne(o, !0)), this.sortDescriptions.push(o);
          return !0;
        case "groupDescriptions":
          this.groupDescriptions.clear();
          for (var i = T(e), r = 0; r < i.length; r++) {
            var o = i[r];
            s(o) && (o = new oe(o)), this.groupDescriptions.push(o)
          }
          return !0;
        case "currentItem":
          return this.currentItem = e, !0
      }
      return !1
    }, Object.defineProperty(t.prototype, "newItemCreator", {
      get: function () {
        return this._itemCreator
      },
      set: function (t) {
        this._itemCreator = M(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "sortConverter", {
      get: function () {
        return this._srtCvt
      },
      set: function (t) {
        t != this._srtCvt && (this._srtCvt = M(t, !0))
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "sortComparer", {
      get: function () {
        return this._srtCmp
      },
      set: function (t) {
        t != this._srtCmp && (this._srtCmp = M(t, !0))
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "useStableSort", {
      get: function () {
        return this._stableSort
      },
      set: function (t) {
        this._stableSort = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.getAggregate = function (t, e, n) {
      return q(t, n ? this._pgView : this._view, e)
    }, Object.defineProperty(t.prototype, "trackChanges", {
      get: function () {
        return this._trackChanges
      },
      set: function (t) {
        this._trackChanges = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "itemsAdded", {
      get: function () {
        return this._chgAdded
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "itemsRemoved", {
      get: function () {
        return this._chgRemoved
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "itemsEdited", {
      get: function () {
        return this._chgEdited
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.clearChanges = function () {
      this._chgAdded.clear(), this._chgRemoved.clear(), this._chgEdited.clear()
    }, t.prototype.implementsInterface = function (t) {
      switch (t) {
        case "ICollectionView":
        case "IEditableCollectionView":
        case "IPagedCollectionView":
        case "INotifyCollectionChanged":
          return !0
      }
      return !1
    }, Object.defineProperty(t.prototype, "getError", {
      get: function () {
        return this._getError
      },
      set: function (t) {
        this._getError = M(t)
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.onCollectionChanged = function (t) {
      void 0 === t && (t = ee.reset), t.action != te.Change || this._committing || this._canceling || t.item == this.currentEditItem || t.item == this.currentAddItem || this._trackItemChanged(t.item), this.collectionChanged.raise(this, t)
    }, t.prototype._raiseCollectionChanged = function (t, e, n) {
      void 0 === t && (t = te.Reset);
      var r = new ee(t, e, n);
      this.onCollectionChanged(r)
    }, t.prototype._notifyItemChanged = function (t) {
      var e = new ee(te.Change, t, this.items.indexOf(t));
      this.onCollectionChanged(e)
    }, t.prototype.onSourceCollectionChanging = function (t) {
      return this.sourceCollectionChanging.raise(this, t), !t.cancel
    }, t.prototype.onSourceCollectionChanged = function (t) {
      this.sourceCollectionChanged.raise(this, t)
    }, Object.defineProperty(t.prototype, "canFilter", {
      get: function () {
        return this._canFilter
      },
      set: function (t) {
        this._canFilter = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "canGroup", {
      get: function () {
        return this._canGroup
      },
      set: function (t) {
        this._canGroup = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "canSort", {
      get: function () {
        return this._canSort
      },
      set: function (t) {
        this._canSort = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "currentItem", {
      get: function () {
        return this._pgView && this._idx > -1 && this._idx < this._pgView.length ? this._pgView[this._idx] : null
      },
      set: function (t) {
        this.moveCurrentTo(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "currentPosition", {
      get: function () {
        return this._idx
      },
      set: function (t) {
        this.moveCurrentToPosition(C(t))
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "filter", {
      get: function () {
        return this._filter
      },
      set: function (t) {
        this._filter != t && (this._filter = M(t), this.canFilter && this.refresh())
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "groupDescriptions", {
      get: function () {
        return this._grpDesc
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "groups", {
      get: function () {
        return this._groups
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "isEmpty", {
      get: function () {
        return !this._pgView || !this._pgView.length
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "sortDescriptions", {
      get: function () {
        return this._srtDsc
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "sourceCollection", {
      get: function () {
        return this._src
      },
      set: function (t) {
        if (t != this._src) {
          if (!this.onSourceCollectionChanging(new Wt)) return;
          var e = this.currentPosition;
          this.commitEdit(), null != this._ncc && this._ncc.collectionChanged.removeHandler(this._sourceChanged), this._src = T(t, !1), this._ncc = i(this._src, "INotifyCollectionChanged"), this._ncc && this._ncc.collectionChanged.addHandler(this._sourceChanged, this), this.clearChanges(), this.refresh(), this.moveCurrentToFirst(), this.onSourceCollectionChanged(), this.currentPosition < 0 && e > -1 && this.onCurrentChanged()
        }
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype._sourceChanged = function (t, e) {
      this._updating <= 0 && this.refresh()
    }, t.prototype.contains = function (t) {
      return this._pgView.indexOf(t) > -1
    }, t.prototype.moveCurrentTo = function (t) {
      return this.moveCurrentToPosition(this._pgView.indexOf(t))
    }, t.prototype.moveCurrentToFirst = function () {
      return this.moveCurrentToPosition(0)
    }, t.prototype.moveCurrentToLast = function () {
      return this.moveCurrentToPosition(this._pgView.length - 1)
    }, t.prototype.moveCurrentToPrevious = function () {
      return this._idx > 0 && this.moveCurrentToPosition(this._idx - 1)
    }, t.prototype.moveCurrentToNext = function () {
      return this.moveCurrentToPosition(this._idx + 1)
    }, t.prototype.moveCurrentToPosition = function (t) {
      if (t >= -1 && t < this._pgView.length && t != this._idx) {
        var e = new Wt;
        if (this.onCurrentChanging(e)) {
          var n = this._pgView[t];
          this._edtItem && n != this._edtItem ? this.commitEdit() : this._newItem && n != this._newItem && this.commitNew(), this._idx = t, this.onCurrentChanged()
        }
      }
      return this._idx == t
    }, t.prototype.refresh = function () {
      this._newItem || this._edtItem ? this._pendingRefresh = !0 : this._updating <= 0 && (this._performRefresh(), this.onCollectionChanged())
    }, t.prototype._performRefresh = function () {
      if (!(this._updating > 0)) {
        this._pendingRefresh = !1;
        var t = this.currentItem;
        this._view = this._src ? this._performFilter(this._src) : [], this.canSort && this._srtDsc.length > 0 && (this._view == this._src && (this._view = this._src.slice()), this._performSort(this._view)), this._groups = this.canGroup ? this._createGroups(this._view) : null, this._fullGroups = this._groups, this._groups && (this._view = this._mergeGroupItems(this._groups)), this._pgIdx = v(this._pgIdx, 0, this.pageCount - 1), this._pgView = this._getPageView(), this._groups && this.pageCount > 1 && (this._groups = this._createGroups(this._pgView), this._mergeGroupItems(this._groups));
        var e = this._pgView.indexOf(t);
        e < 0 && (e = Math.min(this._idx, this._pgView.length - 1)), this._idx = e, this._digest = this._getGroupsDigest(this.groups), this.currentItem !== t && this.onCurrentChanged()
      }
    }, t.prototype._performSort = function (t) {
      if (this._stableSort) {
        var e = t.map(function (t, e) {
            return {
              item: t,
              index: e
            }
          }),
          n = this._compareItems();
        e.sort(function (t, e) {
          var r = n(t.item, e.item);
          return 0 == r ? t.index - e.index : r
        });
        for (var r = 0; r < t.length; r++) t[r] = e[r].item
      } else t.sort(this._compareItems())
    }, t.prototype._compareItems = function () {
      var e = this._srtDsc,
        n = this._srtCvt,
        r = this._srtCmp,
        i = !0,
        o = 0;
      return function (s, a) {
        for (var u = 0; u < e.length; u++) {
          var c = e[u],
            l = c._bnd.getValue(s),
            h = c._bnd.getValue(a);
          if (n && (l = n(c, s, l, i), h = n(c, a, h, !1), i = !1), r && null != (o = r(l, h))) return c.ascending ? +o : -o;
          if (l !== l && (l = null), h !== h && (h = null), null != l && null == h) return -1;
          if (null == l && null != h) return 1;
          if ("string" == typeof l && "string" == typeof h) {
            var p = t._collator;
            if (p) {
              if (0 != (o = p.compare(l, h))) return c.ascending ? +o : -o;
              continue
            }
            var f = l.toLowerCase(),
              d = h.toLowerCase();
            f != d && (l = f, h = d)
          }
          if (0 != (o = l < h ? -1 : l > h ? 1 : 0)) return c.ascending ? +o : -o
        }
        return 0
      }
    }, t.prototype._performFilter = function (t) {
      return this.canFilter && this._filter ? t.filter(this._filter, this) : t
    }, t.prototype.onCurrentChanged = function (t) {
      void 0 === t && (t = Ut.empty), this.currentChanged.raise(this, t)
    }, t.prototype.onCurrentChanging = function (t) {
      return this.currentChanging.raise(this, t), !t.cancel
    }, Object.defineProperty(t.prototype, "items", {
      get: function () {
        return this._pgView
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.beginUpdate = function () {
      this._updating++
    }, t.prototype.endUpdate = function () {
      this._updating--, this._updating <= 0 && this.refresh()
    }, Object.defineProperty(t.prototype, "isUpdating", {
      get: function () {
        return this._updating > 0
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.deferUpdate = function (t) {
      try {
        this.beginUpdate(), t()
      } finally {
        this.endUpdate()
      }
    }, Object.defineProperty(t.prototype, "canAddNew", {
      get: function () {
        return this._canAddNew
      },
      set: function (t) {
        this._canAddNew = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "canCancelEdit", {
      get: function () {
        return this._canCancelEdit
      },
      set: function (t) {
        this._canCancelEdit = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "canRemove", {
      get: function () {
        return this._canRemove
      },
      set: function (t) {
        this._canRemove = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "currentAddItem", {
      get: function () {
        return this._newItem
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "currentEditItem", {
      get: function () {
        return this._edtItem
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "isAddingNew", {
      get: function () {
        return null != this._newItem
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "isEditingItem", {
      get: function () {
        return null != this._edtItem
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.editItem = function (t) {
      t != this._edtItem && this.moveCurrentTo(t) && (this.commitEdit(), this._edtItem = t, this._edtClone = {}, this._extend(this._edtClone, this._edtItem))
    }, t.prototype.commitEdit = function () {
      var t = this._edtItem,
        e = this._edtClone;
      if (null != t) {
        this._committing = !0;
        var n = this._sameContent(t, e),
          r = this._pendingRefresh;
        this._edtItem = null, this._edtClone = null;
        var i = this._pgView.indexOf(t),
          o = this._digest;
        this._performRefresh(), n || this._trackItemChanged(t), this._pgView.indexOf(t) != i || o != this._digest || r ? this._raiseCollectionChanged() : this._raiseCollectionChanged(te.Change, t, i), this.commitNew(), this._committing = !1
      }
    }, t.prototype.cancelEdit = function () {
      var t = this._edtItem;
      if (null != t) {
        if (this._edtItem = null, !this.canCancelEdit) return void b(!1, "cannot cancel edits (canCancelEdit == false).");
        var e = this._src.indexOf(t);
        e >= 0 && this._edtClone && (this._extend(this._src[e], this._edtClone), this._edtClone = null, this._canceling = !0, this._raiseCollectionChanged(te.Change, t, e), this._canceling = !1, this._pendingRefresh && (this._performRefresh(), this._raiseCollectionChanged()))
      }
    }, t.prototype.addNew = function () {
      if (arguments.length > 0 && b(!1, "addNew does not take any parameters, it creates the new items."), this.commitEdit(), !this.canAddNew) return b(!1, "cannot add items (canAddNew == false)."), null;
      var t = null,
        e = this.sourceCollection;
      if (null != (t = this.newItemCreator ? this.newItemCreator() : e && e.length ? new e[0].constructor : {})) {
        if (this._newItem = t, this._updating++, this._src.push(t), this._updating--, this._pgView != this._src && this._pgView.push(t), this.groups && this.groups.length) {
          var n = this.groups[this.groups.length - 1];
          for (n.items.push(t); n.groups && n.groups.length;)(n = n.groups[n.groups.length - 1]).items.push(t)
        }
        this._raiseCollectionChanged(te.Add, t, this._pgView.length - 1), this.moveCurrentTo(t)
      }
      return this._newItem
    }, t.prototype.commitNew = function () {
      var t = this._newItem;
      if (null != t) {
        this._newItem = null;
        var e = this._pgView.indexOf(t),
          n = this._digest;
        if (this._performRefresh(), this._trackChanges) {
          var r = this._chgEdited.indexOf(t);
          r > -1 && this._chgEdited.removeAt(r), this._chgAdded.indexOf(t) < 0 && this._chgAdded.push(t)
        }
        this._pgView.indexOf(t) == e && n == this._digest ? this._raiseCollectionChanged(te.Change, t, e) : this._raiseCollectionChanged()
      }
    }, t.prototype.cancelNew = function () {
      var t = this._newItem;
      null != t && this.remove(t)
    }, t.prototype.remove = function (t) {
      var e = t == this._newItem;
      if (e && (this._newItem = null), t == this._edtItem && this.cancelEdit(), this.canRemove) {
        var n = this._src.indexOf(t);
        if (n > -1) {
          var r = this.currentItem;
          this._updating++, this._src.splice(n, 1), this._updating--;
          var i = this._digest;
          if (this._performRefresh(), this._trackChanges) {
            var o = this._chgAdded.indexOf(t);
            o > -1 && this._chgAdded.removeAt(o);
            var s = this._chgEdited.indexOf(t);
            s > -1 && this._chgEdited.removeAt(s), this._chgRemoved.indexOf(t) < 0 && !e && o < 0 && this._chgRemoved.push(t)
          }
          var a = this.sortDescriptions.length > 0,
            u = this.pageSize > 0 && this._pgIdx > -1;
          a || u || i != this._getGroupsDigest(this.groups) ? this._raiseCollectionChanged() : this._raiseCollectionChanged(te.Remove, t, n), this.currentItem !== r && this.onCurrentChanged()
        }
      } else b(!1, "cannot remove items (canRemove == false).")
    }, t.prototype.removeAt = function (t) {
      t = E(t), this.remove(this._pgView[t])
    }, t.prototype._trackItemChanged = function (t) {
      if (this._trackChanges) {
        var e = this.sourceCollection;
        if (e && e.indexOf(t) > -1) {
          var n = this._chgEdited.indexOf(t),
            r = te.Change;
          if (n < 0 && this._chgAdded.indexOf(t) < 0) this._chgEdited.push(t);
          else if (n > -1) {
            i = new ee(r, t, n);
            this._chgEdited.onCollectionChanged(i)
          } else if ((n = this._chgAdded.indexOf(t)) > -1) {
            var i = new ee(r, t, n);
            this._chgAdded.onCollectionChanged(i)
          }
        }
      }
    }, t.prototype._extend = function (t, e) {
      for (var n in e) t[n] = e[n]
    }, t.prototype._sameContent = function (t, e) {
      b(null != e && null != t, "Two objects expected");
      for (var n in e)
        if (!this._sameValue(t[n], e[n])) return !1;
      for (var n in t)
        if (!this._sameValue(t[n], e[n])) return !1;
      return !0
    }, t.prototype._sameValue = function (t, e) {
      return t === e || Nt.equals(t, e)
    }, Object.defineProperty(t.prototype, "canChangePage", {
      get: function () {
        return this._canChangePage
      },
      set: function (t) {
        this._canChangePage = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "isPageChanging", {
      get: function () {
        return !1
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "itemCount", {
      get: function () {
        return this._pgView.length
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "pageIndex", {
      get: function () {
        return this._pgIdx
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "pageSize", {
      get: function () {
        return this._pgSz
      },
      set: function (t) {
        t != this._pgSz && (this._pgSz = E(t), this.refresh())
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "totalItemCount", {
      get: function () {
        return this._view.length
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "pageCount", {
      get: function () {
        return this.pageSize ? Math.ceil(this.totalItemCount / this.pageSize) : 1
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.moveToFirstPage = function () {
      return this.moveToPage(0)
    }, t.prototype.moveToLastPage = function () {
      return this.moveToPage(this.pageCount - 1)
    }, t.prototype.moveToPreviousPage = function () {
      return this.moveToPage(this.pageIndex - 1)
    }, t.prototype.moveToNextPage = function () {
      return this.moveToPage(this.pageIndex + 1)
    }, t.prototype.moveToPage = function (t) {
      var e = v(t, 0, this.pageCount - 1);
      if (e != this._pgIdx) {
        this.canChangePage || b(!1, "cannot change pages (canChangePage == false).");
        var n = new re(e);
        this.onPageChanging(n) && (this._pgIdx = e, this._pgView = this._getPageView(), this._idx = 0, this.groupDescriptions && 0 != this.groupDescriptions.length ? this.refresh() : this.onCollectionChanged(), this.onPageChanged())
      }
      return this._pgIdx == t
    }, t.prototype.onPageChanged = function (t) {
      void 0 === t && (t = Ut.empty), this.pageChanged.raise(this, t)
    }, t.prototype.onPageChanging = function (t) {
      return this.pageChanging.raise(this, t), !t.cancel
    }, t.prototype._getFullGroup = function (t) {
      var e = this._getGroupByPath(this._fullGroups, t.level, t._path);
      return null != e && (t = e), t
    }, t.prototype._getGroupByPath = function (t, e, n) {
      for (var r = 0; r < t.length; r++) {
        var i = t[r];
        if (i.level == e && i._path == n) return i;
        if (i.level < e && 0 == n.indexOf(i._path) && null != (i = this._getGroupByPath(i.groups, e, n))) return i
      }
      return null
    }, t.prototype._getPageView = function () {
      if (this.pageSize <= 0 || this._pgIdx < 0) return this._view;
      var t = this._pgSz * this._pgIdx,
        e = Math.min(t + this._pgSz, this._view.length);
      return this._view.slice(t, e)
    }, t.prototype._createGroups = function (t) {
      if (!this._grpDesc || !this._grpDesc.length) return null;
      for (var e = [], n = {}, r = null, i = 0; i < t.length; i++)
        for (var s = t[i], a = e, u = this._grpDesc.length, c = "", l = 0; l < u; l++) {
          var h = this._grpDesc[l],
            p = h.groupNameFromItem(s, l),
            f = l == u - 1;
          !(r = n[c]) && o(p) && (r = {}, n[c] = r);
          var d = this._getGroup(h, a, r, p, l, f);
          c += "/" + p, d._path = c, f && d.items.push(s), a = d.groups
        }
      return e
    }, t.prototype._getGroupsDigest = function (t) {
      for (var e = "", n = 0; null != t && n < t.length; n++) {
        var r = t[n];
        e += "{" + r.name + ":" + (r.items ? r.items.length : "*"), r.groups.length > 0 && (e += ",", e += this._getGroupsDigest(r.groups)), e += "}"
      }
      return e
    }, t.prototype._mergeGroupItems = function (t) {
      for (var e = [], n = 0; n < t.length; n++) {
        var r = t[n];
        if (!r._isBottomLevel)
          for (var i = this._mergeGroupItems(r.groups), o = 0, s = i.length; o < s; o++) r._items.push(i[o]);
        for (var o = 0, s = r._items.length; o < s; o++) e.push(r._items[o])
      }
      return e
    }, t.prototype._getGroup = function (t, e, n, r, i, s) {
      var a;
      if (n && o(r)) {
        if (a = n[r]) return a
      } else
        for (var u = 0; u < e.length; u++)
          if (t.namesMatch(e[u].name, r)) return e[u];
      var c = new he(t, r, i, s);
      return e.push(c), n && (n[r] = c), c
    }, t._collator = "undefined" != typeof window && window.Intl ? new Intl.Collator : null, t
  }();
  e.CollectionView = le;
  var he = function () {
    function t(t, e, n, r) {
      this._gd = t, this._name = e, this._level = n, this._isBottomLevel = r, this._groups = [], this._items = []
    }
    return Object.defineProperty(t.prototype, "name", {
      get: function () {
        return this._name
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "level", {
      get: function () {
        return this._level
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "isBottomLevel", {
      get: function () {
        return this._isBottomLevel
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "items", {
      get: function () {
        return this._items
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "groups", {
      get: function () {
        return this._groups
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "groupDescription", {
      get: function () {
        return this._gd
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.getAggregate = function (t, e, n) {
      var r = i(n, le);
      return q(t, (r ? r._getFullGroup(this) : this).items, e)
    }, t
  }();
  e.CollectionViewGroup = he;
  var pe = function () {
    function t(t) {
      this._showAutoTipBnd = this._showAutoTip.bind(this), this._hideAutoTipBnd = this._hideAutoTip.bind(this), this._html = !0, this._gap = 6, this._showAtMouse = !1, this._showDelay = 500, this._hideDelay = 0, this._tips = [], this.popup = new Gt, y(this, t)
    }
    return t.prototype.setTooltip = function (t, e) {
      t = B(t), e = this._getContent(e);
      var n = this._indexOf(t);
      n > -1 && (this._detach(t), this._tips.splice(n, 1)), e && (this._attach(t), this._tips.push({
        element: t,
        content: e
      }))
    }, t.prototype.getTooltip = function (t) {
      t = B(t);
      for (var e = this._tips, n = 0; n < e.length; n++)
        if (e[n].element == t) return e[n].content;
      return null
    }, t.prototype.show = function (e, n, r) {
      e = B(e), n = this._getContent(n), r || (r = Ht.fromBoundingRect(e.getBoundingClientRect()));
      var i = t._eTip;
      i || (S(i = t._eTip = document.createElement("div"), "wj-tooltip"), i.style.visibility = "none"), this._setContent(n);
      var o = new fe(n);
      this.onPopup(o), o.content && !o.cancel && (document.body.appendChild(i), this._setContent(o.content), i.style.minWidth = "", J(i, r = new Ht(r.left - (i.offsetWidth - r.width) / 2, r.top - this.gap, i.offsetWidth, r.height + 2 * this.gap), !0), document.addEventListener("mousedown", this._hideAutoTipBnd))
    }, t.prototype.hide = function () {
      var e = t._eTip;
      e && (k(e), e.innerHTML = ""), document.removeEventListener("mousedown", this._hideAutoTipBnd)
    }, t.prototype.dispose = function () {
      var t = this;
      this._tips.forEach(function (e) {
        t._detach(e.element)
      }), this._tips.splice(0, this._tips.length)
    }, Object.defineProperty(t.prototype, "isVisible", {
      get: function () {
        var e = t._eTip;
        return null != e && null != e.parentElement && e.offsetWidth > 0
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "isContentHtml", {
      get: function () {
        return this._html
      },
      set: function (t) {
        this._html = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "gap", {
      get: function () {
        return this._gap
      },
      set: function (t) {
        this._gap = C(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "showAtMouse", {
      get: function () {
        return this._showAtMouse
      },
      set: function (t) {
        this._showAtMouse = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "showDelay", {
      get: function () {
        return this._showDelay
      },
      set: function (t) {
        this._showDelay = E(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "hideDelay", {
      get: function () {
        return this._hideDelay
      },
      set: function (t) {
        this._hideDelay = E(t)
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.onPopup = function (t) {
      return this.popup && this.popup.raise(this, t), !t.cancel
    }, t.prototype._indexOf = function (t) {
      for (var e = 0; e < this._tips.length; e++)
        if (this._tips[e].element == t) return e;
      return -1
    }, t.prototype._attach = function (t) {
      t.addEventListener("mouseenter", this._showAutoTipBnd), t.addEventListener("mouseleave", this._hideAutoTipBnd), t.addEventListener("click", this._showAutoTipBnd)
    }, t.prototype._detach = function (t) {
      t.removeEventListener("mouseenter", this._showAutoTipBnd), t.removeEventListener("mouseleave", this._hideAutoTipBnd), t.removeEventListener("click", this._showAutoTipBnd)
    }, t.prototype._showAutoTip = function (t) {
      var e = this;
      if (!t.defaultPrevented)
        if ("click" != t.type || $t._touching) {
          var n = "mouseenter" == t.type ? this._showDelay : 0;
          this._clearTimeouts(), this._toShow = setTimeout(function () {
            var n = e._indexOf(t.target);
            if (n > -1) {
              var r = e._tips[n],
                i = e._showAtMouse ? new Ht(t.clientX, t.clientY, 0, 0) : null;
              e.show(r.element, r.content, i), e._hideDelay > 0 && (e._toHide = setTimeout(function () {
                e.hide()
              }, e._hideDelay))
            }
          }, n)
        } else this._hideAutoTip()
    }, t.prototype._hideAutoTip = function () {
      this._clearTimeouts(), this.hide()
    }, t.prototype._clearTimeouts = function () {
      this._toShow && (clearTimeout(this._toShow), this._toShow = null), this._toHide && (clearTimeout(this._toHide), this._toHide = null)
    }, t.prototype._getContent = function (t) {
      if ((t = w(t)) && "#" == t[0]) {
        var e = B(t);
        e && (t = e.innerHTML)
      }
      return t
    }, t.prototype._setContent = function (e) {
      var n = t._eTip;
      n && (this.isContentHtml ? n.innerHTML = e : n.textContent = e)
    }, t
  }();
  e.Tooltip = pe;
  var fe = function (t) {
    function e(e) {
      var n = t.call(this) || this;
      return n._content = w(e), n
    }
    return __extends(e, t), Object.defineProperty(e.prototype, "content", {
      get: function () {
        return this._content
      },
      set: function (t) {
        this._content = w(t)
      },
      enumerable: !0,
      configurable: !0
    }), e
  }(Wt);
  e.TooltipEventArgs = fe;
  var de = function () {
    function t(t) {
      this._r = 0, this._g = 0, this._b = 0, this._a = 1, t && this._parse(t)
    }
    return Object.defineProperty(t.prototype, "r", {
      get: function () {
        return this._r
      },
      set: function (t) {
        this._r = v(C(t), 0, 255)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "g", {
      get: function () {
        return this._g
      },
      set: function (t) {
        this._g = v(C(t), 0, 255)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "b", {
      get: function () {
        return this._b
      },
      set: function (t) {
        this._b = v(C(t), 0, 255)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "a", {
      get: function () {
        return this._a
      },
      set: function (t) {
        this._a = v(C(t), 0, 1)
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.equals = function (e) {
      return e instanceof t && this.r == e.r && this.g == e.g && this.b == e.b && this.a == e.a
    }, t.prototype.toString = function () {
      var t = Math.round(100 * this.a);
      return t > 99 ? "#" + ((1 << 24) + (this.r << 16) + (this.g << 8) + this.b).toString(16).slice(1) : "rgba(" + this.r + "," + this.g + "," + this.b + "," + t / 100 + ")"
    }, t.fromRgba = function (e, n, r, i) {
      void 0 === i && (i = 1);
      var o = new t(null);
      return o.r = Math.round(v(C(e), 0, 255)), o.g = Math.round(v(C(n), 0, 255)), o.b = Math.round(v(C(r), 0, 255)), o.a = v(C(i), 0, 1), o
    }, t.fromHsb = function (e, n, r, i) {
      void 0 === i && (i = 1);
      var o = t._hsbToRgb(v(C(e), 0, 1), v(C(n), 0, 1), v(C(r), 0, 1));
      return t.fromRgba(o[0], o[1], o[2], i)
    }, t.fromHsl = function (e, n, r, i) {
      void 0 === i && (i = 1);
      var o = t._hslToRgb(v(C(e), 0, 1), v(C(n), 0, 1), v(C(r), 0, 1));
      return t.fromRgba(o[0], o[1], o[2], i)
    }, t.fromString = function (e) {
      var n = new t(null);
      return n._parse(w(e)) ? n : null
    }, t.prototype.getHsb = function () {
      return t._rgbToHsb(this.r, this.g, this.b)
    }, t.prototype.getHsl = function () {
      return t._rgbToHsl(this.r, this.g, this.b)
    }, t.interpolate = function (e, n, r) {
      r = v(C(r), 0, 1);
      var i = t._rgbToHsl(e.r, e.g, e.b),
        o = t._rgbToHsl(n.r, n.g, n.b),
        s = 1 - r,
        a = e.a * s + n.a * r,
        u = [i[0] * s + o[0] * r, i[1] * s + o[1] * r, i[2] * s + o[2] * r],
        c = t._hslToRgb(u[0], u[1], u[2]);
      return t.fromRgba(c[0], c[1], c[2], a)
    }, t.toOpaque = function (e, n) {
      if (1 == (e = s(e) ? t.fromString(e) : O(e, t)).a) return e;
      n = null == n ? t.fromRgba(255, 255, 255, 1) : s(n) ? t.fromString(n) : O(n, t);
      var r = e.a,
        i = 1 - r;
      return t.fromRgba(e.r * r + n.r * i, e.g * r + n.g * i, e.b * r + n.b * i)
    }, t.prototype._parse = function (e) {
      if ("transparent" == (e = e.toLowerCase())) return this._r = this._g = this._b = this._a = 0, !0;
      if (e && 0 != e.indexOf("#") && 0 != e.indexOf("rgb") && 0 != e.indexOf("hsl")) {
        var n = document.createElement("div");
        n.style.color = e;
        var r = n.style.color;
        r == e && ((r = window.getComputedStyle(n).color) || (document.body.appendChild(n), r = window.getComputedStyle(n).color, k(n))), e = r.toLowerCase()
      }
      if (0 == e.indexOf("#")) return 4 == e.length ? (this.r = parseInt(e[1] + e[1], 16), this.g = parseInt(e[2] + e[2], 16), this.b = parseInt(e[3] + e[3], 16), this.a = 1, !0) : 7 == e.length && (this.r = parseInt(e.substr(1, 2), 16), this.g = parseInt(e.substr(3, 2), 16), this.b = parseInt(e.substr(5, 2), 16), this.a = 1, !0);
      if (0 == e.indexOf("rgb")) {
        var i = e.indexOf("("),
          o = e.indexOf(")");
        if (i > -1 && o > -1 && (s = e.substr(i + 1, o - (i + 1)).split(",")).length > 2) return this.r = parseInt(s[0]), this.g = parseInt(s[1]), this.b = parseInt(s[2]), this.a = s.length > 3 ? parseFloat(s[3]) : 1, !0
      }
      if (0 == e.indexOf("hsl")) {
        var i = e.indexOf("("),
          o = e.indexOf(")");
        if (i > -1 && o > -1) {
          var s = e.substr(i + 1, o - (i + 1)).split(",");
          if (s.length > 2) {
            var a = parseInt(s[0]) / 360,
              u = parseInt(s[1]),
              c = parseInt(s[2]);
            s[1].indexOf("%") > -1 && (u /= 100), s[2].indexOf("%") > -1 && (c /= 100);
            var l = t._hslToRgb(a, u, c);
            return this.r = l[0], this.g = l[1], this.b = l[2], this.a = s.length > 3 ? parseFloat(s[3]) : 1, !0
          }
        }
      }
      return !1
    }, t._hslToRgb = function (e, n, r) {
      b(e >= 0 && e <= 1 && n >= 0 && n <= 1 && r >= 0 && r <= 1, "bad HSL values");
      var i, o, s;
      if (0 == n) i = o = s = r;
      else {
        var a = r < .5 ? r * (1 + n) : r + n - r * n,
          u = 2 * r - a;
        i = t._hue2rgb(u, a, e + 1 / 3), o = t._hue2rgb(u, a, e), s = t._hue2rgb(u, a, e - 1 / 3)
      }
      return [Math.round(255 * i), Math.round(255 * o), Math.round(255 * s)]
    }, t._hue2rgb = function (t, e, n) {
      return n < 0 && (n += 1), n > 1 && (n -= 1), n < 1 / 6 ? t + 6 * (e - t) * n : n < .5 ? e : n < 2 / 3 ? t + (e - t) * (2 / 3 - n) * 6 : t
    }, t._rgbToHsl = function (t, e, n) {
      b(t >= 0 && t <= 255 && e >= 0 && e <= 255 && n >= 0 && n <= 255, "bad RGB values"), t /= 255, e /= 255, n /= 255;
      var r, i, o = Math.max(t, e, n),
        s = Math.min(t, e, n),
        a = (o + s) / 2;
      if (o == s) r = i = 0;
      else {
        var u = o - s;
        switch (i = a > .5 ? u / (2 - o - s) : u / (o + s), o) {
          case t:
            r = (e - n) / u + (e < n ? 6 : 0);
            break;
          case e:
            r = (n - t) / u + 2;
            break;
          case n:
            r = (t - e) / u + 4
        }
        r /= 6
      }
      return [r, i, a]
    }, t._rgbToHsb = function (e, n, r) {
      b(e >= 0 && e <= 255 && n >= 0 && n <= 255 && r >= 0 && r <= 255, "bad RGB values");
      var i = t._rgbToHsl(e, n, r);
      return t._hslToHsb(i[0], i[1], i[2])
    }, t._hsbToRgb = function (e, n, r) {
      var i = t._hsbToHsl(e, n, r);
      return t._hslToRgb(i[0], i[1], i[2])
    }, t._hsbToHsl = function (t, e, n) {
      b(t >= 0 && t <= 1 && e >= 0 && e <= 1 && n >= 0 && n <= 1, "bad HSB values");
      var r = v(n * (2 - e) / 2, 0, 1),
        i = 1 - Math.abs(2 * r - 1),
        o = v(i > 0 ? n * e / i : e, 0, 1);
      return b(!isNaN(r) && !isNaN(o), "bad conversion to HSL"), [t, o, r]
    }, t._hslToHsb = function (t, e, n) {
      b(t >= 0 && t <= 1 && e >= 0 && e <= 1 && n >= 0 && n <= 1, "bad HSL values");
      var r = v(1 == n ? 1 : (2 * n + e * (1 - Math.abs(2 * n - 1))) / 2, 0, 1),
        i = v(r > 0 ? 2 * (r - n) / r : e, 0, 1);
      return b(!isNaN(r) && !isNaN(i), "bad conversion to HSB"), [t, i, r]
    }, t
  }();
  e.Color = de;
  var ge = function () {
    function t() {}
    return t.copy = function (e) {
      t._copyPaste(w(e), null)
    }, t.paste = function (e) {
      t._copyPaste(null, M(e))
    }, t._copyPaste = function (t, e) {
      for (var n = R(), r = G(n, ".wj-control"); r && $t.getControl(r);) r = r.parentElement;
      if (r = r || document.body) {
        var i = V('<textarea class="wj-clipboard" style="position:fixed;opacity:0"/>', r);
        s(t) && (i.value = t), i.select(), i.onkeydown = function (t) {
          t.preventDefault()
        }, setTimeout(function () {
          n.focus(), k(i), l(e) && e(i.value)
        }, $t._CLIPBOARD_DELAY)
      }
    }, t
  }();
  e.Clipboard = ge, e.showPopup = J, e.hidePopup = function (t, e, n) {
    void 0 === e && (e = !0), void 0 === n && (n = !1);
    var r = null;
    return n ? r = Y(function (n) {
      t.style.opacity = (1 - n).toString(), 1 == n && (X(t, e), t.style.opacity = "")
    }) : X(t, e), r
  };
  var _e = function () {
    function t(t) {
      this._copyCss = !0, null != t && y(this, t)
    }
    return Object.defineProperty(t.prototype, "title", {
      get: function () {
        return this._title
      },
      set: function (t) {
        this._title = w(t)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "copyCss", {
      get: function () {
        return this._copyCss
      },
      set: function (t) {
        this._copyCss = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype.addCSS = function (t) {
      this._css || (this._css = []), this._css.push(t)
    }, t.prototype.append = function (t) {
      var e = this._getDocument(),
        n = e.body,
        r = !1;
      if (n)
        if (s(t)) n.appendChild(V(t));
        else if (t instanceof Node) {
        i = this._cloneNode(t);
        n.appendChild(i)
      } else r = !0;
      else if (s(t)) e.write(t);
      else if (t instanceof HTMLElement) {
        var i = this._cloneNode(t);
        e.write(i.outerHTML)
      } else r = !0;
      r && b(!1, "child parameter should be an HTML node or an HTML string.")
    }, t.prototype.print = function () {
      var t = this;
      this._iframe && (this._close(), setTimeout(function () {
        var e = t._iframe.contentWindow,
          n = "onafterprint" in e && !Mt();
        n && (e.onafterprint = function () {
          k(t._iframe), t._iframe = null
        }), document.queryCommandSupported("print") ? e.document.execCommand("print", !1, null) : (e.focus(), e.print()), n || (k(t._iframe), t._iframe = null)
      }, 100))
    }, t.prototype._cloneNode = function (t) {
      var e = t.cloneNode(!0);
      return t instanceof HTMLElement && e instanceof HTMLElement && ["select", "textarea"].forEach(function (n) {
        for (var r = t.querySelectorAll(n), i = e.querySelectorAll(n), o = 0; o < r.length; o++) i[o].value = r[o].value
      }), e
    }, t.prototype._getDocument = function () {
      return this._iframe || (this._iframe = document.createElement("iframe"), S(this._iframe, "wj-printdocument"), W(this._iframe, {
        position: "fixed",
        left: 1e4,
        top: 1e4
      }), document.body.appendChild(this._iframe), this._iframe.contentDocument.write('<body style="position:static"/>')), this._iframe.contentDocument
    }, t.prototype._close = function () {
      var t = this._getDocument();
      if (t.close(), t.title = null != this.title ? this.title : document.title, t.title && t.title.trim() || (t.title = " "), this._copyCss) {
        for (var e = document.head.querySelectorAll("link"), n = 0; n < e.length; n++) {
          var r = e[n];
          if (r.href.match(/\.css$/i) && r.rel.match(/stylesheet/i)) {
            s = K(r.href, {
              async: !1
            });
            this._addStyle(s.responseText)
          }
        }
        for (var i = document.head.querySelectorAll("STYLE"), n = 0; n < i.length; n++) this._addStyle(i[n].textContent)
      }
      if (this._css)
        for (n = 0; n < this._css.length; n++) {
          var o = t.createElement("style"),
            s = K(this._css[n], {
              async: !1
            });
          o.textContent = s.responseText, t.head.appendChild(o)
        }
    }, t.prototype._addStyle = function (t) {
      var e = this._getDocument(),
        n = e.createElement("style");
      n.textContent = t, e.head.appendChild(n)
    }, t
  }();
  e.PrintDocument = _e;
  var me = "The Wijmo license in this application ",
    ve = {
      evl: "Wijmo Evaluation Version ({version})",
      prd: me + "is not valid for the <b>{control}</b> control.",
      ver: me + "is not valid for the version in use. The license version is <b>{licVer}</b>; the product version is <b>{version}</b>.",
      dmn: me + "is not valid for the current domain. The license domain is <b>{licDomain}</b>; the current domain is <b>{domain}</b>.",
      exp: me + "has expired. The license expiration date is <b>{expDate:d}</b>.",
      lic: me + "is invalid.",
      mss: me + "is not set.",
      ctc: 'Please <a target="_blank" rel="noopener" href="https://www.grapecity.com/en/licensing/wijmo">create a license</a> or contact GrapeCity: <a href="mailto:us.sales@grapecity.com">us.sales@grapecity.com</a>.',
      hdr: "Wijmo License",
      cls: "CLOSE"
    },
    ye = {};
  e._updateWme = function (t, e) {
    var n = $t._wme;
    if (!(n && z(document.body, n) && n.offsetWidth && n.offsetHeight))
      if (e) {
        var r = $(t);
        ye[r] || (ye[r] = !0, lt(e, {
          algorithmCode: "B0",
          publicKey: "l6/zrbWoSbcLFwEetFh38rH3ErBZE9H+Cqix3R+wTlfA1wD5B+lUcCQn+EJ60I4RGrm0x1sFjkiLWwB0jAn6BWZv0W4WbqAKriOdeoivxDp1Wmjs3qkEDhvbsjPtfvwx2BHil6o+/tDrdMJQSGs18WZm2PoQLQuL+9VhZ4FNRHUQU3Jtioke/OZEGHJOdYVwvCGalzBad6QFOiVbDBQPePpS3++GJzOxN8SN/7lyS5/IdKiy3WJRaVGkB370+HbN6hKraDfUgReLX26yxRaKC/5aWnGAJ2NnWLoGyAGRcwT9dVjo4bcAZNrrA0U9JVKQxaSskhdv2p49XzJkltXx5w=="
        }, function (n) {
          tt(t, e, n)
        }, function () {
          tt(t, e, null)
        }), setTimeout(function () {
          delete ye[r]
        }, 50))
      } else tt(t, null, null)
  };
  var be = "undefined" == typeof window ? null : window.Uint8Array || window.TypedArray,
    we = function () {
      function t(t, e, n) {
        void 0 === e && (e = null), void 0 === n && (n = "_"), this._promptChar = "_", this._mskArr = [], this._full = !0, this._hbInput = this._input.bind(this), this._hbKeyDown = this._keydown.bind(this), this._hbKeyPress = this._keypress.bind(this), this._hbCompositionStart = this._compositionstart.bind(this), this._hbCompositionEnd = this._compositionend.bind(this), this.mask = w(e), this.input = t, this.promptChar = w(n, !1), this._connect(!0), this._evtInput = document.createEvent("HTMLEvents"), this._evtInput.initEvent("input", !0, !1)
      }
      return Object.defineProperty(t.prototype, "input", {
        get: function () {
          return this._tbx
        },
        set: function (t) {
          this._connect(!1), this._tbx = t, this._connect(!0)
        },
        enumerable: !0,
        configurable: !0
      }), Object.defineProperty(t.prototype, "mask", {
        get: function () {
          return this._msk
        },
        set: function (t) {
          t != this._msk && (this._msk = w(t, !0), this._parseMask(), this._valueChanged())
        },
        enumerable: !0,
        configurable: !0
      }), Object.defineProperty(t.prototype, "promptChar", {
        get: function () {
          return this._promptChar
        },
        set: function (t) {
          t != this._promptChar && (this._promptChar = w(t, !1), b(1 == this._promptChar.length, "promptChar must be a string with length 1."), this._valueChanged())
        },
        enumerable: !0,
        configurable: !0
      }), Object.defineProperty(t.prototype, "maskFull", {
        get: function () {
          return this._full
        },
        enumerable: !0,
        configurable: !0
      }), t.prototype.getMaskRange = function () {
        return this._mskArr.length ? [this._firstPos, this._lastPos] : [0, this._tbx.value.length - 1]
      }, t.prototype.getRawValue = function () {
        var t = this._tbx.value,
          e = "";
        if (!this.mask) return t;
        for (var n = 0; n < this._mskArr.length && n < t.length; n++) this._mskArr[n].literal || t[n] == this._promptChar || (e += t[n]);
        return e
      }, t.prototype.refresh = function () {
        this._parseMask(), this._valueChanged()
      }, t.prototype._input = function (t) {
        var e = this;
        this._composing ? t.stopImmediatePropagation() : setTimeout(function () {
          e._valueChanged()
        })
      }, t.prototype._keydown = function (t) {
        if (t.keyCode == Ft.Back) {
          var e = this._tbx.selectionStart,
            n = this._tbx.selectionEnd;
          if (e <= this._firstPos && n == e) return t.preventDefault(), void(this._backSpace = !1)
        }
        this._backSpace = t.keyCode == Ft.Back
      }, t.prototype._keypress = function (t) {
        t.ctrlKey || t.metaKey || t.altKey || this._composing || !this._preventKey(t.charCode) || t.preventDefault()
      }, t.prototype._compositionstart = function (t) {
        this._composing = !0
      }, t.prototype._compositionend = function (t) {
        var e = this;
        this._composing && (this._composing = !1, setTimeout(function () {
          e._tbx && (e._valueChanged() || j(e._tbx, e._tbx.value.length), e._tbx.dispatchEvent(e._evtInput))
        }))
      }, t.prototype._preventKey = function (t) {
        if (t && this._mskArr.length) {
          var e = this._tbx,
            n = e.selectionStart,
            r = String.fromCharCode(t);
          if (n < this._firstPos && j(e, n = this._firstPos), n >= this._mskArr.length) return !0;
          var i = this._mskArr[n];
          if (i.literal) this._validatePosition(n);
          else if (i.wildCard != r && !this._isCharValid(i.wildCard, r)) return !0
        }
        return !1
      }, t.prototype._connect = function (t) {
        var e = this._tbx;
        e && (b(e instanceof HTMLInputElement || e instanceof HTMLTextAreaElement, "INPUT or TEXTAREA element expected."), t ? (this._autoComplete = e.autocomplete, this._spellCheck = e.spellcheck, e.autocomplete = "off", e.spellcheck = !1, e.addEventListener("input", this._hbInput), e.addEventListener("keydown", this._hbKeyDown, !0), e.addEventListener("keypress", this._hbKeyPress, !0), e.addEventListener("compositionstart", this._hbCompositionStart, !0), e.addEventListener("compositionend", this._hbCompositionEnd, !0), e.addEventListener("blur", this._hbCompositionEnd, !0), this._valueChanged()) : (e.autocomplete = this._autoComplete, e.spellcheck = this._spellCheck, e.removeEventListener("input", this._hbInput), e.removeEventListener("keydown", this._hbKeyDown, !0), e.removeEventListener("keypress", this._hbKeyPress, !0), e.removeEventListener("compositionstart", this._hbCompositionStart, !0), e.removeEventListener("compositionend", this._hbCompositionEnd, !0), e.removeEventListener("blur", this._hbCompositionEnd, !0)))
      }, t.prototype._valueChanged = function () {
        if (!this._tbx || !this._msk) return !1;
        var t = this._tbx,
          e = t.value.length < 2,
          n = t.selectionStart,
          r = n > 0 ? t.value[n - 1] : "",
          i = t.value;
        t.value = this._applyMask(), e && (n = this._firstPos + 1);
        var o = n > 0 ? t.value[n - 1] : "";
        return n > 0 && o == this._promptChar && r != this.promptChar && n--, n == i.length && (n = this._matchEnd), this._validatePosition(n), i != t.value
      }, t.prototype._applyMask = function () {
        this._full = !0, this._matchEnd = 0;
        var t = this._tbx.value;
        if (!this._msk) return t;
        if (!t && !this._tbx.required) return t;
        var e = "",
          n = 0,
          r = this._promptChar;
        t = this._handleVagueLiterals(t);
        for (var i = 0; i < this._mskArr.length; i++) {
          var o = this._mskArr[i],
            s = o.literal;
          if (s && s == t[n] && n++, o.wildCard) {
            if (s = r, t) {
              for (var a = n; a < t.length; a++)
                if (this._isCharValid(o.wildCard, t[a])) {
                  switch (s = t[a], o.charCase) {
                    case ">":
                      s = s.toUpperCase();
                      break;
                    case "<":
                      s = s.toLowerCase()
                  }
                  s != r && (this._matchEnd = e.length + 1);
                  break
                } n = a + 1
            }
            s == r && (this._full = !1)
          }
          e += s
        }
        return e
      }, t.prototype._handleVagueLiterals = function (t) {
        if (t.length > this._mskArr.length + 1) return t;
        var e = t.length - this._mskArr.length;
        if (0 != e && t.length > 1) {
          for (var n = -1, r = this._tbx, i = r == R() ? r.selectionStart : r.value.length, o = Math.max(0, i - e); o < this._mskArr.length; o++)
            if (this._mskArr[o].vague) {
              n = o;
              break
            } if (n > -1)
            if (e < 0) {
              var s = Array(1 - e).join(this._promptChar),
                a = n + e;
              a > -1 && (t = t.substr(0, a) + s + t.substr(a))
            } else {
              for (; n > 0 && this._mskArr[n - 1].literal;) n--;
              t = t.substr(0, n) + t.substr(n + e)
            }
        }
        return t
      }, t.prototype._isCharValid = function (e, n) {
        var r = this._promptChar;
        switch (e) {
          case "0":
            return n >= "0" && n <= "9" || n == r;
          case "9":
            return n >= "0" && n <= "9" || " " == n || n == r;
          case "#":
            return n >= "0" && n <= "9" || " " == n || "+" == n || "-" == n || n == r;
          case "L":
            return n >= "a" && n <= "z" || n >= "A" && n <= "Z" || n == r;
          case "l":
            return n >= "a" && n <= "z" || n >= "A" && n <= "Z" || " " == n || n == r;
          case "A":
            return n >= "0" && n <= "9" || n >= "a" && n <= "z" || n >= "A" && n <= "Z" || n == r;
          case "a":
            return n >= "0" && n <= "9" || n >= "a" && n <= "z" || n >= "A" && n <= "Z" || " " == n || n == r;
          case "９":
            return n >= "０" && n <= "９" || n == r;
          case "Ｊ":
          case "Ｇ":
            return !("Ｇ" == e && t._X_DBCS_BIG_HIRA.indexOf(n) > -1) && (n >= "ぁ" && n <= "ゖ" || n == r);
          case "Ｋ":
          case "Ｎ":
            return !("Ｎ" == e && t._X_DBCS_BIG_KATA.indexOf(n) > -1) && (n >= "ァ" && n <= "ヺ" || n == r);
          case "Ｚ":
            return n <= "!" || n >= "ÿ" || n == r;
          case "H":
            return n >= "!" && n <= "ÿ" || n == r;
          case "K":
          case "N":
            return !("N" == e && t._X_SBCS_BIG_KATA.indexOf(n) > -1) && (n >= "ｦ" && n <= "ﾟ" || n == r)
        }
        return !1
      }, t.prototype._validatePosition = function (t) {
        var e = this._mskArr;
        if (this._backSpace)
          for (; t > 0 && t < e.length && e[t - 1].literal;) t--;
        if (0 == t || !this._backSpace)
          for (; t < e.length && e[t].literal;) t++;
        R() == this._tbx && j(this._tbx, t), this._backSpace = !1
      }, t.prototype._parseMask = function () {
        this._mskArr = [], this._firstPos = -1, this._lastPos = -1;
        for (var t, n = this._msk, r = "|", i = 0; n && i < n.length; i++) switch (n[i]) {
          case "0":
          case "9":
          case "#":
          case "A":
          case "a":
          case "L":
          case "l":
          case "９":
          case "Ｊ":
          case "Ｇ":
          case "Ｋ":
          case "Ｎ":
          case "Ｚ":
          case "K":
          case "N":
          case "H":
            this._firstPos < 0 && (this._firstPos = this._mskArr.length), this._lastPos = this._mskArr.length, this._mskArr.push(new Ce(n[i], r));
            break;
          case ".":
          case ",":
          case ":":
          case "/":
          case "$":
            switch (n[i]) {
              case ".":
              case ",":
                t = e.culture.Globalize.numberFormat[n[i]];
                break;
              case ":":
              case "/":
                t = e.culture.Globalize.calendar[n[i]];
                break;
              case "$":
                t = e.culture.Globalize.numberFormat.currency.symbol
            }
            for (s = 0; s < t.length; s++) this._mskArr.push(new Ce(t[s]));
            break;
          case "<":
          case ">":
          case "|":
            r = n[i];
            break;
          case "\\":
            i < n.length - 1 && i++, this._mskArr.push(new Ce(n[i]));
            break;
          default:
            this._mskArr.push(new Ce(n[i]))
        }
        for (i = 0; i < this._mskArr.length; i++) {
          var o = this._mskArr[i];
          if (o.literal)
            for (var s = 0; s < i; s++) {
              var a = this._mskArr[s];
              if (a.wildCard && this._isCharValid(a.wildCard, o.literal)) {
                o.vague = !0;
                break
              }
            }
        }
      }, t._X_DBCS_BIG_HIRA = "ぁぃぅぇぉっゃゅょゎゕゖ", t._X_DBCS_BIG_KATA = "ァィゥェォッャュョヮヵヶ", t._X_SBCS_BIG_KATA = "ｧｨｩｪｫｬｭｮｯ", t
    }();
  e._MaskProvider = we;
  var Ce = function () {
    return function (t, e) {
      e ? (this.wildCard = t, this.charCase = e) : this.literal = t
    }
  }();
  e._MaskElement = Ce;
  var Ee = function () {
    function t(t) {
      this._isDown = !1, this._mousedownBnd = this._mousedown.bind(this), this._mouseupBnd = this._mouseup.bind(this), this._onClickBnd = this._onClick.bind(this), this.element = t, this._connect(!0)
    }
    return Object.defineProperty(t.prototype, "element", {
      get: function () {
        return this._e
      },
      set: function (t) {
        this._connect(!1), this._e = O(t, HTMLElement, !0), this._connect(!0)
      },
      enumerable: !0,
      configurable: !0
    }), Object.defineProperty(t.prototype, "disabled", {
      get: function () {
        return this._disabled
      },
      set: function (t) {
        this._disabled = A(t)
      },
      enumerable: !0,
      configurable: !0
    }), t.prototype._connect = function (t) {
      if (this._e) {
        t ? this._e.addEventListener("mousedown", this._mousedownBnd) : this._e.removeEventListener("mousedown", this._mousedownBnd)
      }
    }, t.prototype._clearTimeouts = function () {
      this._toRepeat && (clearInterval(this._toRepeat), this._toRepeat = null), this._toDelay && (clearInterval(this._toDelay), this._toDelay = null)
    }, t.prototype._mousedown = function (e) {
      var n = this;
      this._isDown && this._mouseup(null), this._disabled || (this._isDown = !0, t._stopEvents.forEach(function (t) {
        document.addEventListener(t, n._mouseupBnd)
      }), this._clearTimeouts(), this._toDelay = setTimeout(function () {
        n._isDown && (n._onClick(), n._toRepeat = setTimeout(n._onClickBnd, $t._CLICK_REPEAT))
      }, $t._CLICK_DELAY))
    }, t.prototype._mouseup = function (e) {
      var n = this;
      this._isDown && e && "mouseup" == e.type && e.preventDefault(), t._stopEvents.forEach(function (t) {
        document.removeEventListener(t, n._mouseupBnd)
      }), this._clearTimeouts(), this._isDown = !1
    }, t.prototype._onClick = function () {
      this._clearTimeouts(), this._e && (this._e.click(), this._isDown && (this._toRepeat = setTimeout(this._onClickBnd, $t._CLICK_REPEAT)))
    }, t._stopEvents = ["mouseup", "mouseout", "keydown"], t
  }();
  e._ClickRepeater = Ee;
  var Ae = null != navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i);
  e.isMobile = function () {
    return Ae
  };
  var xe = null != navigator.userAgent.match(/Firefox\//);
  e.isFirefox = Mt;
  var Me = null != navigator.userAgent.match(/^((?!chrome|android).)*safari/i);
  e.isSafari = function () {
    return Me
  };
  var Te = null != navigator.userAgent.match(/Edge\//);
  e.isEdge = function () {
    return Te
  };
  var Oe = null != navigator.userAgent.match(/MSIE |Trident\/|Edge\//);
  e.isIE = Tt;
  var Ie = !1;
  e.isIE9 = Ot;
  var Pe = !1;
  "undefined" != typeof document && document.addEventListener("test", function (t) {}, {
    get passive() {
      return Pe = !0, !0
    }
  }), e.getEventOptions = It;
  var Se = !1;
  if ("undefined" != typeof document && document.createElement("div").focus({
      get preventScroll() {
        return Se = !0, !0
      }
    }), e.supportsFocusOptions = function () {
      return Se
    }, e._startDrag = function (t, e) {
      t.effectAllowed = e, Mt() && t.setData("text", "")
    }, "undefined" != typeof document && document.doctype && navigator.appVersion.indexOf("MSIE 9") > -1 && (Ie = !0, document.addEventListener("mousemove", function (t) {
      if (1 == t.which) {
        var e = G(t.target, ".wj-control");
        if (e && !e.style.cursor)
          for (var n = t.target; n; n = n.parentElement)
            if (n.attributes && n.attributes.draggable) return n.dragDrop(), !1
      }
    })), "undefined" != typeof window) {
    var De = "requestAnimationFrame";
    if (!window[De]) {
      var Fe = 0;
      window[De] = function (t) {
        var e = Date.now(),
          n = 16 - (e - Fe),
          r = n > 0 ? n : 0;
        return Fe = e + r, setTimeout(function () {
          t(Fe)
        }, r)
      }, window.cancelAnimationFrame = clearTimeout
    }
    if (!window.atob) {
      var Le = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
        je = new RegExp("[^" + Le + "]");
      window.atob = function (t) {
        var e, n, r, i, o = [],
          s = 0,
          a = t.length;
        if (je.test(t) || /=/.test(t) && (/=[^=]/.test(t) || /={3}/.test(t))) throw new Error("Invalid base64 data");
        for (a % 4 > 0 && (a = (t += Array(4 - a % 4 + 1).join("=")).length); s < a;) {
          for (n = [], i = s; s < i + 4;) n.push(Le.indexOf(t.charAt(s++)));
          for (r = [((e = (n[0] << 18) + (n[1] << 12) + ((63 & n[2]) << 6) + (63 & n[3])) & 255 << 16) >> 16, 64 === n[2] ? -1 : (65280 & e) >> 8, 64 === n[3] ? -1 : 255 & e], i = 0; i < 3; ++i)(r[i] >= 0 || 0 === i) && o.push(String.fromCharCode(r[i]))
        }
        return o.join("")
      }, window.btoa = function (t) {
        for (var e, n, r = [], i = 0, o = t.length; i < o;) e = ((n = [t.charCodeAt(i++), t.charCodeAt(i++), t.charCodeAt(i++)])[0] << 16) + ((n[1] || 0) << 8) + (n[2] || 0), r.push(Le.charAt((e & 63 << 18) >> 18), Le.charAt((258048 & e) >> 12), Le.charAt(isNaN(n[1]) ? 64 : (4032 & e) >> 6), Le.charAt(isNaN(n[2]) ? 64 : 63 & e));
        return r.join("")
      }
    }
  }
});
