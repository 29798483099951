import { Injectable } from '@angular/core';
import { Guid } from "guid-typescript";
import { LogPayload } from './logger.model';
import { SWCoreServices } from '../../../../core/services/core.services';
import { AppSettings } from '../../../../core/configs/core.config';
//import { IS_LOGGER_ACTIVE, AppSettings } from 'src/app/shared/constants/api-constant';
//import { UserInfo } from './userInfo.service';
import { HttpService } from './http.services';
//IS_LOGGER_ACTIVE is Octopus flag
@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private userData: any;
  traceId: string = '';
  startedLoggers: Array<any> = [];
  payload = {
    "event": {
      "module": "",
      "action": "",
      "duration": "", //string
      "outcome": "", 
      "attribute": {}
    },
    "transaction": {
      "id": "",
      "type": ""
    },
    "destination": {
      "name": "",
      "url": "",
      "type": "",
    },
    "message": "", 
    "user"  : {
      "browser":{}
    }

  }
  // let agentInformation:any;
  //   let userInfo = {
  //       userName:'',
  //       role:''
  //   };
  //   agentInformation = this.coreService.getLoggedInUserInfo();
  //   userInfo.userName = agentInformation.UserName;
  constructor(private coreService: SWCoreServices,private http: HttpService) {}
  
  createTraceId(): string {
    this.traceId = Guid.create()['value'];
    return this.traceId;
  }
  getTraceId(): string {
    return this.traceId;
  }
  logOnce(data: any) {
    if (!AppSettings.IS_LOGGER_ACTIVE) {
      return false;
    }
    if (!this.traceId) {
      this.createTraceId();
    }
    let logPayload: any = new LogPayload();
    logPayload.trace.id = this.getTraceId();
    logPayload.event.module = data.module;
    logPayload.event.action = data.action ? data.action : '';
    logPayload.event.duration = null;
    logPayload.event.outcome = 'success';
    logPayload.user.browser.screensize = data.screensize;
    logPayload.transaction.id = Guid.create()['value'];
    logPayload.transaction.type = data.transactionType ? data.transactionType : 'inquiry';;

    logPayload.destination.name = data.destName ? data.destName : null;
    logPayload.destination.url = data.destUrl? data.destUrl: null;
    logPayload.destination.type = data.destType ? data.destType : null;
    logPayload.message = data.message;
    this.logServer(logPayload);
  }
  logStart(data: any) {
    if (!AppSettings.IS_LOGGER_ACTIVE) {
      return { identifier: '', traceId: '', transactionId: '' };
    }
    if (!this.traceId) {
      this.createTraceId();
    }
    let logPayload: any = new LogPayload();
    let identifier = Guid.create()['value'];

    logPayload.trace.id = this.getTraceId();
    logPayload.event.module = data.module;
    logPayload.event.action = data.action ? data.action : 'api-call';
    logPayload.event.duration = '0';
    logPayload.event.outcome = '';
    // logPayload.event.attrib = 'unknown';
    // logPayload.eventattribs = '';
    logPayload.transaction.id = Guid.create()['value'];
    logPayload.transaction.type = data.transactionType ? data.transactionType : 'inquiry';
    
    logPayload.destination.name = data.destName ? data.destName : null;;
    logPayload.destination.url = data.destUrl? data.destUrl: null;
    logPayload.destination.type = data.destType ? data.destType : 'api';
    logPayload.message = data.message;

    this.startedLoggers.push({
      identifier: identifier,
      startTime: new Date(),
      payload: logPayload
    });
    this.logServer(logPayload);
    return { identifier: identifier, traceId: logPayload.trace.id, transactionId: logPayload.transaction.id };
  }
  logSuccess(identifier: string, message: string = '', attrib: any ='') {
    
    if (!AppSettings.IS_LOGGER_ACTIVE) {
      return false;
    }
    let startedLoggerObj: any = this.getStartedObject(identifier);
    let startedLogger: any = startedLoggerObj.startedLogger;
    startedLogger.payload.event.duration = `${(new Date() as any) - startedLogger.startTime}`;
    startedLogger.payload.event.outcome = 'success';
    startedLogger.payload.event.action = 'api-response';
    // startedLogger.payload.event.attrib = attrib;
    startedLogger.payload.labels = attrib;
    startedLogger.payload.message = message;
    this.startedLoggers.splice(startedLoggerObj.startedIndex, 1);
    // console.log('Success >> ', startedLogger.payload);
    this.logServer(startedLogger.payload);
  }
  logFailure(identifier: string, message: string = '') {
    if (!AppSettings.IS_LOGGER_ACTIVE) {
      return false;
    }
    let startedLoggerObj: any = this.getStartedObject(identifier);
    let startedLogger: any = startedLoggerObj.startedLogger;
    startedLogger.payload.event.duration = `${(new Date() as any) - startedLogger.startTime}`;
    startedLogger.payload.event.outcome = 'fail';
    startedLogger.payload.event.action = 'api-response';
    startedLogger.payload.message = message;
    this.startedLoggers.splice(startedLoggerObj.startedIndex, 1);
    // console.log('Failure >> ', startedLogger.payload);
    this.logServer(startedLogger.payload);
  }
  getStartedObject(identifier: string) {
    let startedLogger: any;
    let startedIndex: number = null;
    for (let i = 0; i < this.startedLoggers.length; i++) {
      if (this.startedLoggers[i].identifier === identifier) {
        startedLogger = this.startedLoggers[i];
        startedIndex = i;
      }
    }
    return { startedIndex: startedIndex, startedLogger: startedLogger };
  }

  logServer(logPayload: any) {
    if (!AppSettings.IS_LOGGER_ACTIVE) {
      return false;
    }
    //this.userData = this.userInfo._currentUserFn();
    this.userData = this.coreService.getLoggedInUserInfo();
    //   userInfo.userName = agentInformation.UserName;
    const payload={events:[]}
    payload["events"].push ({
      "organization" : { "id": "homeoffice"},
       "group": { "id": "clr"},
      "labels" :logPayload.labels,
      "trace": {
        "id": logPayload.trace.id
      },
      "host": {
        "type": AppSettings.SW_ENVIRONMENT,//AppSettings.ENVIRONMENT,
        "name": window.location.host //window.location.hostname
      },
      "event": {
        "provider": "annualQuestionnaire-ui",
        "module": logPayload.event.module,
        "url": window.location.href,
        "action": logPayload.event.action,
        "duration": `${logPayload.event.duration}`,
        "kind": "event",
        "outcome": logPayload.event.outcome
        // "attrib" :logPayload.event.attrib
      },
      //"eventattribs": logPayload.eventattribs,
      "transaction": {
        "id": logPayload.transaction.id, //Guid.create()['value'],
        "type": logPayload.transaction.type
      },
      // "client": {
      //     "name": "UI"
      // },
      "destination": {
        "name": logPayload.destination.name,
        "url": logPayload.destination.url,
        "type": logPayload.destination.type
      },
      "message": logPayload.message,
      "user": {
        "id": this.userData.UserName,
        "browser":{
          "screensize": logPayload.user.browser.screensize,
        }
        
      },
      "log": {
        "level": "info" //this.logLevelArr[log.level]
      }
      // "additional": log.additional
    })
    const withCredentials = false;
    const extraHeaders = {};

    const itemToBool = item => {
      if (typeof item !== 'object' || item === null) return item;
      const cleanedItem = clean(item);
      return Object.keys(cleanedItem).length !== 0 && cleanedItem;
    };
    const clean = obj => {
      if (Array.isArray(obj)) {
        const newArr = obj.map(itemToBool).filter(Boolean);
        return newArr.length && newArr;
      }
      const newObj = Object.entries(obj).reduce((a, [key, val]) => {
        const newVal = itemToBool(val);
        if (newVal) a[key] = newVal;
        return a;
      }, {});
      return Object.keys(newObj).length > 0 && newObj;
    };
    //const payload["events"] =events
    //const filteredPayload = payload;
    // logPayload = Object.entries(logPayload).reduce((a,[k,v]) => (v === null ||  v == ''? a : (a[k]=v, a)), {})
    this.http.post(AppSettings.logger, payload, extraHeaders, withCredentials, {timestamp: false}).subscribe(resp => {}, error=>{})
  }
  
}