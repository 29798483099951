import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { JqFunctions } from "../../../static-class/jqfunctions.static";
import { SWCoreServices } from "../../../services/core.services";
import { DomSanitizer } from "@angular/platform-browser";
import { AppSettings } from "../../../configs/core.config";
import { AppConstant } from "../../../constant/core.constant";
import * as moment from "moment";
import { ToastService } from "../../ui-components/toaster/toaster.service";

@Component({
  selector: "feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"]
})
export class FeedbackComponent implements OnInit, OnDestroy {
  isModalLoad = false;
  url;
  listenSuccessMessageFn: any;
  listenGeneralMessageFn: any;
  feedbackEmbedURL = this.swCoreServices.getAPPSettingsByKey(
    AppConstant.medalliaEmbedJs
  );
  @Input() feedbackParam: any;
  advisorInfo = {
    name: "",
    repId: ""
  };
  employeeFormId = this.swCoreServices.getAPPSettingsByKey(
    "MEDALLIA_EMP_FORM_ID"
  );
  advisorFormId = this.swCoreServices.getAPPSettingsByKey(
    "MEDALLIA_ADV_FORM_ID"
  );
  isThankYou = false;
  constructor(
    private activeModal: NgbActiveModal,
    private toasterService: ToastService,
    private swCoreServices: SWCoreServices
  ) {
    let self = this;
    this.listenSuccessMessageFn = function(ev) {
      self.toasterService.toastSuccess(
        "Feedback Provided",
        "Your feedback has been submitted successfully."
      );
      self.isThankYou = true;
      // setTimeout(function() {
      self.dismissModal();
      // }, 3000);
    };
    this.listenGeneralMessageFn = function(ev) {
      self.dismissModal();
    };
  }

  ngOnInit(): void {
    window.addEventListener(
      "MDigital_ThankYou_Displayed",
      this.listenSuccessMessageFn,
      false
    );
    window.addEventListener(
      "MDigital_Form_Close_No_Submit",
      this.listenGeneralMessageFn,
      false
    );
    this.isModalLoad = true;
    this.giveFeedback();
  }

  giveFeedback() {
    if (JqFunctions.isScriptFileLoaded(this.feedbackEmbedURL)) {
      this.loadMedalliaForm();
    } else {
      setTimeout(function() {
        this.giveFeedback();
      }, 500);
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    // console.log("close acc destroy");
    window.removeEventListener(
      "MDigital_ThankYou_Displayed",
      this.listenSuccessMessageFn,
      false
    );
    window.removeEventListener(
      "MDigital_Form_Close_No_Submit",
      this.listenGeneralMessageFn,
      false
    );
  }

  dismissModal(): void {
    this.activeModal.dismiss("dismiss");
  }

  loadMedalliaForm() {
    let advisorInformation = window["advisorInformation"];
    this.advisorInfo.name = advisorInformation
      ? advisorInformation.fullName
      : "NA";
    this.advisorInfo.repId = advisorInformation
      ? advisorInformation.repId
      : "NA";
    if (this.feedbackParam.type == "employee") {
      window["employeeFeedbackForm"] = "lplAtWorkEmployee";
    } else {
      window["employeeFeedbackForm"] = "";
    }
    window["userType"] =
      this.feedbackParam.type == "employee"
        ? "Employee"
        : advisorInformation["userType"];

    window["username"] =
      this.feedbackParam.type == "employee"
        ? "CORP" + "\\" + this.swCoreServices.agentInformation.UserName
        : advisorInformation["username"];

    window["emailAddress"] =
      this.feedbackParam.type == "employee"
        ? this.swCoreServices.agentInformation.EmailAddress
        : advisorInformation["emailAddress"];

    window["masterId"] =
      this.feedbackParam.type == "employee"
        ? ""
        : advisorInformation["masterId"];

    window["repId"] =
      this.feedbackParam.type == "employee" ? "" : advisorInformation["repId"];

    window["fullName"] =
      this.feedbackParam.type == "employee"
        ? this.swCoreServices.agentInformation.DisplayName
        : advisorInformation["fullName"];

    window["tenantId"] =
      this.feedbackParam.type == "employee"
        ? "LPL"
        : advisorInformation["tenantId"];

    window["phoneNumber"] =
      this.feedbackParam.type == "employee"
        ? ""
        : advisorInformation["phoneNumber"];

    window["onBehalfOfSourceUserName"] =
      this.feedbackParam.type == "employee"
        ? ""
        : "CORP" + "\\" + this.swCoreServices.agentInformation.UserName;
    setTimeout(function() {
      window["KAMPYLE_ONSITE_SDK"].updatePageView();
    }, 1000);
  }
}
