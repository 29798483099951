import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'non-aq-header',
  templateUrl: './non-aq-header.component.html',
  styleUrls: ['./non-aq-header.component.scss']
})
export class NonAqHeaderComponent implements OnInit {
  useremail: string;

  constructor(
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.useremail = this.cookieService.get('useremail');
  }
  //set the query for dashboard page
  redirectToHome() {
    $('.nav-tab:first').click();
  }

}
