<!---- CW login -->
<div style="display: none" class="createIframe" id="createIframe">
</div>
<!-- Forge Rock login -->
<div style="visibility: hidden" class="FRIframe" id="FRIframe" style="width:0;height:0;border:0; border:none;">
</div>

<!-- <div *ngIf="!appLoaded">
  <loader></loader>
  <div style="position: absolute; z-index: 100; width: 100%; top: 54%; text-align: center;">
    <div style="font-weight: bold;">Loading...</div>
    <div>{{appLoadingText}}</div>
  </div>
</div> -->

<!-- header -->
<!-- <header-component *ngIf = "showSWHeaderFlag"></header-component> -->
<non-aq-header></non-aq-header>
<!-- end header -->
<div id="page-outer" class="container-fluid page-outer animated slideInRight" role="main">

  <!-- <div class="scrollbar" id="viewportContainer"> -->
    <div id="MenuItems" class="leftSidePannel collapse width">    
      <app-side-menu></app-side-menu>
    </div>
    <div class="sideContent">     
      <router-outlet></router-outlet>
    </div>
  <!-- </div> -->
</div>

<ng-template #timeoutModal let-c="close" let-d="dismiss" id="timeoutModal">
  <div class="modal-header">
    <h4 class="modal-title">Your session is about to expire!</h4>
  </div>
  <div class="modal-body">
    <p>Your session is about to timeout in
      <span style="font-weight: bold">{{countdown}} {{(countdown > 1) ? 'seconds' : 'second'}}</span> due to inactivity.
    </p>
    <p>Do you want to stay signed in?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default btn-sm" (click)="timeOutLogOff()">No, Log Off</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="timeOutStayConnected()">Yes, Stay Connected</button>
  </div>
</ng-template>
<toaster-container></toaster-container>
