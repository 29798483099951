
    <ngb-toast
        *ngFor="let toast of toastService.toasts"
        [class]="toast.classname"
        [autohide]="toast.autohide"
        [delay]="toast.delay || 5000"
        (hide)="toastService.remove(toast)"
        >
        <div style="width: 300px; min-height: 50px; display: flex; align-items: center; padding: 0 3px;">
            <span style="margin-right: 10px; opacity: 0.8;">
                <!-- success --><svg *ngIf="toast.type === 'success'" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#ffffff"/></svg>
                <!-- info white--><svg *ngIf="toast.type === 'primary' || toast.type === 'secondary' || toast.type === 'info' || toast.type === 'dark'" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" fill="#ffffff"/></svg>
                <!-- info dark--><svg *ngIf="toast.type === 'light' || toast.type === 'white'" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" fill="#000000"/></svg>
                <!-- danger --><svg *ngIf="toast.type === 'danger'" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" fill="#ffffff"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                <!-- warning --><svg *ngIf="toast.type === 'warning'" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"fill="#ffffff"/></svg>
            </span>
            <span style="display: flex; flex-direction: column; margin-right: 10px;">
                <span style="font-weight: bold;">{{toast.headerText}}</span>
                <span style="">{{toast.messageText}}</span>
            </span>
            <span *ngIf="toast.closable" style="font-size: 24px; font-weight: bold; cursor: pointer; opacity: 0.8; margin-left: auto;" (click)="toastService.remove(toast)">&times;</span>
        </div>
    </ngb-toast>
  