import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import * as _ from "lodash";

import { SWCoreServices } from '../../../../../../core/services/core.services';
import { AppSettings } from '../../../../../../core/configs/core.config';
import { AppConstant } from '../../../../../../core/constant/core.constant';
import { BEMSService } from '../../../services/BEMS.service';

@Injectable()
export class AccountListFilterService {
  
  constructor(private swCoreServices : SWCoreServices, private stateRegistrationService: BEMSService) { }

  getAccountList(formData : any, pageDetails: string){
    //let url: string = AppSettings._tampsFilterService;
    let url: string = AppSettings._hoFilterService,
      appType = this.stateRegistrationService.appType,
      header = {
        'Content-Type':'application/json'
      };
    
    formData['appName'] = appType;
    if (formData.searchText === null) formData.searchText = '';
    return Observable.create((observer)=>{

      this.swCoreServices.postData(url, formData, header)
                        .subscribe(result => {
                            if (result.data && result.data.records && result.data.records.length > 0) {
                              observer.next(result.data);
                              observer.complete();
                            } else {
                              //observer.error(AppConstant.NO_RECORD_FOUND,result.data);
                              observer.complete();
                              //throw Observable.throw(AppConstant.NO_RECORD_FOUND);
                            }
                        });

    });
    
  }
}
