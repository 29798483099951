import { Injectable, OnInit, Input, ElementRef } from "@angular/core";
import { AppSettings } from "../configs/core.config";
import { SWCoreServices } from "./core.services";
import { catchError, map, tap } from "rxjs/operators";
import { AppConstant } from "../constant/core.constant";
import { ToastService } from "../components/ui-components/toaster/toaster.service";
@Injectable()
export class userPreferenceService {
  constructor(
    private sharedService: SWCoreServices,
    private toasterService: ToastService
  ) {
    this.toasterService = toasterService;
  }

  saveFavoriteContact(
    sendParam: object,
    toastType: string,
    toatHeader: string,
    toastMessage: string
  ) {
    if (toastType == "info") {
      this.toasterService.toastInfo(toatHeader, toastMessage);
    } else if (toastType == "warning") {
      this.toasterService.toastWarning(toatHeader, toastMessage);
    } else if (toastType == "success") {
      this.toasterService.toastSuccess(toatHeader, toastMessage);
    }
    else if (toastType == "error") {
      this.toasterService.toastDanger(toatHeader, toastMessage);
    }
    return this.sharedService
      .httpCall(AppConstant.savePreference, sendParam)
      .pipe(map(data => data));
  }

  saveUserPreference(sendParam: object) {
    this.toasterService.toastSuccess(
      "Save Preference",
      "Your preference is saved successfully"
    );
    return this.sharedService
      .httpCall(AppConstant.savePreference, sendParam)
      .pipe(map(data => data));
  }

  saveKMTagPreference(sendParam: object) {
    // var url: string = AppSettings.savePreference;
    //this.toasterService.popupMessage('success', 'Save Preference', 'Your preference is saved successfully', 'toast-bottom-center');
    return this.sharedService
      .httpCall(AppConstant.savePreference, sendParam)
      .pipe(map(data => data));
  }

  getUserPreferenceFromComponent(getParam: Object) {
    // var url: string = AppSettings.getPrefereneFromComponent;
    return this.sharedService
      .httpCall(AppConstant.userPreferenceApplicationAndComponent, getParam)
      .pipe(
        map(response => {
          if (!response.data) {
            response.data = [];
          }
          return response;
        })
      );
  }
}
