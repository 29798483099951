import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SWCoreServices } from "src/core/services/core.services";
import { AppStateService } from './app-state.service';
import * as constant from '../constants/app.constant';
import { AgentModel } from "../models/agent.model";
import { RESTServices } from 'src/core/services/rest.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  isLocal: boolean;  // this needs to be removed once integration is done
agentInformation: AgentModel = this.createEmptyAgent();
  responseValue: any = [];
  constructor(
      private _http: HttpClient,
      private _appState: AppStateService,
      private _swCoreServices: SWCoreServices,
      private REST: RESTServices
    ) {this.getLoggedInUserInfo(); }

    createEmptyAgent() {
      return {
        Domain: "",
        UserName: "",
        EmailAddress: "",
        FirstName: "",
        LastName: "",
        DisplayName: "",
        AccountEnabled: false,
        AccountExpirationDate: "",
        AccountLockoutTime: null,
        Groups: [],
        ClientWorksUserName: "",
        JwtToken: "",
        SessionID:"",
        AssignedRoles: {
          IsServiceProfessional: false,
          IsServiceManager: false,
          IsTSHDServiceProfessional: false,
          IsTSHDServiceManager: false
        }
      };
    }
    getLoggedInUserInfo() {
      if (localStorage.getItem(constant.agentLocalStoargeName) !== null) {
        this.agentInformation = JSON.parse(localStorage.getItem(constant.agentLocalStoargeName));
      }
  
      return this.agentInformation;
    }
  fetchURI(apiName): string {
    // this.isLocal = location.hostname === "localhost" || location.hostname === "127.0.0.1";
    // if(this.isLocal) {
    //   return constant.LOCAL_DATA[apiName];
    // }
    return constant.API_DATA[apiName];
  }
  getAllQuestionnaire(): Observable<any> {
    return this.get(constant.GET_QUESTIONNAIRE)
                      .pipe(map((r:any)=>{return {questions: r}})) 
  }


  get(method) {
    let HeaderData = { 
      'Content-Type': 'application/json'      
    };
    return this._swCoreServices
        .get(this.fetchURI(method),HeaderData)        
        .pipe(
          map(res => res.data || {}),

        catchError(err => {
          // Handle specific error for this call
          return of(undefined);
        })
    )
  }

  post(method, params) {
    return this._swCoreServices
      .post(this.fetchURI(method), params)
      .pipe(map(res => res.data || {}),
      catchError(err => {
        // Handle specific error for this call
        return of(undefined);
      })
    )
  }

  
  /**
   * get a list of roles list.
   * @input null
   * @return Observable
   */
  getAQRoles(): Observable<any> {
    
    return this.get(constant.GET_ROLES)
                    .pipe(map((r: any) => {return {roles: r}}))
                    .pipe(tap(r => this._appState.appState = r));
  }

  /**
   * get a list of categories list.
   * @input null
   * @return Observable
   */
  getAQCategories(): Observable<any> {
    return this.get(constant.GET_CATEGORIES)
      .pipe(map((r: any) => { return { categories: r }; }))
      .pipe(tap(r => this._appState.appState = r));
  }

  /**
   * get a details of advisors.
   * @input taskId
   * @return Observable
   */

  // getUserInfo(taskid): Observable<any> { 
  //   let url =  constant.GET_USER_INFO +'/'+ taskid;  
  //   return this.get(url);
  // }

  /**
   * get a list of response/control types.
   * @input null
   * @return Observable
   */
  getResponseTypes(): Observable<any> {
    return this.get(constant.GET_RESPONSE_TYPES)
                    .pipe(map((r: any) => {return {responseTypes: r}}))
                    .pipe(tap(r => this._appState.appState = r));
  }

  /**
   * get a list of all departments.
   * @input null
   * @return Observable
   */
  getDepartments(): Observable<any> {
    return this.get(constant.GET_DEPARTMENTS)
                    .pipe(map((r: any) => {return {departments: r}}))
  }

  /**
   * get list of filtered questionnaire
   * @param filterValue 
   * @return observable
   */
  getFilteredQuestionnaire(filterValue): Observable<any> {
    const observableRes = this.isLocal ? this.get(constant.GET_QUESTIONNAIRE): this.post(constant.GET_QUESTIONNAIRE, filterValue);
    return observableRes
                    .pipe(map((r: any) => {return {questionnaire: r.questions}}))
                    .pipe(tap(r => this._appState.questionnnaire = r));
  }

  deleteQuestion(question): void {
    this._appState.questionnaire$.subscribe(aq => {
    });
  }
}
