import { AppSettings } from "../configs/core.config";
import { AppConstant } from "../constant/core.constant";
import { SWCoreServices } from "./core.services";
import { InvestorSearchData } from "../models/investor-search-data.model";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class InvestorSearchService {
  constructor(private swCoreServices: SWCoreServices) {}

  getAccountDetails(
    formKey: string,
    formText: string,
    pn: number,
    ps: number,
    optionalTenantType = null
  ): Observable<any> {
    /* CW Account Search
        let url = AppSettings.accountSearchCW;
        let repList = this.sharedService.repListFormatted;

        let formData = {};
        formData[formKey] = { "in": formText };
        formData["loc"] = { "in": "LPL" };
        formData["userRepList"] = { "in": repList };

        let payload = { "page": { "pn": pn, "ps": ps }, "search":  formData , "sort": { "cols": formKey + "|asc" }, "display": { "cols": "repID,lplAcct,regBlock,clntGrp,acctName,clientGroupName,ssntin, acctClass, accountStatus,loc,sponsrAcct,accountID,clientID" }, "listId": "0CDE8FC7-87C9-42F9-9C0D-A597630A0EC5" };

        return this.sharedService
            .postCW(url, payload)
            .map(response => {
                if (response.data) {
                    return response.data[0];
                } else {
                    throw new Error(AppConstant.NO_RECORD_FOUND);
                }
            })
        */

    /* SW Account Search */
    let payload = {};
    payload["limit"] = 100;

    payload["matchAll"] = true;
    payload["locationLPLAccounts"] = true; //For LPL Accounts only

    payload["tenantType"] = optionalTenantType || "LPL";
    payload["repID"] = "";
    payload["userName"] = "";
    payload[formKey] = formText;

    return this.swCoreServices
      .httpCall(AppConstant.searchInvestor, payload)
      .pipe(
        map(response => {
          if (response.data) {
            return response.data;
          } else {
            throw new Error(AppConstant.NO_RECORD_FOUND);
          }
        })
      );
  }
}
