<div class="modal-header" *ngIf="feedbackParam.type == 'advisor'">
  <h4 class="modal-title pull-left">Submit Feedback On-Behalf of {{advisorInfo.name}} ({{advisorInfo.repId}}) </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="dismissModal()">
    <span aria-hidden="true" class="close-btn">&times;</span>
  </button>
</div>
<div class="modal-header medalliaLPLatWorkFeedbackForm-header" *ngIf="feedbackParam.type == 'employee'"
  [ngClass]="{'h-thankyou' : isThankYou}">
  <h4 class="modal-title pull-left">Submit Your Feedback to LPL
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="dismissModal()">
    <span aria-hidden="true" class="close-btn">&times;</span>
  </button>
</div>
<div class="modal-body modal-iframe-height" *ngIf="!isThankYou">
  <div id="{{advisorFormId}}" [attr.id]="advisorFormId" class="medalliaAdvisorSendFeedbackForm"
    *ngIf="feedbackParam.type == 'advisor'">
  </div>
  <div id="{{employeeFormId}}" [attr.id]="employeeFormId" class="medalliaEmployeeFeedbackForm"
    *ngIf="feedbackParam.type == 'employee'">
  </div>
</div>
<div class="modal-footer footer-label" *ngIf="feedbackParam.type == 'advisor'">
  LPL's Advisor Feedback team and the vendor supporting our Continuous Improvement
  initiatives will see your responses. As a result, you may be contacted directly by teams responsible for improving
  your experience with LPL. If you are experiencing a technical issue, please contact the Technical Support Help Desk
  at: 800-877-7210, ext. 6357

  <!-- <a target="_blank" rel="noopener" href="https://www.lpl.com/disclosures/privacy-security/online-privacy-policy.html">Online Privacy
    Disclosure</a> -->

</div>
