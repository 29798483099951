import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'  // <--provides this service in the root ModuleInjector
})
export class Toaster {
  constructor(private toastr: ToastrService) {
    //
  }
  showSuccess(msg: string) {
    this.toastr.success(msg);
  }
  showError(msg: string) {
    this.toastr.error(msg);
  }
  showWarning(msg: string) {
    this.toastr.warning(msg);
  }
}
