import { AppSettings } from "../configs/core.config";
import { AppConstant } from "../constant/core.constant";
import * as moment from "moment";
import "moment-timezone";

export class TimeFunctions {
  static getTimezone(isAbbriviations = true) {
    return isAbbriviations
      ? moment.tz(moment.tz.guess()).zoneAbbr()
      : moment.tz.guess();
  }

  static date(d: any = new Date()) {
    return moment(d);
  }

  static dateFormat(d: any = new Date(), format = "MM/DD/YYYY") {
    return TimeFunctions.date(d).format(format);
  }

  static dateParseZFormat(d: any = new Date(), format = "MM/DD/YYYY") {
    return moment.parseZone(d).format(format);
  }

  static dateUTCFormat(
    d: any = new Date(),
    format = "MM/DD/YYYY",
    isTimeZoneCalculate = false
  ) {
    return !isTimeZoneCalculate
      ? moment.utc(d).format(format)
      : moment
          .utc(d)
          .tz(TimeFunctions.getTimezone(false))
          .format(format);
  }

  static dateDiff(startDate, endDate) {
    return startDate.diff(endDate);
  }

  static dateDuration(startDate, endDate, type) {
    //type = seconds, minutes etc.
    const duration = moment.duration(
      TimeFunctions.dateDiff(startDate, endDate)
    );
    return type == "seconds" ? duration.asSeconds() : duration.asMinutes();
  }

  static cloneRef(d: any = new Date()) {
    return TimeFunctions.date(d).clone();
  }

  static getSubsDate(
    d: any = new Date(),
    data = 1,
    unit: moment.unitOfTime.DurationConstructor = "days",
    format = "MM/DD/YYYY"
  ) {
    // unit values
    //  'days',
    //  "months",
    //  "years",
    //  "quarters",
    //  "weeks",
    //  "hours",
    //  "minutes",
    //  "seconds",
    //  "milliseconds"
    return moment(d, format).subtract(data, unit);
  }

  static getAddDate(
    d: any = new Date(),
    data = 1,
    unit: moment.unitOfTime.DurationConstructor = "days",
    format = "MM/DD/YYYY"
  ) {
    // unit values
    //  'days',
    //  "months",
    //  "years",
    //  "quarters",
    //  "weeks",
    //  "hours",
    //  "minutes",
    //  "seconds",
    //  "milliseconds"
    return moment(d, format).add(data, unit);
  }

  static setStartOfTime(
    d: any = new Date(),
    unit: moment.unitOfTime.DurationConstructor = "month"
  ) {
    // unit values
    //  "year",
    //  "month",
    //  "quarter",
    //  "week",
    //  "isoWeek",
    //  'day',
    //  "date",
    //  "hour",
    //  "minute",
    //  "second"
    return moment(d).startOf(unit);
  }
}
