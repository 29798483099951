
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SWCoreServices } from '../services/core.services';
import { AppSettings } from '../configs/core.config';
import { AppConstant } from '../constant/core.constant';

//import 'rxjs/add/observable/fromPromise';
//import { fromPromise } from 'rxjs/observable/fromPromise';
import { from } from 'rxjs';

@Injectable()
export class HozXhrInterceptor implements HttpInterceptor {

    userId: string;

    constructor(private sharedservice: SWCoreServices) { }

     

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handleAccess(request, next));
    };

    private async handleAccess(request: HttpRequest<any>, next: HttpHandler):
        Promise<HttpEvent<any>> {
        
        const sideMenu = /clrworks\/config/gi;
        
        let changedRequest = request.clone();
        //console.log('request.url ', request.url)
        if (request.url.search(sideMenu) === -1 ) {
            // HttpHeader object immutable - copy values
            const headerSettings: { [name: string]: string | string[]; } = {};

            for (const key of request.headers.keys()) {
                headerSettings[key] = request.headers.getAll(key);
            }; 

            // headerSettings['hoz-jwt'] = this.sharedservice.agentInformation.JwtToken;
            // headerSettings['Content-Type'] = 'application/json';

            //Adding secret code & Client id
            //headerSettings['client_id']= AppSettings.CPS_CLIENT_ID.toString();
           // headerSettings['client_secret']= AppSettings.CPS_SECRET.toString();
    
            const newHeader = new HttpHeaders(headerSettings);

            changedRequest = request.clone({
                headers: newHeader
            });
        }

        
         
        return next.handle(changedRequest).toPromise();
    }

}
