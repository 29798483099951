import { AdminSettings } from './adminSettings.constant';

export class AppConstant extends AdminSettings {
  public static preferenceApplicationConfig = "applicationConfig";
  public static GLOBAL_HEADER_NAME = "";
  public static GLOBAL_APP_NAME = "CLRWorks";
  public static serviceworksRouteName = "";
  public static homeAppLauncherRouteName = "#/home";
  public static currentAppRouteName = "#/branchexams";

  public static REDIRECTOR_APP_NAME = "branchexams";
  public static APP_NAME_LOCAL_STORAGE_KEY = "seedApplicationVersion";
  public static VERSION_API_APP_NAME = "CLRWorks";
  public static VERSION_API_MODULE_NAME = "branchexams";

  /* TAMPS list filter messages */
  public static MIN_REP_ID_MESSAGE = "Minimum of 4 characters";

  public static SW_APP_TITLE = [
    { url: "default", title: "ServiceWorks" },
    { url: "dashboard", title: "Agent Dashboard - ServiceWorks" },
    { url: "advisor", title: "Advisor 360 - ServiceWorks" },
    { url: "reporting", title: "Reporting - ServiceWorks" },
    { url: "signout", title: "ServiceWorks - Logged Out" },
  ];

  public static SERVICE_REQ_BACK_LOG_TITLE = "Service Request Backlog";
  public static ADVISOR_JSON_PATH = "core/data/advisor.json";
  public static REPORTING_JSON_PATH = "core/data/reporting.json";
  public static DASHBOARD_JSON_PATH = "core/data/dashboard.json";
  public static APP_LAUNCHER_TILES_JSON_PATH =
    "core/data/app-launcher-tiles.json";

  public static GRID_COLUMN = {
    NEW_OPEN_ACCOUNT: "core/data/new-account-grid.json",
    NEW_OPEN_LINKS: "core/data/new-links.json",
    RTT: "core/data/rtt-grid-column.json",
    REPORTING_TSHD: "core/data/reporting-tshd.json",
    SERVICEMANAGER: "core/data/service-manager-grid-column.json",
    REPORTING_SIEBEL: "core/data/reporting-siebel.json",
    REPORTING_CALL: "core/data/reporting-call-driver.json",
    COMMISION_STRUCTURE: "core/data/commission-structure.json",
    COMMISION_PAYOUT: "core/data/commission-payout.json",
    NOTIFICATIONS_ADV_SEARCH: "core/data/notifications-adv-search-grid.json"
  };

  public static PAGE_SIZE = 100;
  public static PAGE_SIZE_LIST = [5, 10, 20, 50, 100];
  public static WINDOW_CLOSE_CONFIRM_MSG = "Do you want to exit this window?";
  public static TSHDSR_TITLE = "All SR Volume by Area - Last Hour";
  public static Siebel_TITLE = "Daily SRs by Task Originator and Area";
  public static ReportChart_TITLE = "Chart - SRs by Task Originator and Area";
  public static ReportCallDriver_TITLE = "Top 2 Call Drivers Breakdown";
  public static KNOWN_ISSUE_TITLE = "Known Issues";
  public static ISSUES_MAP_TITLE = "Issues Map";
  public static ALERTS_TITLE = "ServiceWorks Alerts";
  public static ALERTS_CLIENTWORKS_TITLE = " ClientWorks Alerts";
  public static BUTTON_OPEN = "Open";
  public static BUTTON_FOLLOW_UP = "Follow Up";
  public static BUTTON_FOLLOW_UP_JOINED = "FollowUp";
  public static BUTTON_CLOSED = "Closed";
  public static BUTTON_SIGNED = "Signed";
  public static REQUEST_DESCRIPTION = "Description : ";
  public static REQUEST_NO_DATA = "No data available";
  public static REQUEST_LOADING_DATA = "Loading Data";
  public static CALLER_MODAL_SSN = "SSN: XXX-XX-";
  public static HEADER_TITLE = "Service Desktop";
  public static HEADER_SIGNED_IN = "Signed in as";
  public static HEADER_EDIT = "Edit";
  public static HEADER_SAVE = "Save";
  public static HEADER_SIGN_OUT = "Sign Out";
  public static ALERTS_ACTIVITIES = [
    {
      date: "03/11/2017",
      text: "Year End Results Declaration - Feb 21, 2016 "
    },
    { date: "01/25/2017", text: "Year End Results Declared" },
    {
      date: "01/21/2017",
      text: "DOL Final Rule - Release Date - April 8, 2016 "
    },
    { date: "03/01/2017", text: "Town Hall DOL - Dan" }
  ];

  public static IMAGE_URLS = {
    IMG_INFO: "assets/images/info-squared-38.png"
  };
  public static NO_RECORD_FOUND = "No Record Found";
  public static NOT_AVAILABLE = "Not Available";
  public static UNKNOWN_ERROR = "Unknown Error";
  public static NIGO = "Not in Good Order";
  public static ADVISOR_TEXT = "Advisor";
  public static CLIENT_TEXT = "Client";
  public static LA_TEXT = "Licensed Admin";
  public static NLA_TEXT = "Non-Licensed Admin";
  public static Prospect_TEXT = "Prospect";

  public static UNIDETIFIED_CALLER_TEXT = "Unidentified Caller";
  public static SECOND_TEXT = " second";
  public static SECONDS_TEXT = " seconds";
  public static MINUTE_TEXT = " minute";
  public static MINUTES_TEXT = " minutes";
  public static ACCOUNT_NUMBER_TEXT = "Account Number";
  public static REP_ID_TEXT = "Advisor Rep Id";

  public static userPreferenceApplicationId = "SW";
  /*FOR SERVICE REQUEST FOR AGENT PAGE*/
  public static savePrefKeySRB = "Filter";
  public static sortPrefKeySRB = "sortFilter";
  public static statusPrefKeySRB = "statusFilter";
  public static SRTypesPrefKeySRB = "srTypeFilter";
  public static searchTextPrefKeySRB = "searchTextFilter";
  public static userPreferenceComponentIdSRB = "SRBacklog";
  public static userPreferenceComponentIdSRH = "SRHistory";
  public static savePrefKeySRH = "Filter";
  public static statusPrefKeySRH = "statusFilter";

  /*FOR issue map FOR AGENT PAGE*/
  public static carousalPrefKeySRB = "carousalFilter";
  public static carousalDropPrefKeySRB = "carousalDropdownFilter";
  public static carousalTaskOriginatorDropPrefKeySRB =
    "carousalTaskOriginatorDropdownFilter";
  public static userPreferenceComponentIdIMP = "IssueMap";

  /* FOR ALERTS */
  public static alerts = {
    timeInterval: 1000 * 60
  };

  /* FOR SERVICE REQUEST STAT */
  public static serviceRequestStat = {
    timeInterval: 100
  };

  /* FOR CALL STATISTICS */
  public static callStatistics = {
    timeInterval: 1000 * 60 * 10
  };

  /* FOR SERVICE MANAGER CALL MATRIX */
  public static serviceManagerCallMatrix = {
    title: "Service Manager Call Metrics",
    timeInterval: 1000 * 60 * 10
  };

  /* FOR HEADER */
  public static header = {
    signOut: "Sign Out",
    refreshAll: "Refresh All"
  };

  /*FOR ACTIVITY LOG*/
  public static activityLocalStorage = "activity_stream_content";
  public static activityLastStoredLocalStorage = "activity_stream_last_saved";
  public static CallReceivedActivityLog = "Received Call from ";
  public static CallReceivedActivityLogTitle = "Call Received";
  public static QuickTicketCreatedActivityLogTitle = "Ticket Created";
  public static QuickTicketCreatedFailedActivityLogTitle =
    "Ticket Creation Failed";
  public static BORDQuickTicketReqCreatedActivityLogTitle =
    "Restriction Lift Request";
  public static BORDRestrictionClickActivityLogTitle =
    "Restriction View Request";

  public static BORDQuickTicketRequestFailedActivityLogTitle =
    "Restriction Lift Request Failed";

  public static activityTypeForClickRestriction = "restrictionClicked";
  public static QuickTicketSaveForLaterActivityLogTitle = "Ticket Pending";
  public static pageNamePendingTicket = "activity_stream";
  public static pendingQuickTicketLocalStorageIndex = "pending";
  public static completeQuickTicketLocalStorageIndex = "completed_ticket";
  public static activityTypeForPendingTicket = "ticketpending";
  public static activityTypeForCreatedTicket = "ticketcompleted";
  public static activityTypeForFailTicket = "ticketfailed";
  public static callerNameStaticConstant = "{CALLERNAME}";
  public static SRNumberStaticConstant = "{SR}";
  public static dateTimeStaticConstant = "{DATETIME}";
  public static NewTicketSaveActivityLog =
    "Ticket added for " +
    AppConstant.callerNameStaticConstant +
    " with SR# " +
    AppConstant.SRNumberStaticConstant;
  public static NewTicketSaveFailActivityLog =
    "Ticket Failed for " +
    AppConstant.callerNameStaticConstant +
    " with SR# " +
    AppConstant.SRNumberStaticConstant;
  public static NewTicketSaveLaterActivityLog =
    "Ticket pending for " +
    AppConstant.callerNameStaticConstant +
    " with SR# " +
    AppConstant.SRNumberStaticConstant;
  public static TicketPendingActivityLog =
    "Ticket pending for " + AppConstant.callerNameStaticConstant + " with ";
  public static TicketCreatedActivityLog =
    "Ticket added for " + AppConstant.callerNameStaticConstant + " with ";
  public static EmailSentTitle = "Email Sent";
  public static activityTypeForSentEmail = "sentemail";

  /*FOR Search Contacts Component*/
  public static userPreferenceComponentIDSC = "searchContacts";
  public static savePrefKeySCF = "favoriteContacts";

  public static INVESTOR_SEARCH_ENUM = [
    {
      id: "partialAccountNumber",
      text: "LPL Account Number"
    },
    {
      id: "partialAccountName",
      text: "Account Name"
    },
    {
      id: "partialSSNTIN",
      text: "Social Security Number / Tax ID"
    },
    {
      id: "partialClientName",
      text: "Client Name"
    },
    {
      id: "partialClientGroupName",
      text: "Client Group Name - Associated with the account"
    }
  ];

  public static INVESTOR_SEARCH_DIRECT_BUSINESS_ENUM = [
    {
      id: "partialAccountNumber",
      text: "Sponsor Account"
    },
    {
      id: "partialAccountName",
      text: "Account Name"
    },
    {
      id: "partialSSNTIN",
      text: "SSN / Tax ID"
    },
    {
      id: "partialFamily",
      text: "Family"
    },
    {
      id: "partialClientName",
      text: "Client"
    }
  ];

  /*Activity Feed Category*/
  public static ACTIVITY_FEED_INFO = "info";
  public static ACTIVITY_FEED_WARNING = "warning";
  public static ACTIVITY_FEED_SUCCESS = "success";
  public static ACTIVITY_FEED_DANGER = "danger";

  public static freezeSettingAdvisor = [
    "investor-information",
    "knowledge-management"
  ];

  /* CALLER AUDIT LOG TITLES */
  public static CALLER_AUDIT_LOG_TITLE = {
    onLoad: "Caller Audit - IVR Caller Info",
    onSearch: "Caller Audit - Caller Search",
    onAdminSelect: "Caller Audit - ADMIN Select",
    onAuthentication: "Caller Audit - Agent Authentication",
    onOutgoing: "Caller Audit - Outgoing Call",
    callerDetails: "Audit Log – Caller Details",
    onAccountSelect: "Caller Audit - ACCOUNT Select"
  };

  public static ERR_MSG_MAIN_1 = "Oops! Something went wrong.";
  public static ERR_MSG_SUB_1 = "Please contact system administrator for help.";
  public static ERR_MSG_MAIN_2 =
    "You are not authorized to view the application.";

  public static CALLER_ACTION_NO_DATA =
    "No accounts opened in the last 30 days";
  public static QUICK_LINKS_NO_DATA = "No activity in ClientWorks";

  public static ReportingTimeTitle = "Today's Date & Time";
  public static ReportingTSHD_NO_DATA = "No data available";

  public static LAST_REFRESH = "Last Data Refresh at: ";
  public static ReportingSIEBEL_NO_DATA = "No data available";
  public static ReportingCallDriver_NO_DATA = "No data available";

  public static cwIframeStoargeName = "cwIframeLoaded";
  public static cwTokenExpirationHours = 1;

  public static reportTaskOriginatorDropdownLabel = "Select Task originator";
  public static reportAreaDropdownLabel = "Select Area";
  public static reporSubaAreaDropdownLabel = "Select Sub Area";

  public static CommissionStructure_NO_DATA = "No data available";

  public static agentLocalStoargeName = "agentInformation";
  public static taskoriginatorLocalStorageName = "taskoriginatorInformation";

  public static displayQTCFlag = "displayQTC";

  public static localStorageFirmName = "swcwLoginFirm";
  public static localStorageDateName = "swcwLoginDateTime";
  public static localStorageAuthName = "swcwLoginAuth";

  public static authStoreTimeDiff = 1; /// hours
  public static signoutHeading = "Service Works";

  //################ CW log & logout related contant ##############/////
  public static cwLPLFirm = "LPL";
  public static cwAXAFirm = "AXA";
  public static cwNONEFirm = "None";
  public static cwAuthCookieName = "Auth";
  public static cwIframeParentId = "createIframe";
  public static cwIframeId = "clientworksiframe";
  public static cwDropdown = [
    {
      label: AppConstant.cwLPLFirm,
      value: AppConstant.cwLPLFirm
    },
    {
      label: AppConstant.cwAXAFirm,
      value: AppConstant.cwAXAFirm
    },
    {
      label: AppConstant.cwNONEFirm,
      value: AppConstant.cwNONEFirm
    }
  ];

  public static reportingTSHD_Export_File = "TSHD - All SR Volume by Area ";
  public static reportingSiebel_Export_File =
    "Siebel Daily SRs by Task Originator and Area ";
  public static reportingCall_Export_File = "Top 2 Call Drivers Breakdown ";

  public static usernameErrorMessageTitle = "Username missing";
  public static usernameErrorMessageBody =
    "Username does not exist for this user, please try another user";
  public static usernameErrorMessagePos = "toast-bottom-center";
  public static copyTicketNumberMessageTitle = "Copy";
  public static copyTicketNumberMessageBody =
    "Ticket number is successfully copied to your clipboard";
  public static copyTicketNumberMessagePos = "toast-bottom-center";
  public static routenotAuthorizeErrorMessageTitle = "Not Authorized";
  public static routenotAuthorizeErrorMessageBody =
    "You are not authorized to view [ROUTENAME] page";
  public static routenotAuthorizeErrorMessagePos = "toast-bottom-center";

  public static quickTicketDropdownLabelLoadingTime = 40;
  public static quickTicketDropdownLoadingTime = 50;
  public static quickTicketDropdownResetLoadingTime = 30;

  /*SESSION TIMEOUT VARIABLE*/
  public static IDLE_TIMEOUT_TIMER = 30; // in seconds
  public static LOG_OFF_TIMEOUT_TIMER = 60; // in seconds
  public static SESSION_TIMER_START_TIME = "05:00 AM";
  public static SESSION_TIMER_END_TIME = "05:00 PM";
  public static BACKGROUND_SESSION_INTERVAL_IN_MILLISECONDS = 10000;
  /*End SESSION TIMEOUT*/

  public static IDLE_TIMEOUT_TIMER_LOCAL_STORAGE = "idle";
  public static IDLE_TIMEOUT_TIMER_LOCAL_STORAGE_VALUE_ARRAY = [
    "true",
    "false",
    "signout"
  ];

  public static personaLocalStorage = "agentPersona";


  public static duration90times = [
    {
      timing: "9:00 AM to 10:30 AM",
      startTime: "9:00 AM",
      endTime: "10:30 AM"
    },
    {
      timing: "10:30 AM to 12:00 PM",
      startTime: "10:30 AM",
      endTime: "12:00 PM"
    },
    {
      timing: "12:00 PM to 1:30 PM",
      startTime: "12:00 PM",
      endTime: "1:30 PM"
    },
    { timing: "1:30 PM to 3:00 PM", startTime: "1:30 PM", endTime: "3:00 PM" },
    { timing: "3:00 PM to 4:30 PM", startTime: "3:00 PM", endTime: "4:30 PM" },
    { timing: "4:30 PM to 6:00 PM", startTime: "4:30 PM", endTime: "6:00 PM" }
  ];

  // cobrowse session timeout
  /*SESSION TIMEOUT VARIABLE*/
  public static CoBrowse_IDLE_TIMEOUT_TIMER = 600; // in seconds
  public static CoBrowse_LOG_OFF_TIMEOUT_TIMER = 60; // in seconds
  public static CoBrowse_BACKGROUND_SESSION_INTERVAL_IN_MILLISECONDS = 10000;

  public static Chatbot_IDLE_TIMEOUT_TIMER = 600000; // in seconds
  public static Chatbot_LOG_OFF_TIMEOUT_TIMER = 60; // in seconds
  public static Chatbot_BACKGROUND_SESSION_INTERVAL_IN_MILLISECONDS = 10000;
  /*End SESSION TIMEOUT*/

  public static CoBrowse_IDLE_TIMEOUT_TIMER_LOCAL_STORAGE = "coBrowse-idle";
  public static CoBrowse_IDLE_TIMEOUT_TIMER_LOCAL_STORAGE_VALUE_ARRAY = [
    "true",
    "false"
  ];
  public static Chatbot_IDLE_TIMEOUT_TIMER_LOCAL_STORAGE = "chatbot-idle";
  public static Chatbot_IDLE_TIMEOUT_TIMER_LOCAL_STORAGE_VALUE_ARRAY = [
    "true",
    "false"
  ];

  public static Alert_Message_Year_Range_Account_and_Request_Grid = 1;

  //Dummy rep Ids
  public static dummyLPLRepArray = ["LPL", "Z001"];
  public static dummyAXARepArray = ["AXA", "Z007"];
  public static dummyReplicateLPLRep = "CCCA";
  public static dummyReplicateAXARep = "AXAB";
  public static dummyRepDescription = {
    LPL: {
      masterRepID: "LPL",
      repID: "LPL",
      name: "LPL Client / Contra Firm",
      callPopName: "LPL Client/Contra Firm"
    },
    AXA: {
      masterRepID: "AXA",
      repID: "AXA",
      name: "AXA Client / Contra Firm",
      callPopName: "AXA Client/Contra Firm"
    },
    Z001: {
      masterRepID: "Z001",
      repID: "Z001",
      name: "LPL Transfer",
      callPopName: "LPL Transfer"
    },
    Z007: {
      masterRepID: "Z007",
      repID: "AXA",
      name: "AXA Client/Contra Firm",
      callPopName: "AXA Transfer"
    }
  };

  public static US_STATES = [
    {
      name: "Alabama",
      abbr: "AL"
    },
    {
      name: "Alaska",
      abbr: "AK"
    },
    {
      name: "Arizona",
      abbr: "AZ"
    },
    {
      name: "Arkansas",
      abbr: "AR"
    },
    {
      name: "California",
      abbr: "CA"
    },
    {
      name: "Colorado",
      abbr: "CO"
    },
    {
      name: "Connecticut",
      abbr: "CT"
    },
    {
      name: "Delaware",
      abbr: "DE"
    },
    {
      name: "Florida",
      abbr: "FL"
    },
    {
      name: "Georgia",
      abbr: "GA"
    },
    {
      name: "Hawaii",
      abbr: "HI"
    },
    {
      name: "Iowa",
      abbr: "IA"
    },
    {
      name: "Idaho",
      abbr: "ID"
    },
    {
      name: "Illinois",
      abbr: "IL"
    },
    {
      name: "Indiana",
      abbr: "IN"
    },
    {
      name: "Kansas",
      abbr: "KS"
    },
    {
      name: "Kentucky",
      abbr: "KY"
    },
    {
      name: "Louisiana",
      abbr: "LA"
    },
    {
      name: "Massachusetts",
      abbr: "MA"
    },
    {
      name: "Maryland",
      abbr: "MD"
    },
    {
      name: "Maine",
      abbr: "ME"
    },
    {
      name: "Michigan",
      abbr: "MI"
    },
    {
      name: "Minnesota",
      abbr: "MN"
    },
    {
      name: "Missouri",
      abbr: "MO"
    },
    {
      name: "Mississippi",
      abbr: "MS"
    },
    {
      name: "Montana",
      abbr: "MT"
    },
    {
      name: "North Carolina",
      abbr: "NC"
    },
    {
      name: "North Dakota",
      abbr: "ND"
    },
    {
      name: "Nebraska",
      abbr: "NE"
    },
    {
      name: "Nevada",
      abbr: "NV"
    },
    {
      name: "New Hampshire",
      abbr: "NH"
    },
    {
      name: "New Jersey",
      abbr: "NJ"
    },
    {
      name: "New Mexico",
      abbr: "NM"
    },
    {
      name: "New York",
      abbr: "NY"
    },
    {
      name: "Ohio",
      abbr: "OH"
    },
    {
      name: "Oklahoma",
      abbr: "OK"
    },
    {
      name: "Oregon",
      abbr: "OR"
    },
    {
      name: "Pennsylvania",
      abbr: "PA"
    },
    {
      name: "Rhode Island",
      abbr: "RI"
    },
    {
      name: "South Carolina",
      abbr: "SC"
    },
    {
      name: "South Dakota",
      abbr: "SD"
    },
    {
      name: "Tennessee",
      abbr: "TN"
    },
    {
      name: "Texas",
      abbr: "TX"
    },
    {
      name: "Utah",
      abbr: "UT"
    },
    {
      name: "Virginia",
      abbr: "VA"
    },
    {
      name: "Vermont",
      abbr: "VT"
    },
    {
      name: "Washington",
      abbr: "WA"
    },
    {
      name: "Wisconsin",
      abbr: "WI"
    },
    {
      name: "West Virginia",
      abbr: "WV"
    },
    {
      name: "Wyoming",
      abbr: "WY"
    },
    {
      name: "District of Columbia",
      abbr: "DC"
    },
    {
      name: "Puerto Rico",
      abbr: "PR"
    },
    {
      name: "Virgin Islands, U.S.",
      abbr: "VI"
    },
    {
      name: "Armed Forces Americas",
      abbr: "AA"
    },
    {
      name: "Armed Forces Europe",
      abbr: "AE"
    },
    {
      name: "Armed Forces Pacific",
      abbr: "AP"
    }
  ];

  public static MeetingPopoutURL =
    "#" + AppConstant.serviceworksRouteName + "/meeting-popout";
  public static meetingPopoutTitle = "Screenshare";

  public static enableCloseAccountFeature = true;
  public static enableCloseAccountConsole = false;

  public static enableEditAcctAddressFeature = true;
  public static enableEditAcctAddressConsole = false;

  public static enableEditAccountDemographicsFeature = true;
  public static enableEditAccountDemographicsConsole = false;

  public static enableEditFinancialsSuitabilityFeature = true;
  public static enableEditFinancialsSuitabilityConsole = false;

  public static BENEFICIARY_DESIGNATION_TODB = [
    {
      id: "000",
      text: "Main participant"
    },
    {
      id: "001",
      text: "Primary"
    },
    {
      id: "999",
      text: "Decedent"
    }
  ];
  public static BENEFICIARY_DESIGNATION = [
    {
      id: "000",
      text: "Main participant"
    },
    {
      id: "001",
      text: "Primary"
    },
    {
      id: "002",
      text: "Contingent Beneficiary"
      //"text": "For informational purposes only"
    },
    {
      id: "999",
      text: "Decedent"
    }
  ];
  public static BENEFICIARY_RELATIONSHIP = [
    {
      id: "O",
      text: "Other"
    },
    {
      id: "S",
      text: "Spouse"
    }
    /*
    ,
    {
      id: "B",
      text: "Single elapsed"
    },
    {
      id: "F",
      text: "Single elapsed (factor 1.0)"
    },
    {
      id: "J",
      text:
        "Will provide a joint elapsed RMD calculation for beneficiary accounts"
    },
    {
      id: "R",
      text: "Will provide a single recalc RMD calculation"
    },
    {
      id: "Y",
      text: "Joint recalculation"
    }
    */
  ];

  public static SR_RTT_STATUS_MAP = {
    Cancelled: "Cancelled",
    Closed: "Completed",
    "S360 Call Back": "Completed",
    "Specialized Call Back": "Completed",
    Assigned: "In Progress",
    "Client Side Complete": "In Progress",
    "Client/Market Complete": "In Progress",
    Forwarded: "In Progress",
    "In Process": "In Progress",
    "Market Complete": "In Progress",
    Open: "In Progress",
    "Re-Opened": "In Progress",
    "S360 Tracking": "In Progress",
    "Specialized Tracking": "In Progress",
    Tracking: "In Progress",
    "Not In Good Order": "Not In Good Order",
    Pending: "Pending Advisor"
  };

  public static RTT_REQUEST_ID_PF_KEY = "60783EB8-EDFF-4BF0-AAAC-A06174F3F99E";

  // persona exception for environment specific
  public static PERSONA_ENVIRONMENT_EXCEPTION = [
    "sw_readonly",
    "sw_survey",
    "sw_cobrowse",
    "sw_screenshare",
    "sw_isa",
    "swciraadmins",
    "sw_casemanagers"
  ];

  // public static DEFAULT_SW_PERSONAS = {
  //   devPersonas: ['SW_ServiceProfessional_Dev', 'SW_ServiceManager_Dev', 'SW_TSHD_ServiceProfessional_Dev', 'SW_TSHD_ServiceManager_Dev', 'SW_Executive_Dev'],
  //   qaPersonas: ['SW_TSHD_ServiceManager_QA', 'SW_TSHD_ServiceProfessional_QA', 'SW_ServiceProfessional_QA', 'SW_ServiceManager_QA', 'SW_Executive_QA'],
  //   prodPersonas: ['SW_ServiceProfessional', 'SW_ServiceManager', 'SW_TSHD_ServiceProfessional', 'SW_TSHD_ServiceManager', 'SW_Executive']
  // };

  public static SW_PERSONAS_PER_PAGE = {
    agentDashboard: [
      "SW_ServiceProfessional_Dev",
      "SW_ServiceManager_Dev",
      "SW_TSHD_ServiceProfessional_Dev",
      "SW_TSHD_ServiceManager_Dev",
      "SW_TSHD_ServiceManager_QA",
      "SW_TSHD_ServiceProfessional_QA",
      "SW_ServiceProfessional_QA",
      "SW_ServiceManager_QA",
      "SW_ServiceProfessional",
      "SW_ServiceManager",
      "SW_TSHD_ServiceProfessional",
      "SW_TSHD_ServiceManager"
    ],
    advisor360: [
      "SW_ServiceProfessional_Dev",
      "SW_ServiceManager_Dev",
      "SW_TSHD_ServiceProfessional_Dev",
      "SW_TSHD_ServiceManager_Dev",
      "SW_TSHD_ServiceManager_QA",
      "SW_TSHD_ServiceProfessional_QA",
      "SW_ServiceProfessional_QA",
      "SW_ServiceManager_QA",
      "SW_ServiceProfessional",
      "SW_ServiceManager",
      "SW_TSHD_ServiceProfessional",
      "SW_TSHD_ServiceManager"
    ],
    reporting: [
      "SW_ServiceManager_Dev",
      "SW_TSHD_ServiceManager_Dev",
      "SW_TSHD_ServiceManager_QA",
      "SW_ServiceManager_QA",
      "SW_ServiceManager",
      "SW_TSHD_ServiceManager"
    ],
    executive: ["SW_Executive_Dev", "SW_Executive_QA", "SW_Executive"],
    popoutQuickTicket: [
      "SW_ServiceProfessional_Dev",
      "SW_ServiceManager_Dev",
      "SW_TSHD_ServiceProfessional_Dev",
      "SW_TSHD_ServiceManager_Dev",
      "SW_TSHD_ServiceManager_QA",
      "SW_TSHD_ServiceProfessional_QA",
      "SW_ServiceProfessional_QA",
      "SW_ServiceManager_QA",
      "SW_ServiceProfessional",
      "SW_ServiceManager",
      "SW_TSHD_ServiceProfessional",
      "SW_TSHD_ServiceManager"
    ],
    popoutSRAdvancedSearch: [
      "SW_ServiceProfessional_Dev",
      "SW_ServiceManager_Dev",
      "SW_TSHD_ServiceProfessional_Dev",
      "SW_TSHD_ServiceManager_Dev",
      "SW_TSHD_ServiceManager_QA",
      "SW_TSHD_ServiceProfessional_QA",
      "SW_ServiceProfessional_QA",
      "SW_ServiceManager_QA",
      "SW_ServiceProfessional",
      "SW_ServiceManager",
      "SW_TSHD_ServiceProfessional",
      "SW_TSHD_ServiceManager",
      "SW_Executive_Dev",
      "SW_Executive_QA",
      "SW_Executive"
    ],
    popoutNotificationsAdvancedSearch: [
      "SW_ServiceProfessional_Dev",
      "SW_ServiceManager_Dev",
      "SW_TSHD_ServiceProfessional_Dev",
      "SW_TSHD_ServiceManager_Dev",
      "SW_TSHD_ServiceManager_QA",
      "SW_TSHD_ServiceProfessional_QA",
      "SW_ServiceProfessional_QA",
      "SW_ServiceManager_QA",
      "SW_ServiceProfessional",
      "SW_ServiceManager",
      "SW_TSHD_ServiceProfessional",
      "SW_TSHD_ServiceManager",
      "SW_Executive_Dev",
      "SW_Executive_QA",
      "SW_Executive"
    ],
    popoutMeetings: ["SW_CoBrowse", "SW_ScreenShare"],
    chatbot: ["SW_ISA"]
  };

  public static QUICK_LINKS = [
    {
      "AppName": "iKnow",
      "AppHyperLink": "http://kb.corp.lpl.com/",
      "Groups": ["ServiceManager", "ServiceProfessional", "TSHDServiceManager", "TSHDServiceProfessional"]
    },
    {
      "AppName": "Resource Center",
      "AppHyperLink": "https://clientworks.lpl.com/cw/resourcecenter/",
      "Groups": ["ServiceManager", "ServiceProfessional", "TSHDServiceManager", "TSHDServiceProfessional"]
    },
    {
      "AppName": "Proof Point Inbound",
      "AppHyperLink": "https://0017a502.pphosted.com:10000/admin",
      "Groups": ["TSHDServiceManager", "TSHDServiceProfessional"]
    },
    {
      "AppName": "Proof Point Outbound",
      "AppHyperLink": "https://0017a501.pphosted.com:10000/admin",
      "Groups": ["TSHDServiceManager", "TSHDServiceProfessional"]
    },
    {
      "AppName": "Tech Central",
      "AppHyperLink": "http://teamcenter.corp.lpl.com/site/t/techcentral/sitepages/tools.aspx",
      "Groups": ["TSHDServiceManager", "TSHDServiceProfessional"]
    },
    {
      "AppName": "Single Point",
      "AppHyperLink": "https://singlepoint.usbank.com/cs70_banking/logon/sbuser",
      "Groups": ["TSHDServiceManager", "TSHDServiceProfessional"]
    },
    {
      "AppName": "Random Password Generator",
      "AppHyperLink": "https://passwordsgenerator.net/",
      "Groups": ["TSHDServiceManager", "TSHDServiceProfessional"]
    },
    {
      "AppName": "NetWrix",
      "AppHyperLink": "http://10.22.91.116/ALE/List.aspx",
      "Groups": ["ServiceManager", "ServiceProfessional", "TSHDServiceManager", "TSHDServiceProfessional"]
    },
    {
      "AppName": "Specialized Services Central",
      "AppHyperLink": "http://sharepoint.corp.lpl.com/site/S/SpecializedServices/_layouts/15/start.aspx#/SitePages/Home.aspx",
      "Groups": ["ServiceManager", "ServiceProfessional", "TSHDServiceManager", "TSHDServiceProfessional"]
    }
  ];

  //CoBrowse Reporting
  public static enableCoBrowseWidget = false;

  public static chatBotPopoutURL =
    "#" + AppConstant.serviceworksRouteName + "/chatbot";

  public static cobrowseReportFallback = 'Cobrowse Reporting';
  public static bordReportFallback = 'BORD Restriction Report';

  //Account Holder
  public static ACCOUNT_HOLDER_ROLES_DROPDOWN = [
    { value: 'J', text: 'Power of attorney' },
    { value: 'K', text: 'Trading authorization' },
    { value: 'A', text: 'Account holder' },
    { value: 'W', text: 'Authorized officer - Retirement Plan' },
    { value: 'B', text: 'Authorized person' },
    { value: 'Z', text: 'Beneficial owner entity' },
    { value: 'Y', text: 'Beneficial owner individual' },
    { value: 'L', text: 'Conservator' },
    { value: 'X', text: 'Control person' },
    { value: 'D', text: 'Custodian' },
    { value: 'M', text: 'Executor' },
    { value: 'N', text: 'General partner' },
    { value: 'F', text: 'Grantor' },
    { value: 'O', text: 'Guardian' },
    { value: 'G', text: 'Member' },
    { value: 'P', text: 'Naked owner' },
    { value: 'H', text: 'Officer from Business entity' },
    { value: 'I', text: 'Owner-Sole proprietorship' },
    { value: 'Q', text: 'Plan trustee' },
    { value: 'R', text: 'Remainderman' },
    { value: 'S', text: 'Secondary holder' },
    { value: 'V', text: 'Trust officer - For private trust company trust' },
    { value: 'T', text: 'Trustee' }
  ]

  public static COUNTRY_BETA_NFTA = [
    { abbr: 'AA', name: 'Aruba' },
    { abbr: 'AC', name: 'Antigua and Barbuda' },
    { abbr: 'AF', name: 'Afghanistan' },
    { abbr: 'AG', name: 'Algeria' },
    { abbr: 'AJ', name: 'Azerbaijan' },
    { abbr: 'AL', name: 'Albania' },
    { abbr: 'AM', name: 'Armenia' },
    { abbr: 'AN', name: 'Andorra' },
    { abbr: 'AO', name: 'Angola' },
    { abbr: 'AQ', name: 'American Samoa' },
    { abbr: 'AR', name: 'Argentina' },
    { abbr: 'AS', name: 'Australia' },
    { abbr: 'AT', name: 'Ashmore and Cartier' },
    { abbr: 'AU', name: 'Austria' },
    { abbr: 'AV', name: 'Anguilla' },
    { abbr: 'AY', name: 'Antarctica' },
    { abbr: 'BA', name: 'Bahrain' },
    { abbr: 'BB', name: 'Barbados' },
    { abbr: 'BC', name: 'Botswana' },
    { abbr: 'BD', name: 'Bermuda' },
    { abbr: 'BE', name: 'Belgium' },
    { abbr: 'BF', name: 'Bahamas, The' },
    { abbr: 'BG', name: 'Bangladesh' },
    { abbr: 'BH', name: 'Belize' },
    { abbr: 'BK', name: 'Bosnia-Herzegovina' },
    { abbr: 'BL', name: 'Bolivia' },
    { abbr: 'BM', name: 'Burma' },
    { abbr: 'BN', name: 'Benin (Dahomey)' },
    { abbr: 'BO', name: 'Belarus' },
    { abbr: 'BP', name: 'Solomon Islands' },
    { abbr: 'BQ', name: 'Navassa Island' },
    { abbr: 'BR', name: 'Brazil' },
    { abbr: 'BS', name: 'Bassas Da India' },
    { abbr: 'BT', name: 'Bhutan' },
    { abbr: 'BU', name: 'Bulgaria' },
    { abbr: 'BV', name: 'Bouvet Island' },
    { abbr: 'BX', name: 'Brunei' },
    { abbr: 'BY', name: 'Burundi' },
    { abbr: 'CA', name: 'Canada' },
    { abbr: 'CB', name: 'Cambodia (Kampuchea)' },
    { abbr: 'CD', name: 'Chad' },
    { abbr: 'CE', name: 'Sri Lanka' },
    { abbr: 'CF', name: 'Congo (Brazzaville)' },
    { abbr: 'CG', name: 'Zaire/Congo' },
    { abbr: 'CH', name: 'China, Peoples Republic (Inner Mongolia, Tibet & Manchuria)' },
    { abbr: 'CI', name: 'Chile' },
    { abbr: 'CJ', name: 'Cayman Islands' },
    { abbr: 'CK', name: 'Cocos (Keeling) Islands' },
    { abbr: 'CM', name: 'Cameroon' },
    { abbr: 'CN', name: 'Comoros' },
    { abbr: 'CO', name: 'Columbia' },
    { abbr: 'CQ', name: 'Northern Marina Islands' },
    { abbr: 'CR', name: 'Coral Sea Islands Territory' },
    { abbr: 'CS', name: 'Costa Rica' },
    { abbr: 'CT', name: 'Central African Republic' },
    { abbr: 'CU', name: 'Cuba' },
    { abbr: 'CV', name: 'Cape Verde' },
    { abbr: 'CW', name: 'Cook Islands' },
    { abbr: 'CY', name: 'Cyprus' },
    { abbr: 'DA', name: 'Denmark' },
    { abbr: 'DJ', name: 'Djibouti' },
    { abbr: 'DO', name: 'Dominica' },
    { abbr: 'DQ', name: 'Jarvis Islands' },
    { abbr: 'DR', name: 'Dominican Republic' },
    { abbr: 'EB', name: 'European Central Bank' },
    { abbr: 'EC', name: 'Ecuador' },
    { abbr: 'EG', name: 'Egypt' },
    { abbr: 'EI', name: 'Ireland, Republic of (Eire)' },
    { abbr: 'EK', name: 'Equatorial Guinea' },
    { abbr: 'EN', name: 'Estonia' },
    { abbr: 'ER', name: 'Eritrea' },
    { abbr: 'ES', name: 'El Salvador' },
    { abbr: 'ET', name: 'Ethiopia' },
    { abbr: 'EU', name: 'Europa Island' },
    { abbr: 'EZ', name: 'Czech Republic' },
    { abbr: 'FG', name: 'French Guiana' },
    { abbr: 'FI', name: 'Finland' },
    { abbr: 'FJ', name: 'Fiji' },
    { abbr: 'FK', name: 'Falkland Islands (Islas Malvinas)' },
    { abbr: 'FM', name: 'Federated States of Micronesia' },
    { abbr: 'FO', name: 'Faroe Islands' },
    { abbr: 'FP', name: 'French Polynesia (Tahiti)' },
    { abbr: 'FQ', name: 'Baker Island' },
    { abbr: 'FR', name: 'France' },
    { abbr: 'FS', name: 'French Southern and Antarctic' },
    { abbr: 'GA', name: 'Gambia, The' },
    { abbr: 'GB', name: 'Gabon' },
    { abbr: 'GG', name: 'Georgia' },
    { abbr: 'GH', name: 'Ghana' },
    { abbr: 'GI', name: 'Gibraltar' },
    { abbr: 'GJ', name: 'Grenada (Southern Grenadines)' },
    { abbr: 'GK', name: 'Guernsey' },
    { abbr: 'GL', name: 'Greenland' },
    { abbr: 'GM', name: 'Germany' },
    { abbr: 'GO', name: 'Glorioso Islands' },
    { abbr: 'GP', name: 'Guadeloupe' },
    { abbr: 'GQ', name: 'Guam' },
    { abbr: 'GR', name: 'Greece' },
    { abbr: 'GT', name: 'Guatemala' },
    { abbr: 'GV', name: 'Guinea' },
    { abbr: 'GY', name: 'Guyana' },
    { abbr: 'GZ', name: 'Gaza Strip' },
    { abbr: 'HA', name: 'Haiti' },
    { abbr: 'HK', name: 'Hong Kong' },
    { abbr: 'HM', name: 'Heard and McDonald Islands' },
    { abbr: 'HO', name: 'Honduras' },
    { abbr: 'HQ', name: 'Howland Island' },
    { abbr: 'HR', name: 'Croatia' },
    { abbr: 'HU', name: 'Hungary' },
    { abbr: 'IC', name: 'Iceland' },
    { abbr: 'ID', name: 'Indonesia (Bali, Belitung, Flores, Java, etc.)' },
    { abbr: 'IM', name: 'Isle of Man' },
    { abbr: 'IN', name: 'India' },
    { abbr: 'IO', name: 'British Indian Ocean Territory' },
    { abbr: 'IP', name: 'Clipperton Island' },
    { abbr: 'IR', name: 'Iran' },
    { abbr: 'IS', name: 'Israel' },
    { abbr: 'IT', name: 'Italy' },
    { abbr: 'IV', name: 'Cote d\'Ivoire (Ivory Coast)' },
    { abbr: 'IZ', name: 'Iraq' },
    { abbr: 'JA', name: 'Japan' },
    { abbr: 'JE', name: 'Jersey' },
    { abbr: 'JM', name: 'Jamaica' },
    { abbr: 'JN', name: 'Jan Mayen' },
    { abbr: 'JO', name: 'Jordan' },
    { abbr: 'JQ', name: 'Johnston Atoll' },
    { abbr: 'JU', name: 'Juan De Nova Island' },
    { abbr: 'KE', name: 'Kenya' },
    { abbr: 'KG', name: 'Kyrgyzstan' },
    { abbr: 'KN', name: 'Korea, North' },
    { abbr: 'KQ', name: 'Kingman Reef' },
    { abbr: 'KR', name: 'Kiribati (Gilbert Islands)' },
    { abbr: 'KS', name: 'Korea, South' },
    { abbr: 'KT', name: 'Christmas Island (Indian)' },
    { abbr: 'KU', name: 'Kuwait' },
    { abbr: 'KZ', name: 'Kazakhstan' },
    { abbr: 'LA', name: 'Laos' },
    { abbr: 'LE', name: 'Lebanon' },
    { abbr: 'LG', name: 'Latvia' },
    { abbr: 'LH', name: 'Lithuania' },
    { abbr: 'LI', name: 'Liberia' },
    { abbr: 'LO', name: 'Slovakia' },
    { abbr: 'LQ', name: 'Palmyra Atoll' },
    { abbr: 'LS', name: 'Liechtenstein' },
    { abbr: 'LT', name: 'Lesotho' },
    { abbr: 'LU', name: 'Luxembourg' },
    { abbr: 'LY', name: 'Libya' },
    { abbr: 'MA', name: 'Madagascar (Malagasy Republic)' },
    { abbr: 'MB', name: 'Martinique' },
    { abbr: 'MC', name: 'Macau' },
    { abbr: 'MD', name: 'Moldova' },
    { abbr: 'MF', name: 'Mayotte' },
    { abbr: 'MG', name: 'Mongolia' },
    { abbr: 'MH', name: 'Monserrat' },
    { abbr: 'MI', name: 'Malawi' },
    { abbr: 'MK', name: 'Macedonia' },
    { abbr: 'ML', name: 'Mali' },
    { abbr: 'MN', name: 'Monaco' },
    { abbr: 'MO', name: 'Morocco' },
    { abbr: 'MP', name: 'Mauritius' },
    { abbr: 'MQ', name: 'Midway Islands' },
    { abbr: 'MR', name: 'Mauritania' },
    { abbr: 'MT', name: 'Malta' },
    { abbr: 'MU', name: 'Oman' },
    { abbr: 'MV', name: 'Maldives' },
    { abbr: 'MW', name: 'Not Found' },
    { abbr: 'MX', name: 'Mexico' },
    { abbr: 'MY', name: 'Malaysia' },
    { abbr: 'MZ', name: 'Mozambique' },
    { abbr: 'NC', name: 'New Caledonia' },
    { abbr: 'NE', name: 'Niue' },
    { abbr: 'NF', name: 'Norfolk Island' },
    { abbr: 'NG', name: 'Niger' },
    { abbr: 'NH', name: 'Vanuatu' },
    { abbr: 'NI', name: 'Nigeria' },
    { abbr: 'NL', name: 'Netherlands' },
    { abbr: 'NO', name: 'Norway' },
    { abbr: 'NP', name: 'Nepal' },
    { abbr: 'NR', name: 'Nauru' },
    { abbr: 'NS', name: 'Suriname' },
    { abbr: 'NT', name: 'Netherlands Antilles/ Bonaire/ Curacao' },
    { abbr: 'NU', name: 'Nicaragua' },
    { abbr: 'NZ', name: 'New Zealand' },
    { abbr: 'OC', name: 'Other Countries' },
    { abbr: 'PA', name: 'Paraguay' },
    { abbr: 'PC', name: 'Pitcairn Islands' },
    { abbr: 'PE', name: 'Peru' },
    { abbr: 'PF', name: 'Paracel Islands' },
    { abbr: 'PG', name: 'Spratly Islands' },
    { abbr: 'PK', name: 'Pakistan' },
    { abbr: 'PL', name: 'Poland' },
    { abbr: 'PM', name: 'Panama' },
    { abbr: 'PO', name: 'Portugal - Azores' },
    { abbr: 'PP', name: 'Papua New Guinea' },
    { abbr: 'PS', name: 'Republic of Palau' },
    { abbr: 'PU', name: 'Guinea - Bissau' },
    { abbr: 'QA', name: 'Qatar (Katar)' },
    { abbr: 'RE', name: 'Reunion' },
    { abbr: 'RM', name: 'Marshall Islands' },
    { abbr: 'RO', name: 'Romania' },
    { abbr: 'RP', name: 'Philippines' },
    { abbr: 'RQ', name: 'Puerto Rico' },
    { abbr: 'RS', name: 'Russia' },
    { abbr: 'RW', name: 'Rwanda' },
    { abbr: 'SA', name: 'Saudi Arabia' },
    { abbr: 'SB', name: 'St Pierre and Miquelon' },
    { abbr: 'SC', name: 'St Kitts (St Christopher and Nevis)' },
    { abbr: 'SE', name: 'Seychelles' },
    { abbr: 'SF', name: 'South Africa' },
    { abbr: 'SG', name: 'Senegal' },
    { abbr: 'SH', name: 'St Helena (Ascension Island & Tristan De Cuhna)' },
    { abbr: 'SI', name: 'Slovenia' },
    { abbr: 'SL', name: 'Sierra Leone' },
    { abbr: 'SM', name: 'San Marino' },
    { abbr: 'SN', name: 'Singapore' },
    { abbr: 'SO', name: 'Somalia' },
    { abbr: 'SP', name: 'Spain/ Balearic Islands/ (Mallorca, etc.)' },
    { abbr: 'SR', name: 'Not found' },
    { abbr: 'ST', name: 'St Lucia' },
    { abbr: 'SU', name: 'Sudan' },
    { abbr: 'SV', name: 'Svalbard (Spitsbergen)' },
    { abbr: 'SW', name: 'Sweden' },
    { abbr: 'SX', name: 'South Georgia and South Sandwich Islands' },
    { abbr: 'SY', name: 'Syria' },
    { abbr: 'SZ', name: 'Switzerland' },
    { abbr: 'TC', name: 'Uae/ Abu Dhabi/ Dubai' },
    { abbr: 'TD', name: 'Trinidad and Tobago' },
    { abbr: 'TE', name: 'Tromelin Island' },
    { abbr: 'TH', name: 'Thailand' },
    { abbr: 'TI', name: 'Tajikistan' },
    { abbr: 'TK', name: 'Turks and Caicos Islands' },
    { abbr: 'TL', name: 'Tokelau' },
    { abbr: 'TN', name: 'Tonga' },
    { abbr: 'TO', name: 'Togo' },
    { abbr: 'TP', name: 'Sao Tome and Principe' },
    { abbr: 'TS', name: 'Tunisia' },
    { abbr: 'TT', name: 'Not Found' },
    { abbr: 'TU', name: 'Turkey' },
    { abbr: 'TV', name: 'Tuvalu' },
    { abbr: 'TW', name: 'Taiwan' },
    { abbr: 'TX', name: 'Turkmenistan' },
    { abbr: 'TZ', name: 'Tanzania, United Republic of' },
    { abbr: 'UC', name: 'Unknown Country' },
    { abbr: 'UG', name: 'Uganda' },
    { abbr: 'UK', name: 'United Kingdom - Northern Ireland' },
    { abbr: 'UP', name: 'Ukraine' },
    { abbr: 'US', name: 'United States' },
    { abbr: 'UV', name: 'Burkina Faso (Upper Volta)' },
    { abbr: 'UY', name: 'Uruguay' },
    { abbr: 'UZ', name: 'Uzbekistan' },
    { abbr: 'VC', name: 'St Vincent/ N Grenadines/ Winward Island' },
    { abbr: 'VE', name: 'Venezuela' },
    { abbr: 'VI', name: 'Virgin Islands Br./ Redonda/ Tortola' },
    { abbr: 'VM', name: 'Vietnam' },
    { abbr: 'VP', name: 'Corsica' },
    { abbr: 'VQ', name: 'Virgin Islands (U.S.)' },
    { abbr: 'VT', name: 'Vatican City' },
    { abbr: 'WA', name: 'Namibia' },
    { abbr: 'WE', name: 'West Bank' },
    { abbr: 'WF', name: 'Wallis and Futuna' },
    { abbr: 'WI', name: 'Western Sahara' },
    { abbr: 'WQ', name: 'Wake Island' },
    { abbr: 'WS', name: 'Western Samoa' },
    { abbr: 'WZ', name: 'Swaziland' },
    { abbr: 'YM', name: 'Yemen (Aden)' },
    { abbr: 'YO', name: 'Yugoslavia/ Kosovo/ Montenegro/ Serbia' },
    { abbr: 'YS', name: 'Not Found' },
    { abbr: 'ZA', name: 'Zambia' },
    { abbr: 'ZI', name: 'Zimbabwe' }
  ];

  public static ACC_HOLDER_GI_ID_IND = [
    { value: 'DL', text: 'Driver\'s License' },
    { value: 'PP', text: 'Passport' },
    { value: 'MI', text: 'Military ID' },
    { value: 'SG', text: 'State/City Government ID' },
    { value: 'FG', text: 'Federal Government ID' }
  ];

  public static ACC_HOLDER_GI_ID_ENT = [
    { value: 'AI', text: 'Articles of Incorporation' },
    { value: 'BL', text: 'Business License' },
    { value: 'PA', text: 'Partnership Agreement' },
    { value: 'SC', text: 'State Certificate of Good Standing' },
    { value: 'AO', text: 'Articles of Organization' },
    { value: 'FB', text: 'Fictitious Business Name Certificate' },
    { value: 'IF', text: 'IRS Form 990' },
    { value: 'OA', text: 'Operating Agreement' }
  ];

  /* Non-AQ users - POC*/

    // public static emailRegexPattern = '^[A-Za-z0-9._%+-]+@(lpl\.com)$';
    public static emailRegexPattern='';
    public static HEADER_NAME = 'Annual Questionnaire';
    public static GLOBAL_SERVICE_ERROR_MSG = 'System is currently facing some difficulties. Please retry once or try after some time.';
    public static NON_NUMBER_ERROR_MSG = 'Please Enter Only Number';
    public static TECH_SUPPORT_MSG = 'Please contact the Technical Support Help Desk for assistance.';
    public static Non_CW_APP_TITLE = 'Annual Questionnaire';


}
