import { Injectable, Inject } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Http, RequestOptions, RequestMethod } from "@angular/http";

import { Observable, Subject, BehaviorSubject, of } from "rxjs";

import { AppSettings } from "../../../../configs/core.config";
import { SWCoreServices } from "../../../../services/core.services";
import { AppConstant } from "../../../../constant/core.constant";
import { catchError, map, tap } from "rxjs/operators";
import { HeaderIconSettingsModel } from '../../../../models/header-icon-settings.model';
@Injectable()
export class HeaderIconsService {
  private headerIcons = new BehaviorSubject<any>({});
  iconSettingsDefault: HeaderIconSettingsModel = {
    title: AppConstant.GLOBAL_HEADER_NAME,
    redirectedURL: AppConstant.homeAppLauncherRouteName,
    logo: true,
    searchContact: false,
    cwConnection: false,
    userInfo: true,
    searchAdvisor: false,
    searchInvestor: false,
    ticketSearch: false,
    reportsCustom: false,
    reportsBeta: false,
    fraudAlert: false,
    quickLink: false,
    feedback: false,
    help: false,
    signout: true,
    hamburgerMenu: false
  };
  
  constructor(
    private swCoreServices: SWCoreServices
  ) {}

  getHeaderIcons(): Observable<any> {
    return this.headerIcons.asObservable();
  }

  setHeaderIcons(data: HeaderIconSettingsModel) {
    this.headerIcons.next(data);
  }

  // getAgentInformation() {
  //   return this.swCoreServices
  //     .get(AppSettings.userInfoUrl)
  //     .pipe(map(res => res.data || {}))
  //     .toPromise();
  // }

}
