
import { NgModule, Compiler } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes, Router } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RESTServices } from './services/rest.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
/*wijmo module*/
import { WjGridModule } from "wijmo/wijmo.angular2.grid";
import { WjInputModule } from "wijmo/wijmo.angular2.input";
import { WjGridFilterModule } from "wijmo/wijmo.angular2.grid.filter";
import { WjGridDetailModule } from "wijmo/wijmo.angular2.grid.detail";
import { WjNavModule } from "wijmo/wijmo.angular2.nav";
import { WjGridGrouppanelModule } from "wijmo/wijmo.angular2.grid.grouppanel";
/*end*/

import { NgxMyDatePickerModule } from "ngx-mydatepicker";

//Core Components
import { HeaderComponent } from "./components/business-components/header/header.component";
import { SearchContactsComponent } from "./components/business-components/search-contacts/search-contacts.component";
import { QuickLinksComponent } from "./components/business-components/quick-links/quick-links.component";
import { SearchAdvisorComponent } from "./components/business-components/search-advisor/search-advisor.component";
import { LoaderComponent } from "./components/ui-components/loader/loader.component";
import { SignOutComponent } from "./components/ui-components/sign-out/sign-out.component";
import { DynamicLoadComponent } from "./components/ui-components/dynamic-load/dynamic-load.component";
//import { WijmoFlexGridComponent } from "./components/ui-components/wijmo-flex-grid/wijmo-flex-grid.component";
import { SWCrossboxComponent } from "./components/ui-components/sw-crossbox/sw-crossbox.component";
import { SWCheckboxComponent } from "./components/ui-components/sw-checkbox/sw-checkbox.component";
import { SWDateRangePickerComponent } from "./components/ui-components/sw-daterangepicker/sw-daterangepicker.component";
import { DeleteCancelConfirmationBoxComponent } from "./components/ui-components/delete-cancel-confirmation-box/delete-cancel-confirmation-box.component";
import { FraudReportComponent } from "./components/business-components/fraud-report/fraud-report.component";
import { SearchInvestorComponent } from "./components/business-components/search-investor/search-investor.component";
// import { SearchServiceRequestComponent } from "./components/business-components/search-service-request/search-service-request.component";
import { cwConnectionComponent } from "./components/business-components/cw-connection/cw-connection.component";
import { PersonaAccessAuthenticationDirective } from "./directives/persona-access-authentication/persona-access-authentication.directive";
import { ReadMoreComponent } from "./components/ui-components/read-more/read-more.component";
import { InputFileComponent } from "./components/ui-components/input-files/input-file.component";
import { SettingsComponent } from "./components/ui-components/settings/settings.component";
import { ToastsContainer } from "./components/ui-components/toaster/toaster-container.component";
import { FeedbackComponent } from "./components/business-components/feedback/feedback.component";

//Core filters
import { PhoneFilterPipe } from "./pipes/phoneFilter";
import { PhoneFilterTwoPipe } from "./pipes/phoneFilterTwo";
import { SSNFilterPipe } from "./pipes/ssnFilter";
import { TINFilterPipe } from "./pipes/tinFilter";
import { FileSizePipe } from "./pipes/fileSize.pipe";
import { CurrencyFilterPipe } from "./pipes/currencyFilter";
import { SafeHtmlPipe } from "./pipes/pipe.safehtml";
import { FilterArrayPipe } from "./pipes/filter-array-pipe";
import { AccountNumberPipe } from "./pipes/accountNumber";
import { TruncatePipe } from "./pipes/ellipses.pipes";
import { DatePipe } from "./pipes/datefilter";
import { ZeroFilterPipe } from "./pipes/zerofilter";
import { DOBMaskPipe } from "./pipes/dobMask";
import { NumberMaskingPipe } from './pipes/number-masking.pipe';
//services
import { HeaderService } from "./components/business-components/header/services/header.services";
import { HeaderIconsService } from "./components/business-components/header/services/headerIcons.services";
import { InvestorSearchService } from "./services/investor-search.service";
import { AcitivityStreamLogService } from "./services/activity-stream-log.services";
import { userPreferenceService } from "./services/userPreference.services";

import { WINDOW_PROVIDERS } from "./services/window-providers.services";
//directive
import { DynamicLodDirective } from "./components/ui-components/dynamic-load/dynamic-load.directive";
import { DropZoneDirective } from "./components/ui-components/input-files/drop-zone.directive";
import { HozXhrInterceptor } from "./services/hozXhrInterceptor.service";
import { NonAqHeaderComponent } from './components/business-components/non-aq-header/non-aq-header.component'; 
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    WjInputModule,
    WjGridModule,
    WjGridFilterModule,
    WjGridDetailModule,
    WjGridGrouppanelModule,
    WjNavModule,
    NgxMyDatePickerModule.forRoot()
  ],
  declarations: [
    HeaderComponent,
    SearchContactsComponent,
    AccountNumberPipe,
    DatePipe,
    FileSizePipe,
    ZeroFilterPipe,
    PhoneFilterPipe,
    PhoneFilterTwoPipe,
    SSNFilterPipe,
    TINFilterPipe,
    CurrencyFilterPipe,
    SafeHtmlPipe,
    FilterArrayPipe,
    TruncatePipe,
    SearchAdvisorComponent,
    LoaderComponent,
    QuickLinksComponent,
    SignOutComponent,
    DynamicLoadComponent,
    DynamicLodDirective,
    //WijmoFlexGridComponent,
    SWCrossboxComponent,
    SWCheckboxComponent,
    SWDateRangePickerComponent,
    DeleteCancelConfirmationBoxComponent,
    FraudReportComponent,
    SearchInvestorComponent,
    // SearchServiceRequestComponent,
    cwConnectionComponent,
    PersonaAccessAuthenticationDirective,
    ReadMoreComponent,
    DropZoneDirective,
    InputFileComponent,
    SettingsComponent,
 
    ToastsContainer,
    DOBMaskPipe,
    NumberMaskingPipe,
    FeedbackComponent,
    NonAqHeaderComponent
  ],
  exports: [
    HeaderComponent,
    SearchContactsComponent,
    LoaderComponent,
    SearchAdvisorComponent,
    AccountNumberPipe,
    PhoneFilterPipe,
    PhoneFilterTwoPipe,
    SSNFilterPipe,
    TINFilterPipe,
    FileSizePipe,
    ZeroFilterPipe,
    CurrencyFilterPipe,
    SafeHtmlPipe,
    FilterArrayPipe,
    TruncatePipe,
    QuickLinksComponent,
    SignOutComponent,
    DynamicLoadComponent,
    DynamicLodDirective,
    //WijmoFlexGridComponent,
    SWCrossboxComponent,
    SWCheckboxComponent,
    SWDateRangePickerComponent,
    DeleteCancelConfirmationBoxComponent,
    FraudReportComponent,
    SearchInvestorComponent,
    // SearchServiceRequestComponent,
    cwConnectionComponent,
    PersonaAccessAuthenticationDirective,
    ReadMoreComponent,
    InputFileComponent,
    // SettingsComponent,

    ToastsContainer,
    DOBMaskPipe,
    NumberMaskingPipe,
    FeedbackComponent,
    NonAqHeaderComponent
  ],
  providers: [
    HeaderService,
    HeaderIconsService,
    AcitivityStreamLogService,
    userPreferenceService,
    InvestorSearchService,
    RESTServices,
    
    WINDOW_PROVIDERS,
    {
            provide:HTTP_INTERCEPTORS, useClass: HozXhrInterceptor, multi: true
          } 
  ],
  entryComponents: [FeedbackComponent]
})
export class CoreModule {}
