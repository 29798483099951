import { Injectable } from '@angular/core';
import * as $ from "jquery";
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  controlHasChilds = [];
  designQuestionaireFormState = new BehaviorSubject(false);
  questionFormType = new BehaviorSubject('parent');
  requestFrom = new BehaviorSubject('allQuestions');
  designPageEditData = new BehaviorSubject({});
  createInitiatedFrom = new BehaviorSubject('DesignQuestion');
  actionType = new BehaviorSubject('Create');
  //  sub = new Subject();
  subj$ = new BehaviorSubject([])
  // aqId=new Subject();
  aqId$=new BehaviorSubject([])
  parentAqId:any;

  setAqID(aqQuestionId:any){
    this.parentAqId=aqQuestionId;


  }
  getaqID(){
  return this.parentAqId;
  }

  send(value: any) {
    this.subj$.next(value);
  }

  sendAQ(value:any){
    this.aqId$.next(value);
  }
  constructor() { }
  setFormState(submitFlag: any) {
    this.designQuestionaireFormState.next(submitFlag);

  }

  setControlChildFlag(controlHasChildArray){
    this.controlHasChilds = controlHasChildArray;
  }
  getControlChildFlag(){
    return this.controlHasChilds;
  }

  setQuestionFormType(type){
    this.questionFormType.next(type);
  }

  setRequestFrom(data){
    this.requestFrom.next(data);
  }

  setEditQuestionDesignPage(data){
    this.designPageEditData.next(data);
  }
  setCreateInitiatedFrom(data){
    this.createInitiatedFrom.next(data);
  }
  setActionType(data){
    this.actionType.next(data);
  }
  
}
