import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  show(headerText: string, messageText: string, options: any = {}) {
    this.toasts.push({ headerText, messageText, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  toastPrimary(headerText: string, messageText: string = '', delay: number = 5000, autohide: boolean = true, closable: boolean = true) {
    this.show(headerText, messageText, { classname: 'bg-primary text-white', type: 'primary', delay: delay, autohide: autohide, closable: closable});
  }
  toastSecondary(headerText: string, messageText: string = '', delay: number = 5000, autohide: boolean = true, closable: boolean = true) {
    this.show(headerText, messageText || '', { classname: 'bg-secondary text-white', type: 'secondary', delay: delay, autohide: autohide, closable: closable});
  }
  toastSuccess(headerText: string, messageText: string = '', delay: number = 5000, autohide: boolean = true, closable: boolean = true) {
    this.show(headerText, messageText || '', { classname: 'bg-success text-white', type: 'success', delay: delay, autohide: autohide, closable: closable});
  }
  toastDanger(headerText: string, messageText: string = '', delay: number = 5000, autohide: boolean = true, closable: boolean = true) {
    this.show(headerText, messageText || '', { classname: 'bg-danger text-white', type: 'danger', delay: delay, autohide: autohide, closable: closable});
  }
  toastWarning(headerText: string, messageText: string = '', delay: number = 5000, autohide: boolean = true, closable: boolean = true) {
    this.show(headerText, messageText || '', { classname: 'bg-warning text-white', type: 'warning', delay: delay, autohide: autohide, closable: closable});
  }
  toastInfo(headerText: string, messageText: string = '', delay: number = 5000, autohide: boolean = true, closable: boolean = true) {
    this.show(headerText, messageText || '', { classname: 'bg-info text-white', type: 'info', delay: delay, autohide: autohide, closable: closable});
  }
  toastLight(headerText: string, messageText: string = '', delay: number = 5000, autohide: boolean = true, closable: boolean = true) {
    this.show(headerText, messageText || '', { classname: 'bg-light text-dark', type: 'light', delay: delay, autohide: autohide, closable: closable});
  }
  toastDark(headerText: string, messageText: string = '', delay: number = 5000, autohide: boolean = true, closable: boolean = true) {
    this.show(headerText, messageText || '', { classname: 'bg-dark text-white', type: 'dark', delay: delay, autohide: autohide, closable: closable});
  }
  toastWhite(headerText: string, messageText: string = '', delay: number = 5000, autohide: boolean = true, closable: boolean = true) {
    this.show(headerText, messageText || '', { classname: 'bg-white text-dark', type: 'white', delay: delay, autohide: autohide, closable: closable});
  }
  
}