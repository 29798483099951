import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

@Injectable()
export class FilteringService {
    filterPostResult: object = {};

    argFilterList: any;
    filterArray: any = [];

    constructor() { }

    //Get the filters name from JSON as an argument
    setFilters(filterList: any) {

        this.argFilterList = filterList;
        this.initFilters();
    }

    //Initialised the filters
    initFilters() {
        this.argFilterList.forEach(item => {
            this.filterArray[item.column] = []
        });
    }


    getFilterData(results): any {

        results.forEach((result) => {
            //Set blank for deleted filters         
            if (result.deleted) {
                this.filterArray[result.deleted.filterName] = [];
                this.filterPostResult[result.deleted.filterName] = { in: "" };
            }
        });

        this.argFilterList.forEach(item => {

            results.forEach((list) => {
                if (item.column == list.filterName) {

                    if (item.type == 'selectDate') {
                        this.filterArray[list.filterName] = list.filterList;
                        this.filterPostResult[item.column] = this.filterArray[item.column] || null; //{"min":"2019-07-12","max":"2019-09-14"}
                    }
                    else if (item.type == 'typeAhead') {
                        this.filterArray[list.filterName] = list.filterList;
                        // this.filterPostResult[item.column] = { in: this.filterArray[item.column].join('||') } || null;
                        this.filterPostResult[item.column] = { in: this.filterArray[item.column].join('||') };
                    }
                    else if (item.type == 'multiSelectList') {
                            let multiSelectListStr = '';
                            list.filterValue.forEach(value => {
                                multiSelectListStr = multiSelectListStr + value.id + '||';
                            })
                            let multiSelectListStrFrmt = multiSelectListStr.substring(0, multiSelectListStr.length - 2);
                            // this.filterPostResult[item.column] = { in: multiSelectListStrFrmt } || null;
                            this.filterPostResult[item.column] = { in: multiSelectListStrFrmt };
                    }
                }

            });

        } );
        this.filterPostResult['statusGroup'] = { in: '' };
        return this.filterPostResult;

    }

    resetFilterData() {
        this.initFilters();
        this.filterPostResult = {};
    }

}
