<header class="header">
    <div class="container-fluid">
        <div class="row">
            <div class="toggler-container col-4 pl-1">
                <a class="navbar-brand">Annual Questionnaire</a>
            </div>
            <div class="col-8">
                <ul class="nav user-info-wrapper justify-content-end">
                    <!-- <li class="user-info"><a href="javascript:void(0)">
                            <span class="user-info-img"><img alt="profile image"
                                    src="assets/images/profile-default.png"></span>
                            <span class="user-info-name">{{useremail}}</span>
                        </a></li> -->
                    <!-- <li class="sign-out"><a href="javascript:void(0)" (click)="logout()"><span>Sign Out</span></a></li> -->
                    <!-- <li class="sign-out">
                        <a href="javascript:void(0)" (click)="signOut()">
                            <span class="sign-out-span"><strong _ngcontent-c1="">Sign Out</strong></span>
                        </a>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</header>